import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import ws from "@/utils/ws";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/home",
    name: "home",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),_0x3d7761["e"]("chunk-3b4e006d"),])["then"](_0x3d7761["bind"](null, "77b8"));
    },
    */
    component: () => import("../views/Home.vue"),
    meta: {
      title: "home",
      showBar: true,
      hasSocket: false,
    },
  },
  {
    path: "/index",
    name: "index",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),_0x3d7761["e"]("chunk-b9f9e072"),])["then"](_0x3d7761["bind"](null, "7abe"));
    },
    */
    component: () => import("../views/Index.vue"),
    meta: {
      title: "home",
      showBar: true,
      hasSocket: false,
    },
  },
  {
    path: "/trading",
    name: "trading",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),_0x3d7761["e"]("chunk-3b4e006d"),])["then"](_0x3d7761["bind"](null, "6360"));
    },
    */
    component: () => import("../views/Trading.vue"),
    meta: {
      title: "trading",
      showBar: true,
      hasSocket: false,
    },
  },
  {
    path: "/pool",
    name: "pool",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),_0x3d7761["e"]("chunk-2d0df7c5"),])["then"](_0x3d7761["bind"](null, "102b"));
    },
    */
    component: () => import("../views/PoolIndex.vue"),
    meta: {
      title: "pool",
      showBar: true,
      hasSocket: false,
    },
  },
  {
    path: "/paper",
    name: "paper",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "70fb"));
    },
    */
    component: () => import("../views/Paper.vue"),
    meta: {
      title: "paper",
      showBar: true,
      hasSocket: false,
    },
  },
  {
    path: "/loan",
    name: "loan",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),_0x3d7761["e"]("chunk-76f0b72b"),])["then"](_0x3d7761["bind"](null, "8e3f"));
    },
    */
    component: () => import("../views/Loan.vue"),
    meta: {
      title: "loan",
      showBar: true,
      hasSocket: false,
    },
  },
  {
    path: "/web",
    name: "web",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "2631"));
    },
    */
    component: () => import("../views/Web.vue"),
    meta: {
      title: "Web",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/pdf",
    name: "pdf",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),])["then"](_0x3d7761["bind"](null, "045a"));
    },
    */
    component: () => import("../views/Pdf.vue"),
    meta: {
      title: "Pdf",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/future/history",
    name: "futureHistory",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),])["then"](_0x3d7761["bind"](null, "5cb6"));
    },
    */
    component: () => import("../views/FutureHistory.vue"),
    meta: {
      title: "Option History",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/chart",
    name: "chart",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),_0x3d7761["e"]("chunk-6c336c98"),])["then"](_0x3d7761["bind"](null, "10a1"));
    },
    */
    component: () => import("../views/Chart.vue"),
    meta: {
      title: "Chart",
      showBar: false,
      hasSocket: true,
    },
  },
  {
    path: "/nft",
    name: "nft",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "0e59"));
    },
    */
    component: () => import("../views/Nft.vue"),
    meta: {
      title: "Nft",
      showBar: true,
      hasSocket: false,
    },
  },
  {
    path: "/nft/collection",
    name: "collection",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),])["then"](_0x3d7761["bind"](null, "b367c"));
    },
    */
    component: () => import("../views/Collection.vue"),
    meta: {
      title: "Collection",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/nft/order",
    name: "nftOrder",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),])["then"](_0x3d7761["bind"](null, "8a90"));
    },
    */
    component: () => import("../views/NftOrder.vue"),
    meta: {
      title: "NftOrder",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/nft/records",
    name: "nftRecords",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),])["then"](_0x3d7761["bind"](null, "6eb8"));
    },
    */
    component: () => import("../views/NftRecords.vue"),
    meta: {
      title: "NftRecords",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/nft/tagList",
    name: "tagList",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),])["then"](_0x3d7761["bind"](null, "eecc"));
    },
    */
    component: () => import("../views/TagList.vue"),
    meta: {
      title: "TagList",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/my/password",
    name: "password",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),])["then"](_0x3d7761["bind"](null, "a191"));
    },
    */
    component: () => import("../views/Password.vue"),
    meta: {
      title: "Password",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/1",
    name: "article1",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "57a7"));
    },
    */
    component: () => import("../views/Article1.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/2",
    name: "article2",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "8ccf"));
    },
    */
    component: () => import("../views/Article2.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/3",
    name: "article3",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "c47f"));
    },
    */
    component: () => import("../views/Article3.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/4",
    name: "article4",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "4309"));
    },
    */
    component: () => import("../views/Article4.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/5",
    name: "article5",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "26e6"));
    },
    */
    component: () => import("../views/Article5.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/6",
    name: "article6",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "6177"));
    },
    */
    component: () => import("../views/Article6.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/7",
    name: "article7",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "9ea1"));
    },
    */
    component: () => import("../views/Article7.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/8",
    name: "article8",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-view"),_0x3d7761["e"]("chunk-2d0e62c5"),])["then"](_0x3d7761["bind"](null, "33a2"));
    },
    */
    component: () => import("../views/Article8.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/9",
    name: "article9",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-view"),_0x3d7761["e"]("chunk-3dbc12a6"),])["then"](_0x3d7761["bind"](null, "35b4"));
    },
    */
    component: () => import("../views/Article9.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/10",
    name: "article10",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "4f92"));
    },
    */
    component: () => import("../views/Article10.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/11",
    name: "article11",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "30f8"));
    },
    */
    component: () => import("../views/Article11.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/12",
    name: "article12",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "f817"));
    },
    */
    component: () => import("../views/Article12.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/article/13",
    name: "article13",
    /*
    component: function () {
      return _0x3d7761["e"]("chunk-view")["then"](_0x3d7761["bind"](null, "48b2"));
    },
    */
    component: () => import("../views/Article13.vue"),
    meta: {
      title: "Article",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/check",
    name: "Check",
    /*
    component: function () {
      return Promise["all"]([_0x3d7761["e"]("chunk-libs"),_0x3d7761["e"]("chunk-view"),])["then"](_0x3d7761["bind"](null, "676a"));
    },
    */
    component: () => import("../views/Check.vue"),
    meta: {
      title: "Check",
      showBar: false,
      hasSocket: false,
    },
  },
  {
    path: "/404",
    name: "404",
    /*
    component: function () {
      return _0xffdc45["e"]("chunk-view")["then"](_0xffdc45["bind"](null, "8cdb"));
    },
    */
    component: () => import("../views/404.vue"),
    meta: {
      title: "404",
      keepAlive: true,
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  scrollBehavior: function () {
    return {
      y: 0,
    };
  },
  //base: process.env.BASE_URL,
  routes,
});

var sessionStorage = window.sessionStorage;
sessionStorage.clear();

var navigationCount = parseInt(sessionStorage.getItem("count"), 10) || 0;

// Define the resetRouter function
function resetRouter() {
  router.replace("/index").catch(() => {});
}

// Set an item in session storage for the root path
sessionStorage.setItem("/", 0);

// Before each route navigation, update the navigation direction
router.beforeEach(function (to, from, next) {
  if (to.params.direction) {
    store.commit("updateDirection", to.params.direction);
  } else {
    var toItem = sessionStorage.getItem(to.path),
      fromItem = sessionStorage.getItem(from.path);

    if (toItem) {
      !fromItem ||
      parseInt(toItem, 10) > parseInt(fromItem, 10) ||
      ("0" === toItem && "0" === fromItem)
        ? store.commit("updateDirection", "forward")
        : store.commit("updateDirection", "back");
    } else {
      ++navigationCount;
      sessionStorage.setItem("count", navigationCount);
      "/" !== to.path && sessionStorage.setItem(to.path, navigationCount);
      store.commit("updateDirection", "forward");
    }
  }
  console.log("from: ", from);
  console.log("to: ", to);
  console.log("path: ", to.path);
  document.title = "web3.0";
  console.log("document.title: ", document.title);
  if (!to.meta.hasSocket) {
    ws.destroy();
  }
  next();
});

export { resetRouter };

export default router;
