export default {
  zt:{
    st_0:"审核中",
    st_1:"进行中",
    st_2:"已赎回",
    st_3:"审核未通过",
    st_4:"期限切れです"
  },
  langName: "日本語",
  common: {
    error: "システム障害、再度お試しください！",
    home: "ホーム",
    detail: "詳細",
    market: "市場",
    trade: "取引",
    options: "オプション",
    wallets: "ウォレット",
    notMore: "これ以上レコードがありません",
    reconnect: "再接続中",
    noData: "現在コンテンツはありません",
    loading: "読み込み中...",
    liveChat: "ライブチャット",
    help: "ヘルプ",
    lang: "言語",
    notify: [
      "このリンクをウォレットで開いてください！",
      "正常に送信されました",
      "既にクーポンを引き取りました！",
      "承認できません",
    ],
  },
  header: {
    voucher: "クーポンを受け取る",
    connectWallet: "ログイン",
    receive: "受信説明",
    reveiveInfo:
      "クーポンを受け取るためには、マイナーの手数料としてETHを支払う必要があります。ウォレットに十分なETHがマイナーの手数料としてあることを確認してください",
    receiveBtn: "受け取る",
    reward: "報酬を獲得しました",
  },
  share: {
    shareFriend: "友達に共有",
    info: "あなたの招待リンクを送り、友達があなたのリンクを通じてノードに参加すると、あなたは豊富なトークン報酬を得られます",
    myShareLink: "私の共有リンク",
    copy: "コピー",
  },
  ad: {
    plan: "PLAN SAVINGS:",
    regular: "REGULAR SAVINGS:",
  },
  output: {
    interest: "利息",
    desc: "柔軟な利息収入",
    output: "アウトプット",
    address: "アドレス",
    quantity: "数量",
  },
  order: {
    order: "注文",
    amount: "金額",
    available: "利用可能",
    all: "すべて",
    confirm: "確認",
    period: "期間",
    requird: "金額の要求",
    rate: "レート(ETH)",
    additional: "追加報酬",
    less: "1 USDC以上でなければなりません",
    participants: "参加者",
    total: "総額(usdc)",
  },
  chart: {
    loading: "読み込み中",
    min1: "1分",
    min15: "15分",
    min5: "5分",
    min30: "30分",
    hour1: "1時間",
    hour4: "4時間",
    day1: "1日",
    week1: "1週間",
    mon1: "1ヶ月",
    more: "もっと見る",
    price: "価格",
    open: "開く",
    amount: "数量",
    high: "高",
    low: "低",
    buy: "買い",
    sell: "売り",
    buyLong: "ロング/買い",
    sellShort: "ショート/売り",
    main: "メイン",
    side: "サブ",
    setting: "設定",
    history: "ポジション",
  },
  futureOrder: {
    title: "注文を確認",
    name: "名前",
    direction: "方向",
    price: "価格",
    info: "納品時間(左にスワイプしてもっと利益を得る)",
    amount: "数量(USDC)",
    available: "利用可能なUSDC",
    fee: "手数料",
    second: "秒",
    buy: "買い",
    sell: "売り",
    minAount: "${minAmount}-${maxAmount}の最小限度",
    startPrice: "注文価格",
    expectedProfits: "予想利益",
    finish: "期限の履行完了",
    currentPrice: "現在の価格",
    completeResult: "完了結果",
    confirmOrder: "確認",
    continueBuy: "続ける",
  },
  futureHistory: {
    title: "注文履歴",
    direction: "方向",
    amount: "数量",
    buyPrice: "注文価格",
    OrderPrice: "最終価格",
    deliveryTime: "タイプ",
    pl: "P/L",
    sellTime: "完了時間",
  },
  market: {
    currency24h: "通貨24時間",
    volume: "ボリューム",
    liquidity: "流動性",
  },
  user: {
    title: "新しいユーザーがマイニングプールに参加恭喜",
    nation: "ユーザーの国籍",
    address: "公開チェーンアドレス",
    Hashrate: "ウォレットハッシュレート",
  },
  rule: {
    daily: "デイリー",
    interest: "利息",
    rule: "ルール",
    flexible: "フレキシブル",
    payments: "利息は4時間に1回、1日6回支払われます",
    safety: "資産の安全性",
    less: "約80%のUSDC準備資産は3か月以下の短期米国国債です",
    economy:
      "これらは世界で最も安全な資産の1つであり、米国政府の「完全な信頼と信用の背後」によって支持されます。",
    fees: "手数料",
    additional: "利息の引き出しに加算手数料はありません",
  },
  report: {
    title: "dappをサポートするWeb3ウォレット",
    subTitle: ["モバイル", "ハードウェア", "スマートコントラクト", "その他"],
  },
  help: {
    article: [
      {
        title: "データを所有する",
        details: [
          "イーサリアムブロックチェーンを使用するこのソフトウェアcryptocurrency walletのすべての利点を活用してください。これにより、データはローカルにのみ所有され、ブラウザ拡張機能やモバイルアプリを介してウォレットにアクセスできます",
        ],
      },
      {
        title: "WEB3.0の利点は何ですか？",
        details: [
          "Web 3.0は、分散化、開放性、ユーザー向けユーティリティの強化というコアコンセプトを基に構築されています。",
          "分散化システムでは、情報はコンテンツに基づいて取得でき、同時に複数の場所に保持されるため、分散化されています。これにより、インターネット巨大企業が保持する膨大なデータセットを解体する一方で、スケーラビリティ、安全性、アクセス可能です。",
        ],
      },
      {
        title: "分散アプリケーションとは",
        details: [
          "分散アプリケーション(dApps)とは、ブロックチェーンやピアツーピア(P2P)ネットワークのノードで動作するプログラムであり、単一の中央集権型コンピュータに依存することはありません。",
          "dAppsのいくつかの利点は、中間者なしで機能できるため、トランザクションから利益を得る中間者がいないため経済的に効率的です。ユーザーは、共有または消費するコンテンツの法的および規制上の影響に責任があります。",
        ],
      },
      {
        title: "スマートコントラクト",
        details: [
          "分散アプリケーション(dApps)とは、ブロックチェーンやピアツーピア(P2P)ネットワークのノードで動作するプログラムであり、単一の中央集権型コンピュータに依存することはありません。",
          "dAppsのいくつかの利点は、中間者なしで機能できるため、トランザクションから利益を得る中間者がいないため経済的に効率的です。ユーザーは、共有または消費するコンテンツの法的および規制上の影響に責任があります。",
        ],
      },
    ],
    center: "ヘルプセンター",
    hope: "役立つことを願っています",
  },
  partner: {
    title1: "監査報告書",
    detail1: "私たちは安全な監査報告書を持っています",
    title2: "パートナー",
    detail2: "私たちのビジネスパートナー",
  },
  trading: {
    titles: ["トータル取引量", "未決済権益", "トータルユーザー"],
    currency: "人気通貨",
    banners: ["流動性を保存してUSDCを獲得", "私たちの優位性"],
  },
  poolIndex: {
    tab1: "プールデータ",
    tab2: "プラン",
    tab3: "税",
    tab4: "アカウント",
    tab5: "移行",
    transfer: "ウォレットからUSDCを残高に移行",
    button: "移行",
  },
  liquidity: {
    type:"タイプ",
    interest: "利息",
    period: "期間",
    amountRequire: "金額要件(USDC)",
    rate: "レート(ETH)",
    additionalReward: "追加報酬",
    days: "日数",
    subscription: "ステーク",
    redeem: "リデーム",
    record: "記録",
    participants: "参加者",
    totalAmount: "総額(usdc)",
    interestRate: "利率",
    amount: "数量(usdc)",
    collectionAmount: "集計金額",
    reward: "報酬",
    addReward: "追加報酬",
    endTime: "終了時間",
    status: "ステータス",
    stakingAmount: "ステーキング数量",
    unCollected: "未回収数量",
  },
  pool: {
    total: "総配当データ",
    participant: "参加者",
    userRevenue: "ユーザー収益",
    poolData: "プールデータ",
  },
  message: {
    title: "メッセージ",
    ok: "OK",
  },
  lottery: {
    title: "USDCのブラインドボックスプンプ",
    btn: "ランダム選択",
    stake: "ステーク",
    connectWallet: "ウォレット接続",
    voucher: "クーポン受取",
    tab1: "プールデータ",
    tab2: "プラン",
    tab3: "税",
    tab4: "アカウント",
    ok: "Ok",
    lotteryChance: "ETHの抽選会チャンス獲得恭喜！",
    lotteryBtn: "抽選開始",
    congratulations: "恭喜",
    experienceUsdt1: "恭喜！你得た",
    experienceUsdt2: "USDC試用資金。現在の試用金利は",
    experienceUsdt3: "ETH。試用の有効期限は",
  },
  my: {
    myAccount: "マイアカウント",
    totalOutput: "総アウトプット",
    walletBalance: "ウォレット残高",
    exchangeable: "交換可能",
    exchange: "交換",
    withdraw: "引き出し",
    record: "記録",
  },
  exchange: {
    redeem: "すべて引き出す",
    exchange: "交換",
    convert: "EthコインをUSDCに変換",
  },
  withdraw: {
    total: "総残高",
    confirm: "確認",
    tip: " あなたの引き出しは、今後24時間以内にUSDCウォレットアドレスに送られます",
  },
  record: {
    time: "時間",
    quantity: "数量",
    status: "ステータス",
    output: "アウトプット",
    exchange: "交換",
    withdraw: "引き出し",
    interest: "利息",
    rebate: "リバウンド",
  },
  loan: {
    title: "貸し出し金額",
    dueDate: "期日",
    tips: ["簡単な書類でローンする"],
    form: "ローン申請フォーム",
    download: "ダウンロード",
    print: "印刷して記入",
    upload: "写真を撮ってアップロード",
    btn: "アップロード",
    file: "ファイルをダウンロード",
    cancel: "キャンセル",
    uFile: "ファイルをアップロード",
    tip: "アップロードをクリック",
    doc1: "PERSONAL LOAN AGREEMENT 1.doc",
    doc2: "PERSONAL LOAN AGREEMENT 2.doc",
    file1: "PERSONAL_LOAN_AGREEMENT-1.docx",
    file2: "PERSONAL_LOAN_AGREEMENT-2.docx",
  },
  nav: {
    home: "ホーム",
    lang: "言語",
    loan: "ローン",
    paper: "ペーパー",
    pool: "プールデータ",
    trading: "取引",
  },
  whitepaper: {
    title: "USDC ホワイトペーパー",
    view: "表示",
    eth: "イーサリアム ホワイトペーパー",
  },
  nft: {
    vol: "取引量",
    amount: "取引金額",
    qty: "NFT数量",
    users: "利益ユーザー",
    quantity: "数量",
    totalVol: "総取引量",
    lastestTrans: "最新取引価格",
    collQuantity: "コレクション数量",
    showMore: "もっと見る",
    pd: "プロジェクト詳細",
    sellPrice: "売価",
    royalty: "ロイヤリティ",
    royaltyTip: "この取引のすべてのロイヤリティはチャリティーに寄付されます",
    buy: "購入",
    basic: "基本情報",
    contract: "コントラクトアドレス",
    network: "ネットワーク",
    attribute: "属性",
    trait: "この特性を持っている",
    records: "記録",
    balance: "バランス",
    sellNow: "今すぐ売る",
    cancel: "キャンセル",
    buyNow: "今すぐ買う",
    pay: "支払う",
    transPass: "取引パスワード",
    transTip:
      " 取引パスワードを覚えてください。取引パスワードを忘れた場合は、プラットフォームのオンラインカスタマーサービスに連絡してください。",
    newPass: "新しいパスワード",
    rePass: "パスワード確認",
    confirm: "確認",
    purchaseTime: "購入時間",
    purchaseAmount: "購入数量",
    price: "価格",
    priceTip: "売価を入力してください",
    sell: "売る",
    digitTip: "6桁の数字を入力してください",
  },
  info: {
    1: [
      "USDCは、トレーダーが利息を得るために利用するコインです。これを行う方法は複数ありますが、これは暗号通貨の独特の機能の一つであり、伝統の銀行では利益が非常に少ないためです。USDCトークンの使用方法を理解することは、cryptosを取引する人々にとって価値があります。",
      "目次",
      "1 什么是USDC？",
      "2 USDCの利息はどのように機能しますか？",
      "3 USDCで利息を得る方法は？",
      "4 USDCで利回りを得る異なる方法は何ですか？",
      "5 USDCで利息を得る価値はありますか？",
      "6 FAQ：よくある質問",
      "USD Coin (USDC)とは何ですか？",
      "「USDC」と呼ばれるUSD Coinは、安定コインと呼ばれるタイプの暗号通貨です。アメリカで最大の2つの規制された金融機関であるCircleとCoinbaseが発行しており、準備資産によって完全にサポートされています。1つのUSDCの価格は1ドルにペッグされています。言い換えれば、それは1対1で米ドルと引き換え可能です。",
      "これにより、cryptoウォレットでのUSD-ペギング取引が可能になります。USD Coinは、イーサリアムネットワークによって動かされるERC-20イーサリアムトークンであり、イーサリアムを受け入れる任意のウォレットに保管できます。これは、crypto空間のほとんどのユーザーにとって非常に柔軟で使いやすいものです。",
      "USDCの利息はどのように機能しますか？",
      "他の資産の価値にペッグされた価値を持つstablecoinsは、利息を得るために理想的です。USDCの場合、それは1ドルに価値があります。これらのコインで得られる利息は、他のcryptoよりもはるかに高く傾向があります因为它可以被更容易地量化。資産が安定している場合、報酬を提供するのは簡単です、因为它不应该会有太大的波动。USDCを借りることができるようにすることで、企業や個人はブロックチェーンを通じて投資、個人的な理由、またはレバレッジ取引のためのローンを取得できます。彼らは、銀行での伝統的なローンのように、利息を付けてローンを返済することに同意します。言い換えれば、もしあなたが10,000 USDCを借りたら、それは$10,000を借りたのと同じことです。",
      "暗号通貨の利息対stablecoins",
      "あなたはstablecoinsと暗号通貨の両方から利息を得ることができます。しかし、彼らはかなり異なりますし、あなたが対処するリスクプロファイルはそれに反映されます。暗号通貨はstablecoinsよりもはるかにvolatileで、交換はそれらを貸し出すことをはるかに控えめです。暗号通貨は一日の中で価値の30%を失う可能性がありますが、stablecoinsはほとんど動かない。",
      "暗号通貨の極端なvolatileため、交換はそれらの交換でステークされたcryptoのレートを提供する人々に対してはるかに低いレートを提供します。結局、彼らはpay out exaggerated returnsに責任があるかもしれません。一方で、彼らのcryptoをステークする人々は少しの利息増加を得ることができますが、同時に、原資の大幅な損失を被る可能性があります。リスクは常にあります。",
      "USDCのようなトークンはvolatileでないので、stablecoinsは暗号資産の価値を安全に保管する方法として安全です。そのため、交換やブロックチェーンはユーザーに高い利率を提供する意義がで、USDCのAPRは主に「安定している」ために強力です。",
      "なぜUSDCで利息を得るべきか",
      "USDCで利息を得る理由は多数ありますが、利点は以下に含まれており、それ 이상にもあります：",
      "貯金を置き換える：",
      "一部の人々は、過去数年で非常に低い金利を提供してきた貯金口座を置き換えるためにUSDCで利息を得ることがあります。暗号はすぐにその空白を埋めようとしています",
      "より高い利回り：",
      "暗号ステーキングで獲得される金利、特にUSDCでは、伝統的な銀行よりもはるかに高くなっています。",
      "待機中に稼ぐ：",
      "市場は時々危険でvolatileです。そのため、一部のトレーダーは市場を避けながらUSDCをステークします。これは、あなたの口座を続けて成長させるための素晴らしい方法です。",
      "どのようにUSDCで利息を得るか？",
      "USDCの恩恵を受けるには、まずそれを所有している必要があります。USDCを保管することは簡単で、ほとんどの交換で行うことができます。あなたは複数の交換に登録する必要があります。私たちは、あなたが登録した交換にワイヤトランス渡金をすることをお勧めします。あなたのワイヤトランス渡金が交換に到着したら、まず登録した交換でETHを購入し、次にETHをあなたの交換に送信する必要があります。WEB3ウォレットでは、次にWEB3ウォレットで受け取ったETHをUSDCに変換する次のステップです。これは非常に簡単で、数分で完了できます。",
      "USDCで利回りを得る異なる方法は何ですか？",
      "あなたのUSDCで利息を得る多くの異なる方法と場所がありますが、あなたは各プラットフォームの種類の違いを知る必要があります。",
      "交易所を通じてUSDCで利回りを得る",
      "交易所を通じて得るyieldはおそらく最も簡単なプロセスです。ほとんどの交換では、アカウントを作成し、USDCを預け入れて、あなたの好みのyield獲得製品を選択する必要があります。これはUSDCを一定時間ロックする必要があるかもしれない、あるいはしないかもしれません。これは、あなたが流動性を必要とする場合に問題になることがあります。",
      "中央集権型交換を使用する最大の利点は、それは簡単で、おそらくあなたはすでにあなたのcryptoまたは少なくともあなたのcrypto取引のための資金をそのプラットフォームに保持している可能性が高いため、同じプラットフォームで行うことができます。",
      "Pros:",
      "Cons:",
      "簡単で便利です。",
      "USDCを特定の時間ロックする必要があるかもしれません。ユーザー。",
      "同じプラットフォームで行うことができます。",
      "レートが低いかもしれません。",
      "ほとんどの交換は資本が豊富です。",
      "利用可能な製品は限定的かもしれません。",
      "Web3はこの分野のリーダーの1つです。",
      "DeFiでUSDCで利回りを得る",
      "暗号で最も興奮する可能性のある分野の1つは、ユーザーが介入者なしで基本的な金融サービスにアクセスできるDeFiプラットフォームです。ユーザーは、この場合、USDCを分散型貸出プロトコルに預け入れて利息を得ることができます。",
      "この分散型金融の分野では、ユーザーはborrow、lend、および新しい金融製品に投資できます。これはすべてスマートコントラクトとブロックチェーン技術を使用して可能になります。",
      "Pros:",
      "Cons:",
      "スマートコントラクトが使いやすいです。",
      "DeFiプロトコルはユーザーによって間違えたことを元に戻さない。",
      "介入者はいりません。",
      "一部のプロトコルではスケーラビリティが問題になるかもしれません。",
      "他の製品も利用可能です。",
      "流動性は小さいプロトコルで問題になるかもしれません。",
      "分散型金融プロトコルの例にはUniswapやBancorが含まれます。",
      "USDCで利息を得る価値はありますか？",
      "USDCで利息を得ることは、あなたの暗号資産で利息を得る素晴らしい方法です。念のために言及しておきますが、金利は現実の世界の典型的な貯金口座よりもはるかに高くなりますので、より高いリターンを得ることができます。",
      "USDCで利息を得ることは、ほとんどのトレーダーにとって有益です。",
      "あなたがUSDCを使用して利息を得るために選択する理由は多数あります。最も明白なものは、それは金利が非常に低い標準的な銀行よりもはるかに優れています。また、USDCは、一般的なcryptoオフで資産価値が下がる場合に興味を集める素晴らしい方法です。例えば、あなたはBitcoinを現金化し、それをUSDCに変換し、市場が回復するのを待つ間で利息を獲得できます。",
      "何も100%安全ではないことには注意してくださいが、あなたが評判の良い交換または貸出サービスを利用している限り、保護されます。また、これはあなたのポートフォリオの一部を多様化する素晴らしい方法と考えることができますが、USDCで利息を得ることは、非常に高利回りの債券と考えることができます。",
      "ほとんどのトレーダーはUSDCを保有し、市場が対処しにくい時に当然ながら利息を稼ぐために役立ちます。これらの安全な資産をポートフォリオに含めることで、安全性だけでなく柔軟性も得られます。なぜなら、他のコインに変換したり、安全網として使用したりできます。ほとんどのプロトレーダーは、さまざまな時期にこれらのコインの一部を保有しています。",
      "FAQ：よくある質問",
      "USDCコインは利息を払いますか？",
      "コイン自体は利息を払いませんが、多くの交換や貸し手が利息を払います。それは、利息を得るために最も一般的なコインの1つであり、選択肢が豊富です。",
      "USDCは月単位で利息を払いますか？",
      "それがどこで貸し出されているかによります。預け入れ期間は非常に短期から1年を超えるまであります。",
      "USDCを購入することでお金を稼ぐことができますか？",
      "必ずしもありませんが、利息を得ることはお金を稼ぐ方法の1つです。USDCは米ドルにペッグされていますので、基本的に1対1の取引です。",
      "なぜUSDCの利息が高ですか？",
      "USDCの利率は供給と需要の状況によるためです。これは特に「crypto winter」と呼ばれる市場において、供給と需要に応じて上下し、供給と需要に反映されます。USDCの借り入れ需要は、貸し出し可能なUSDCの供給を上回る傾向があります。利率はこれに応じて上昇したり下がったりします。",
      "USDCでお金を失うことはできますか？",
      "理論上はできますが、USDCが米ドルにペッグを維持する限り、それは暗号通貨の世界で最も安全なコインの1つです。",
      "USDCのポイントは何ですか？",
      "USDCには複数の用途がありますが、最も明白なものはブロックチェーン上で米ドルを転送する方法です。しかし、利息を得ることは、それが交換所、貸出サービス、またはDeFi空間に預け入れることができるという点で重要なポイントです。",
    ],
    2: [
      "目次",
      "1. Web3ウォレットとは何ですか？",
      "1.1. Web3とは何ですか？",
      "2. Web3ウォレットはなぜ必要ですか？",
      "3. Web3ウォレットの代替案",
      "3.1. MetaMask",
      "3.2. Coinbase Wallet",
      "3.3. Argent",
      "3.4. Trust Wallet",
      "3.5. Rainbow",
      "4.Web3ウォレットとは何ですか？-概要",
      "Web3への関心が高まるにつれて、さまざまなエコシステムへの参加が激増しています。さらに、もしあなたがこの分野に慣れていないなら、エントリーバリアが高く、克服が難しく思えるかもしれません。しかし、cryptoとWeb3開発への関心が高まるにつれて、業界がユーザーフレンドリーになるにつれて、これらのハードルは次第に消えていくでしょう。アクセスがより簡単になるにつれて、crypto空間も競争が増大するにつれて進化し、新しいdApps（分散型アプリケーション）、トークン、NFT、Web3ウォレットが絶えず開発されます。ウォレットは、cryptoに進入するために不可欠です。なぜなら、Web3空間内でいくつかの基本的な機能を果たすからです。したがって、この記事では、crypto空間をより深く掘り下げるとともに、「Web3ウォレットとは何ですか？」という質問に答えます。",
      "Web3ウォレットが何であるかをよりよく理解することで、私たちはまた市場で最も人気のある代替案のいくつかについて詳しく掘り下げるでしょう。さらに、もしあなたがWeb3開発に興味を持っており、独自のcryptoウォレットを作成したいなら、あなたを正しい道に立たせ、WEB3.0から始まる道を案内します。",
      "WEB3では、オペレーティングシステムのバックエンドインフラストラクチャにアクセスできるため、独自のWeb3ウォレットを迅速かつ効率的に開発することが可能になります。Web3.0はWeb3開発のナンバーワンオペレーティングシステムであり、バックエンドインフラストラクチャに加えて、ユーザーはまた広範なツールボックスにアクセスします。そのツールの1つには、Web3.0 Speedy Nodes、Web3.0 SDK、Price API、IPFSのネイティブサポートなどがあります。",
      "したがって、ブロックチェーン開発者であり保存者になりたい場合は、最初のステップはWeb3.0に登録することです。これを行うには数分しかかからず、登録は完全に無料です。",
      "Web3ウォレットとは何ですか？",
      "あなたがcryptoに参加を検討しているなら、あなたの旅の最初のステップはWeb3ウォレットを取得することです。Web3ウォレットは、Web3空間、DeFi、cryptoにアクセスするために不可欠です。あなたは「Web3」や「cryptoウォレット」という言葉にぶつかりましたか？如果你是新手，让我们先回答“什么是Web3钱包？”这个问题。",
      "まず、Web3ウォレットは基本的にデジタルウォレットです。そのため、デジタルアセットを保管する能力があります。これは、代替可能なものから非代替可能なもの（NFT）トークンまですべてを含みます。第二に、Web3ウォレットはまた、crypto领域に入り込むための扉を開きます。これにより、さまざまなブロックチェーン上のdAppsと対話できます。その結果、ウォレットはあなたに豊富なdAppsの生態系にアクセスするのを助けます。",
      "Cryptoウォレットはしばしば非保管特質を持っており、つまり、ウォレット所有者としてあなたは介入者や仲介業者なしにデジタルアセットを保管できます。これは、他の誰もあなたのトークンにアクセスしないため、あなたユーザーはすべての資産を完全に管理できます。しかし、独占アクセスにより、すべての責任はあなたにあります。つまり、秘密鍵を自分で保持することが必要です。",
      "デジタルアセットをホストする能力に加えて、ウォレットはしばしばその他の機能を提供します。例えば、これはWeb3ウォレットを使用してトークンを送信したり交換したりする可能性を意味します。その結果、cryptoウォレットは、追加のトークンを獲得する方法を含むあなたの資産を完全に管理するために使用できます。",
      "市場には独自の強みを持ちながら異なる種類の多くのウォレットがあります。その中でも最も人気のあるものはMetaMask、Coinbaseウォレット、DeFiウォレットTrustWallet、Argent、Atoken、Hyperpay、Bitkeepなどがあります。しかし、これらの代替案について後ほど詳しく掘り下げる予定です。さらに、Web3ウォレットに密接に関連しているWalletConnectという話題も、もう少し詳しく見てみる価値があります。",
      "Web3とは何ですか？",
      "ブロックチェーン業界が登場したにつれて、「Web3」という言葉を聞いたことがあると思います。しかし、未経験者にとっては、これは馴染みのない言葉であり、多少混乱を招く可能性があります。したがって、このセクションでは、Web3が何であるかを説明し、要約します。",
      "一般的に言えば、Web3はインターネットの「最新世代」または「段階」と言います。あなたが推測できるように、前の世代はWeb1とWeb2であり、あなたがより馴染んでいる段階です。インターネットの3つの世代は特定の時点で始まり、単一のエンティティによって革命的インターネットを開始されることはなく、しかし、各段階には独自の特徴があります。Web1は静的で、Web2はダイナミックで、Web3は分散型です。",
      "分散型がインターネットの最新段階の中心概念であるため、データの分散が主导されています。Web2とは異なり、データを所有する単一の中央集権エンティティはなく、代わりに分散型であり共有されています。さらに、Web3は、企業が大量の個人情報を所有する問題も最終的に解決します。なぜなら、ユーザーが自分のデータを管理するからです。",
      "Web3エコシステムでは、もう一つの重要なコンポーネントがあります。それはdApps（分散型アプリケーション）です。これらは一般的にブロックチェーンに基づく分散型アプリケーションであり、現在の最大のdAppsエコシステムはイーサリアムブロックチェーンでホストされています。dAppsの分散型性により、中央集権化に伴うさまざまな問題、例えば単一障害点を排除しながら、強力なアプリケーションを開発することができます。",
      "しかし、インターネットの最新段階についてもっと学びたい場合は、Web3をより詳しく説明する記事をお勧めします。",
      "なぜWeb3ウォレットが必要ですか？",
      "「Web3ウォレットとは何ですか？」というセクションで、なぜ誰かがそのようなウォレットを必要とするのかについて簡単に触れましたが、この記事のこの部分では、これらの点を詳しく説明します。だから、Web3ウォレットはなぜ必要ですか？",
      "Web3ウォレットの第一の使用例は、あなたのcrypto資産を完全に管理する能力です。これを例示するために、市場で最大のcryptoウォレットであるMetaMaskを詳しく見ていきます。MetaMaskアカウントをお持ちの場合は、ウォレットの操作方法にすでに馴染んでいるかもしれませんが、ここではインターフェースの様子を見ていきます。",
      "ご覧のように、「資産」と「活動」の2つの異なるタブがあります。「活動」タブは、あなたが独立して探検できる取引履歴を単純に展示しています。また、「資産」タブでは、すべての資産を表示することができます。さらに、購入、送信、交換の3つのアクションボタンがあります。ボタンは自己説明的なものですので、これ以上の詳細はカバーしません。",
      "しかし、これはcryptoウォレットとして、MetaMaskを通じてあなたのすべての資産を完全に管理できることを示しています。また、これはユニークではなく、他のほとんどのウォレットも同様にあなたの資産を管理することを許可します。",
      "Web3ウォレットが必要な第二の理由はアクセス性です。Cryptoウォレットはさまざまなブロックチェーンへのゲートウェイです。したがって、Web3ウォレットを使用すると、ユーザーはdAppsに簡単にアクセスできます。crypto資産は、ほとんどのアプリケーションと対話するために必要です。そのため、ユーザーはウォレットを使用して自分自身を認証し、取引を支払う手段を確保できます。",
      "ユーザー認証にさらに興味がある場合は、MetaMaskを使用して認証する方法や、WalletConnectを使用してユーザーを接続する記事をご覧ください。",
      "Web3ウォレットの代替案",
      "前に述べたように、Web3ウォレットの数が増大しているのは、より多くの人々がcrypto空間に参入する効果です。これは単純な「供給と需要」の問題であり、企業が新興市場で成長する機会を見る結果です。しかし、これは選択肢の豊富さからユーザーが選択しようとするときに混乱を招く可能性があります。",
      "そのため、あなたが選択できる5つの代替ウォレットをハイライトします。ほとんどのそれらが同じ目的を果たしますが、多少異なる点がありますし、それぞれ独自の方法でユニークです。5つのWeb3ウォレットは次のとおりです。",
      "MetaMask",
      "Coinbase Wallet",
      "Argent",
      "Trust Wallet",
      "Rainbow",
      "それでは、MetaMaskに詳しく見てみましょう。",
      "MetaMask",
      "私たちのリストの最初のWeb3ウォレットはMetaMaskであり、これは市場で最も有名なウォレットの1つであり、世界中で2100万以上のユーザーを持っています。ウォレットは、モバイルアプリケーションまたはブラウザ拡張機能の2つの異なる形で利用可能です。私たちは以前にMetaMaskを通じて、なぜ誰かがcryptoウォレットを必要とするのかを強調する際に言及しました。私たちは、MetaMaskを通じてcrypto資産を完全に管理できることを指摘しました。",
      "しかし、MetaMaskがイーサリアムのdAppsの生態系へのゲートウェイであることを指摘しなかったことがあります。さらに、イーサリアムは最も重要な開発ブロックチェーンであり、MetaMaskユーザーには数千のアプリケーションがあります。したがって、イーサリアムが成長を続けるにつれて、MetaMaskウォレットを持っていることは非常に有益です。MetaMaskの詳細なガイドについては、私たちのMetaMask説明記事をご覧ください。",
      "Coinbase Wallet",
      "あなたが暗号通貨に関心を示しているなら、おそらくCoinbaseを聞いたことがあるでしょう。Coinbaseは世界で最大の暗号通貨プラットフォームの1つであり、ユーザーフレンドリーなプラットフォームに加えて、Coinbase Walletという形でWeb3ウォレットも提供しています。",
      "Coinbase Walletは、中央集権型のCoinbaseプラットフォームから独立した非保管Web3ウォレットです。したがって、これはユーザーにdAppsとcryptoの世界をCoinbaseの中央控制から独立して体験する方法を提供します。さらに、Coinbaseウォレットの独特の機能は、ユーザー名に基づいてcrypto資産を送信および受信する能力です。ウォレットアドレスに基づいて資産を送信することしかできません。これらは言葉と数字の長い難解なシーケンスなので、指定されたアドレスにcrypto資産を送信および受信する際にはコピーアンドペーストする必要があります。",
      "さらに、トークンの送信方法についてもっと学びたい場合は、ERC-20トークンの送信方法に関するこちらの記事をご覧ください。これは、Web3.0で作業する際にERC-20トークンを送信する簡単さを強調しています。",
      "第三のウォレットはArgentであり、市場の多くのWeb3ウォレットに加わる比較的新しい追加です。Argentはイーサリアムユーザーを対象としており、イーサリアムトークンのみと互換性があります。さらに、ウォレットはdAppsとDeFi（分散型金融）を使用する際のユーザーフレンドリーな顧客体験を提供することに焦点を当てています。彼らはArgent dAppにdAppsとプロトコルをネイティブに統合することによってこれを実現しています。その結果、彼らのアプリを通じて資産を借りたり貸したりすることができ、アプリ内ブラウザの使用を避けることができます。",
      "DeFiについてもっと学びたい場合は、WEB3の「What is DeFi？」記事をご覧ください。また、DeFi 101とDeFi 201のブロックチェーンコースを詳しく見てみてください。DeFiスキルを発展させたい場合はこれらをご覧ください。",
      "しかし、Argentの最大の弱点の1つは、ウォレットがイーサリアムネットワークのみを対象とすることです。そのため、例えばビットコインブロックチェーンなどの他のチェーンに固有のトークンを保管することはできません。",
      "Trust Wallet",
      "第四の選択はTrust Walletであり、Argentとは異なり、このウォレットはほぼすべての種類のトークンを保管する可能性があります。ウォレットはいわゆるブロックチェーン不可知論者であり、つまり、さまざまなチェーンの資産をサポートします。さらに、Trust Walletは市場で確立しており、世界中で1000万以上のユーザーを持っています。Trust Walletが多数の資産とブロックチェーンをサポートしているため、このウォレットは分散型ウェブの提供するすべてを利用する合理的な選択です。",
      "Rainbow",
      "最後のオプションはRainbowであり、イーサリアムネットワークをサポートする非保管ウォレットです。さらに、RainbowはイーサリアムネットワークのdAppsをネイティブにサポートしています。Argentと同様に、Uniswap V2はアプリケーション内でスワップを実行するために使用されます。Rainbowウォレットの他の素晴らしい機能は、NFTサポートです。これにより、ウォレットはすべてのデジタルアセットをきれいに表示できます。",
      "これらは多数の代替案のほんの一部であり、短い説明から NOTICE が必要なことに気づいたかもしれませんが、それらはすべてお互いに似ていると同時に異なります。そのため、特定のニーズに最も適したウォレットを見つける仕事はあなた次第です。",
      "Web3ウォレットとは何ですか？ — 概要",
      "あなたがcryptoの世界に参入を計画しているなら、最初に必要なことはWeb3ウォレットです。cryptoウォレットにより、すべてのデジタルアセットを管理しながら、分散型dAppsの生態系にアクセスすることができます。さらに、これは分散型がWeb3の本質であるため、すべての資産を完全に管理しながら可能です。そのため、取引、貸し出し、借入などのアクションを実行するために介入者はいりません。",
      "分散型ウェブへの関心が高まるにつれて、異なるウォレットの選択肢の数が大幅に増加しました。しかし、MetaMask、Coinbase Wallet、Argent、Trust Wallet、Rainbowの5つが最も人気のある選択肢です。これらの代替案は多くの点で類似していますが、あなたのニーズや状況によって、いくつかのそれらが他のそれらよりも適しています。",
      "さらに、WEB3の力により、特定の機能を持つ独自のウォレットを簡単に迅速に開発することができます。これは、Moralisユーザーがオペレーティングシステムのバックエンドインフラストラクチャを利用できるためです。これにより、数分で完全に機能するWeb3ウォレットを作成することが可能になります。",
      "しかし、Moralisの限界はそこで止まらないです。実際、すべての今後のブロックチェーンプロジェクトの開発時間を大幅に短縮することができます。まだ説得されていない場合は、イーサリアムdAppsを作成する方法、独自のNFT市場を開発する方法、または5ステップでBSCトークンを作成する方法を簡単に説明するブログ記事をご覧ください。",
      "だから、Web3開発のゲームを強化する野心がある場合は、すぐにWEB3に登録してください。これにより、バックエンドインフラストラクチャとMoralisツールにアクセスできます。",
    ],
    3: [
      "Web3はWeb3のコンセプトを促進し、ベトナムのUELで奨学金を提供します...",
      "Web3は、最新の学生限定イベント「Gate Web3 - Empowering GenZ in Web3」が成功裏に終了したことを嬉しく発表します。この教育的および慈善的活动は、2023年10月28日、ベトナムのホーチミン市の経済法大学で午後1時から午後4時30分まで開催されました。",
      "このイベントは、次世代、特にジェネレーションZに、急速に進化するWeb3技術の世界における知識と機会を提供することを目的としています。参加者は、革命的なWeb3のコンセプトと、それがなぜ未来の主要技術になるにつれていきますかについての貴重な洞察を得ました。さらに、专家们は、基本的なスキルセット、どこから始めればよいか、そしてこのダイナミックな分野での成功したキャリアパスを築く方法についての指導を提供しました。",
      "Web3は、学費と生活費の経済的支援が必要な不利な状況にある学生を支援するために、奨学金を提供することにコミットされています。キーノートスピーカーのMs. Mai Ngoさん",
      "イベントでは、Ms. Mai Ngoさん、BSCSのMr. Tuan Anhさん、Investpush Legalの管理弁護士であるMr. Dao Tien Phongさんを含む業界の専門家によるパネルディスカッションも開催され、ブロックチェーン技術におけるキャリアの機会について貴重な洞察を共有しました。参加者は、これらの専門家と有意義な対話に参加し、貴重な知識と指導を得ました。",
      "インフォメーティブなセッションに加えて、Web3とGate Charityはイベント中に奨学金を授与しました。15の奨学金が、academic performanceが優れている不利な状況の学生に与えられ、それぞれが1,000,000 VNDを価値しています。",
      "イベントは、FTC大学からの約100人の学生と講師を招待する予定です。イベントでは、オンラインでの存在感も大幅に持ち、オンラインの視聴者数に50,000人を期待しています。",
      "Web3は、このイベントを開催して、専門家、学生、Web3技術の支持者を統合して、この革新的な分野での教育、認識、そして機会を促進することに興奮しています。",
      "Gate Charityについて",
      "Charityは、グローバルな非営利慈善団体で、世界中でブロックチェーン慈善運動を推進しています。Missionは「バランスのとれた世界を促進する」ことで、cryptoコミュニティを統合して貧困の差距を埋め合わせ、恵まれない人们的生活を改善し、教育の公平性を最大化し、環境の持続可能性を促進するブロックチェーンネットワークとcryptoイニシアティブを通じています。",
      "免責事項：",
      "この活動に関するすべての内容は、慈善目的のみを目的としており、いかなる種類の専門的なアドバイスを構成しません。コンテンツに含まれるものは、誰かが彼らのサービスや製品を利用または購入することを勧誘、推薦、支持、または提供するものではありません。Gate Charityは、この活動と関連する情報の最終的な解釈権を留保しており、サポート@gatecharity.orgへのお問い合わせでGate Charityに連絡してください。",
      "1,700を超える暗号通貨を安全に、迅速に、簡単にWeb3で取引するゲートウェイ",
      "Web3に登録して、参照紹介で40%のコミッションを得てWeb3ストレージスロットを手に入れよう。",
      "私たちに連絡してください！",
      "チームは2023年10月23日",
    ],
    4: [
      "ブロックチェーン アクセスによる生活の変化",
      "ブロックチェーン ソリューションを通じて、いつでもどこでも必要なときに誰もが金融サービスや支援にアクセスできる世界を構築します。",
      "寄付総額 (USD)",
      "受益者合計",
      "恩恵を受ける地域",
      "世界におけるバランスの取れた開発の促進",
      "貧困の撲滅",
      "ブロックチェーンと暗号通貨は、平等な教育と雇用機会の創出、貧困に苦しむ人々の支援、そして何百万人もの人々の貧困からの脱却に役割を果たすことができると考えています。",
      "恵まれない人々の支援",
      "女性、障害者、恵まれない子ども、高齢者、恵まれない地域の脆弱な家族を支援することに重点を置いて、重要なリソースとサポート システムへの障壁を取り除き、包摂性、アクセシビリティ、繁栄と成長の能力をもたらします。",
      "地球の保護",
      "地球は 1 つしかありません。私たちは常に、気候と自然環境への人間の被害を減らすために行動します。健全な環境とは、未来の世代に普遍的に感じられるプラスの影響を生み出すことを意味します。",
      "人道支援の提供",
      "紛争の被災者への支援、被災地の復興、避難民への支援、医療が行き届かない地域への支援など、状況が最も深刻なときに災害や危機の被災者にタイムリーな救援を提供します。",
      "透明で追跡可能な募金活動",
      "ブロックチェーン技術を使用して、透明で信頼性の高い慈善活動を実現し、恵まれない人々を真に支援します。",
      "NFT アート作品募金チャンネル",
      "NFT オークション募金により、アート作品の価値を広めながら、慈善目的を効果的に達成できます。",
      "メタバース チャリティー ゲーム プロジェクト",
      "教育的で楽しい方法を提供し、すべての人の心に慈善の種を広める多様な Web3.0 ソリューション。",
      "ブロックチェーン技術を活用して人々に利益をもたらす",
      "世界をリードするブロックチェーン技術",
      "安全で革新的なブロックチェーン技術を通じて、慈善寄付が透明で安全で信頼できることを保証します。経済的支援を必要とするすべての人が、あらゆる面で支援を受けられることが保証されています。",
      "幅広くサポートされているデジタル資産リポジトリ",
      "当社は、世界で最も幅広いデジタル資産プールをサポートしていることを誇りに思っています。つまり、お持ちのデジタル資産を使用して、この素晴らしい慈善活動に参加できるということです。",
      "ブロックチェーン教育へのアクセス",
      "当社は、教育がすべての根源であると信じており、基本的なブロックチェーン教育を行うことは、将来のブロックチェーン構築に不可欠な部分となる、当社が支援する人々にとって有益です。",
      "慈善目的の多国間協力",
      "当社は、あらゆる形態の統合が最終的に慈善活動自体に役立つことを理解し、最高の機関、プロジェクト、業界の専門家を結集することで、ブロックチェーンの有効性を最大限に高めることに尽力しています。",
      "リーダーボード",
      "10 月 21 日、午前 8 時から午後 6 時 (GMT -3)、Web3 Charity は A. A. Garotos de Ouro および Gate.io と提携して、ブラジルの有名な Arena Brahma で特別なキッズ デー イベントを開催しました。この素晴らしい集まりには、近隣のコミュニティから約 150 人の子供たちが集まり、皆、熱心で興奮していました。",
      "世界的な非営利慈善団体であるWeb3 Charityは、2023年10月6日にフィリピンのバクラヨンで「海洋保護」をテーマにしたイベントを成功裏に開催しました。このイベントでは、地元の環境保護活動家2人とアーティストのJRが集まり、この地域の海洋生態系の保護を推進しました。",
      "私たちの取り組み",
      "ソマリア食糧危機支援プロジェクト",
      "Gate CharityとWorid Visionに参加して、ソマリアの飢餓被害者を支援しましょう",
      "募金",
      "寄付",
      "今すぐ寄付",
      "支援対象",
      "世界の少なくとも 45 か国で、約 5,000 万人が飢餓の危機に瀕しています。アフリカ、ラテンアメリカ、中東、アジアの国々が影響を受けており、現代ではこのような飢餓危機は見られていません。ソマリアなどの国では、何百万人もの人々が飢餓に苦しんでいます。干ばつ、洪水、その他の異常気象などの気候変動の悪影響により、作物や収入が破壊され、一部の国ではイナゴの大群が大量発生して収穫が壊滅し​​ています。COVID-19 パンデミックの影響により、食品やその他の商品の価格が世界的に高騰しています。これにより、家族は生き残るために必要な基本的な食料品を購入できなくなっています。一方、世界各地で起きている紛争により、何百万人もの人々が故郷を追われ、家や命、生計が破壊されています。Web3 Charity と World Vision は、ソマリアの飢餓に苦しむ家族、特に人道支援に頼って生き延びている子どもたちに食糧を提供するために協力します。",
      "国連世界食糧計画との合意により、1 人から寄付された 1 USDC は 27 倍になります。1,000 USDC の寄付は、WEP の寄付により 27 USDC になります。",
      "世界の小麦生産の発祥地であるウクライナでの戦争を含む複数の紛争の影響により、世界で最も弱い立場にある子どもたちにとって最悪の状況が生まれています",
      "一方、世界各地で起きている紛争により、何百万人もの人々が故郷を追われ、家や命、生計が破壊されています。世界中の避難民の 3 分の 2 が飢餓のために家を追われたことをご存知ですか?",
      "COVID-19パンデミックの影響により、食料やその他の商品の価格が世界的に高騰しています。これにより、家族は生きていくために必要な基本的な食料品を購入できなくなっています。",
      "干ばつ、洪水、激しい嵐、予測できない天候など、気候変動の悪影響により、作物や収入が破壊され、一部の国ではイナゴの大群が大量発生して収穫が壊滅し​​ています。",
      "飢餓と闘うために力を合わせる",
      "45 か国で約 5,000 万人が影響を受けている前例のない飢餓危機に対応するため、web3 Charity は World Vision と提携しました。このパートナーシップは、特にソマリアのような飢餓に苦しむ地域で、切実に必要としている人々に重要な食糧支援を提供することを目的としています。このコラボレーションは、アフリカ、ラテンアメリカ、中東、アジアのさまざまな地域で深刻な規模に達している飢餓という重大な問題に対処することを目指しています。",
      "World Food Program で効果を最大化",
      "Web3 Charity は World Vision と連携して、国連世界食糧計画 (WFP) と協力し、個人の寄付を活用しています。個人が寄付する 1 USDC は 27 倍に増幅され、1,000 USDC の寄付は 27,000 USDC になります。これらのリソースは、飢餓に苦しむ地域の家族、特に生存のために人道支援に頼っている子供たちに不可欠な食糧援助を届けるために不可欠です。この取り組みには、家族への食料パッケージの配布や、幼い子どもたちへの治療的栄養の提供などが含まれており、この危機的状況における彼らの当面の栄養ニーズに対応しています。",
      "当社のパートナー",
      "ワールド ビジョンは、世界中の貧困と闘い、脆弱なコミュニティ、家族、子供たちの持続可能な開発を促進するキリスト教の NGO です。この組織は、困っている人々に毎分水、食料、エンパワーメントを提供しています。約 100 か国に拠点を置くワールド ビジョンは、緊急事態、危機、自然災害に迅速に対応できます。2021 年、この組織は 52 か国で緊急事態の影響を受けた 3,010 万人以上の人々を支援しました。ワールド ビジョンのアカウントは、透明性を確保するために毎年監査されています。",
      "インドネシアの恵まれない子供たちのための SLB スリ スエデウィ スクールを支援しましょう",
      "ゲート チャリティと Worid Vision に参加して、ソマリアの飢餓被害者を支援しましょう",
      "集められた寄付",
      "寄付",
      "今すぐ寄付",
      "支援する活動",
      "1982 年に設立された SLB Sri Soedewi 障害者学校は、100 名を超える生徒を受け入れ、視覚障害、聴覚障害、知的障害、身体障害、自閉症など、さまざまな障害を持つ生徒のためのプログラムを提供しています。小学校、中学校、高校の生徒を受け入れています。この学校は、生徒一人ひとりのユニークな可能性と興味を生かし、学力とさまざまなスキルを育み、生徒の総合的な成長を確実にすることに重点を置いています。究極の目標は、生徒が社会で自立した生活を送ることができるライフ スキルを身に付けることです。Web3 Charity と SLB Sri Soedewi 障害者学校は、包括性を促進し、すべての子供に安全で支援的なプラットフォームを提供することに尽力しています。",
      "すべての寄付は生徒の生活に意味のある変化をもたらし、あなたもその一員になることができます。",
      "視覚障害を持つ生徒に対する質の高いサポートと学業生活は、自尊心を築き、重要な友情を育み、未来が開かれているという確信を与えるのに役立ちます。",
      "適切な学校環境は、子どもの社会的、文化的ニーズに対する重要な長期的な対応です。SLB Sri Soedewi は、生徒が輝けるように、感情的、言語的、学問的なサポート システムを開発する機会を提供します。",
      "障害は生活の妨げになるものではなく、一部の活動にアクセスして参加することの障壁でもありません。ただ、彼らは特別なので、できない活動を別の方法で行っているだけです。",
      "団結の精神は、包摂性を育み、素晴らしい笑顔を作り、お互いに手を握って強い絆を築くための重要な要素の 1 つです。",
    ],
    5: [
      "Circle が初の年次「USDC 経済の現状」レポートを発表",
      "以下は、レポート「USDC 経済の現状 – デジタル通貨の実用価値段階へようこそ」の序文です。",
      "「USDC 経済の現状」レポートの全文をダウンロードし、Circle.com/Davos で世界経済フォーラム #WEF23 のアジェンダをご覧ください",
      "ドル デジタル通貨がお金、支払い、金融の次の進化を推進",
      "10 年前、私は、従来のお金をデジタル通貨の形でインターネットにネイティブに持ち込み、オープンで相互運用可能なグローバル ネットワークで利用できるようにできるというビジョンを持って Circle を共同設立しました。インターネットの最初の数十年間を特徴づけた摩擦のない情報交換と通信を反映した、摩擦のない価値交換の世界の到来は、それ以来 Circle の活動の原動力となっています。",
      "2013 年には、デジタル通貨とブロックチェーン技術の技術的成熟度、業界の理解、規制意識は事実上存在しませんでした。実際、「インターネット上のドル」のプロトコルを構築するための必要条件さえ存在していませんでした。これは少なくとも 10 年から 20 年の道のりであり、デジタル通貨がオープン インターネットに与える影響は、情報のインターネットよりもさらに大きなものになることはわかっていました。",
      "10 年後の今、世界をリードする規制されたドル デジタル通貨である USD Coin (USDC) が主流になりつつあります。ほぼすべての指標とトレンドにおいて、USDC エコノミーは成長し、健全であり、インターネット規模での経済活動を促進する大きな力になりつつあります。固定されたインフラストラクチャや金融ウォールド ガーデンに依存した銀行では不可能な人々や市場にリーチします。",
      "ビットコインやその他の暗号資産の投機の時代は激動の 1 年を経てきましたが、USDC などのデジタル通貨は、ブロックチェーンとプログラム可能で構成可能な常時接続マネーの実用価値フェーズへの必要な移行を告げています。インターネット時代において、これらの責任あるイノベーションを推進することは、従来の金融システムと競争することではなく、未完の仕事を完了することです。",
      "この機会を大局的に見ると、USDC のようなドル デジタル通貨は、2 兆ドルを超える決済業界の収益のかなりの部分を吸収し始め、21 兆ドルの M2 マネー サプライの新しいデジタル表現になる可能性があります。",
      "たとえば、小売業者は、この進化の重要性を理解しています。85% 以上がデジタル通貨決済の有効化を優先しています。USDC の POS 決済は初期段階ですが、すでに導入されています。また、Checkout.com、Mastercard、Plaid、Stripe、Visa、FIS の Worldpay など、多くの従来の決済プロバイダーは、小売業者が可能な限り簡単に USDC を受け入れられるようにするために、USDC を使用して構築しています。",
      "パブリック ブロックチェーン インフラストラクチャは、「ダイヤルアップからブロードバンドへ」のアップグレードを経て、ほぼ瞬時に、非常に安全で、プログラム可能で、低コストのトランザクションを促進しています。Web2 のビルダーと開発者は、ますます多くのスタートアップ、企業、従来の決済および金融サービス企業が価値のインターネットへの接続を目指すにつれて、Web3 に集まっています。",
      "一方、世界中の政策立案者は焦点を絞り、信頼できるデジタル通貨を銀行や決済規制当局が一貫して調和のとれた方法で監督するための、切望されている規制を導入しています。",
      "Circle は創業以来、インターネットネイティブの基盤、つまり価値の保存と伝送、そして最終的にはより広範な資本の仲介のためのオープンでグローバルかつ相互運用可能なパブリックインターネットインフラストラクチャの上に新しいグローバル経済システムを構築できるという考えに突き動かされてきました。そのビジョンは現実になりつつあり、2023 年はお金、決済、金融の次の進化において重要な年となるでしょう。",
      "当社の最初の USDC 経済の年次回顧レポートで説明されているように、世界的な実用価値の緑の芽が至る所で芽生えており、グローバル金融システムの完全性を保護しながら繁栄への道を改善できるオープンで責任ある金融サービスイノベーションの波を引き起こしています。",
    ],
    6: [
      "2024 年の USDC 経済の現状に向けた新情報",
      "新しいインターネット金融システムが出現しています。USDC、ブロックチェーン ネットワーク、そして世界最高の開発者やテクノロジー企業の力により、金融システムはアップグレードされています。より高速で、より簡単で、より安全になり、お金は誰にとってもオープンになります。",
      "序文",
      "過去 10 年間、ブロックチェーン テクノロジーの台頭により、インターネットとグローバル金融の融合が促進されました。この融合により、金融システムのソフトウェア アップグレードが実現し、まさに変革をもたらすものとなっています。",
      "これが、2013 年に Circle が設立されたきっかけです。当社は、オープン インターネット ソフトウェアのコア要素をお金の世界に注入することに重点を置いてきました。当社の使命は、インターネットのオープン性と規模を利用して、摩擦のない価値交換を通じて世界経済の繁栄を高め、新興の新しいインターネット金融システムを構築することです。",
      "ソフトウェアは、一世代前に多くの従来の業界を進化させたように、金融の世界を席巻する態勢が整っています。通信、娯楽、その他のビジネス活動がより速く、より安く、よりアクセスしやすくなったことで、コストが下がり、障壁がなくなり、イノベーションが盛んになりました。私たちは、商取引のブロックチェーンへの移行が加速していることを、数十年にわたるデジタル変革のトレンドの次の波と見ており、その社会的、経済的影響は、1990年代から2000年代初頭に最初のインターネットによってもたらされたものよりも大きくなる可能性があります。",
      "2000年から2020年にかけて、インターネットの使用率は世界人口の7％未満から60％に増加しました。1ブロックチェーンによって、長い間閉ざされていた従来の金融の側面が開かれるため、ブロックチェーンベースの商取引も同様の成長軌道をたどると予想しています。",
      "今後数年間で、何百万もの企業と何十億もの人々が、この新しいオープンなWeb3インターネットレイヤーを使用して、数兆ドル相当の取引を開始すると予想しています。",
      "オープンマネーは、インターネットのオープン性と相互運用性の設計に根ざした経済的、技術的、哲学的な原則で構成される大きなアイデアです。ウェブは、データ配布の限界費用をゼロにすることで、人類の知識の総計をすべての人が利用できるようにしました。開発者やクリエイターが構築しやすくすることで、サイトやアプリの豊かなエコシステムを実現しました。",
      "しかし、インターネットのプロジェクトは未完成のままです。データは世界中を安全かつ瞬時に無料で移動しますが、インターネット上の金融スーパーハイウェイはまだ舗装中です。",
      "私たちが今日行っていることはすべて、このインターネット金融システムの構築と加速に重点を置いています。",
      "このレポートで詳しく説明しているように、すべては、USDC を世界最大かつ最も広く使用されているステーブルコイン ネットワークに構築する取り組みから始まります。USDC を通じて、私たちはほぼすべての人がどこにいてもインターネット上でドルにアクセスして使用できるようにしています。そして、USDC を企業や開発者が今日の支配的なインターネットおよび金融プラットフォームに匹敵し、それらを再形成できる新しい波のアプリを構築するためのオープン プラットフォームに変えています。",
      "昨年は、ブロックチェーン ベースのインターネット ネイティブ金融の将来にとって大きな転換点となりました。悪質な行為者は悪質な行為の重荷を背負い始め、詐欺が発覚しましたが、基盤となる技術は成熟し、主流の企業は USDC のような技術への関与を深めました。",
      "大手決済会社や商人決済会社は現在、現実世界の問題点を解決するために USDC を使用しています。ブロックチェーン ベースのクレジット市場は、世界中の実際の経済活動をサポートするために USDC を使用しています。インターネット上で生まれたフィンテックやネオバンクは、インターネット コマースのスピードでドルを移動する手段として USDC に注目しています。送金会社や非政府組織 (NGO) は、価値を迅速かつ公正に必要とする人々に移動するという難しい問題に取り組むために USDC を活用しています。従来の銀行、資本市場会社、カストディアンは、ビジネスを成長させる方法として USDC をサポートしています。",
      "この勢いにより、世界中の政策立案者は、支払いステーブルコインやオープン ブロックチェーンに責任を持って関与するためのルールやガイドラインの発行で大きな進歩を遂げています。彼らはこの機会の大きさを理解しており、経済と社会のためにその恩恵を活用する決意を固めています。イノベーターと政策立案者は協力して、インターネット上のお金で何ができるかという技術を再定義しています。",
      "まだ初期段階ではありますが、USDC とより広範なエコシステムが脱出速度に達し、世界中でオープンマネーの力を解き放つ準備ができているという明白な兆候があります。",
      "2024 年版の State of the USDC Economy をお楽しみください。私のチームと私は、調査結果について皆さんと議論することを楽しみにしています。",
      "敬具、",
      "共同創設者、会長兼 CEO",
      "誰でも、どこでも使えるドル",
      "オープンブロックチェーンネットワークは、新しい形のお金の原動力となり、人々や企業が世界中で価値を交換する方法を再定義しています。これらのネットワークは、インターネットを活用して深く根付いたコストと摩擦を減らし、スピードを上げ、国境を越えてデジタルドルを送金したり、他の伝統的な資産のトークン化されたバージョンとやり取りしたりするなど、価値を交換する際に何が可能かを再考する、金融テクノロジーの新しい層を表しています。",
      "この可能性が、Circle が 2018 年に USDC を立ち上げるきっかけとなりました。USDC は、米ドルの強さ、信頼性、ネットワーク効果とオープンインターネットの超大国を組み合わせた支払いステーブルコインです。",
      "短期的な機会は大きく、世界金融の中心にあるドルの役割によって、並外れた影響を生み出し始めています。ラテンアメリカでは貿易請求書の 90% 以上、アジア太平洋地域では 74%、ヨーロッパ以外の地域では 79% がドルで占められています。3 連邦準備制度理事会によると、米国紙幣の約 1 兆ドルが米国外で保有されており、これには存在する 100 ドル紙幣の 3 分の 2 が含まれます。4",
      "ドルの世界的な役割は、今日のステーブルコイン活動の圧倒的多数がドル建てであることを意味します。USDC により、ほぼ誰でも、どこからでも、インターネット経由でデジタルドルにアクセスし、保有し、取引できるようになります。USDC はすでに 190 か国以上で利用可能であり、約 300 万のオンチェーン ウォレットに保有されています。5",
      "インターネット上のお金のためのオープン プラットフォーム",
      "過去数年間、USDC はステーブルコイン ネットワークとして急成長を遂げてきました。2018 年の導入以来、USDC は約 12 兆ドルのブロックチェーン取引の決済に使用されています。より多くの国で、より多くの人々がブロックチェーン上でデジタルドルをアンロックすることのメリットを体験しています。これにより、開発者は新しいアプリを構築し、USDC を中心としたサービスのエコシステムを強化し、USDC の取得、送信、保存がさらに容易になります。",
      "ブロックチェーン技術の使用例の増加は、クラウド コンピューティングの採用曲線を思い起こさせます。クラウドは、その歴史の初期にはニッチでリスクが高いと考えられていましたが、今ではビジネスにとってのクラウドの本質的な利点は明らかです。今日、企業はデータの 60% をクラウドに保存しており、大企業の 10 社中 9 社がマルチクラウド アーキテクチャを採用しています。6 エンタープライズ グレードのブロックチェーンで Web3 を採用する企業が増えるにつれ、私たちはこの進化の次の段階に差し掛かっています。Circle が構築を支援しているインターネット金融システムは、クラウドと競合するのではなく、クラウドの完成に役立ちます。実際、ブロックチェーン ネットワークは、信頼できるデータ、トランザクション、計算能力をパブリック インターネットにもたらします。",
      "エンタープライズ グレードのブロックチェーンで Web3 を採用する企業が増えるにつれ、私たちはこの進化の次の段階に差し掛かっています。",
      "インターネット金融用のドル API USDC は、基本的にパブリック インターネット上のドル API です。これは、規制がしっかりしたオープンソースのビルディング ブロックであり、他のフィンテック、銀行、デジタル通貨プロジェクトに簡単に統合して、世界で最も広く使用されている通貨建てのほぼ即時かつほぼ無料の取引を可能にします。これは、金融技術スタックの急速な進化と API の普及が進む時代に、大きなチャンスを生み出します。",
      "ブロックチェーンの相互運用性のために構築された USDC は、15 のブロックチェーン ネットワークでネイティブに利用できます。時間の経過とともに、開発者が活動し、適切なセキュリティ対策が講じられている場所であればどこでも USDC を利用できるようにし、ブロックチェーン エコシステム全体を簡単かつ安全に流れるようにする予定です。",
      "同時に、ブロックチェーンの複雑さを背景に溶け込ませています。最近リリースされたクロスチェーン転送プロトコル (CCTP) は、USDC をあるブロックチェーンから別のブロックチェーンに送信する際の摩擦を減らし、安全性とセキュリティを高め、コストを削減するのに役立ちます。近い将来、USDC がネイティブであるすべてのブロックチェーンで CCTP サポートを有効にして、ユーザーが USDC をあるブロックチェーンから別のブロックチェーンに移動する際に現在直面している課題を回避し、インターネット経由でドルをシームレスに送信できるようにすることを目指しています。",
      "開発者にとってブロックチェーンが使いやすくなる",
      "ブロックチェーン ネットワーク上に構築している開発者はすでに何万人もおり、今後数年間でこの数字は急速に増加すると予想されます。Circle は、このエコシステムの成長をサポートし、企業や開発者がブロックチェーン ネットワーク上に新しいアプリを構築するプロセスを簡素化するために、プログラム可能なウォレット、スマート コントラクト テンプレート、その他のサービスを提供しています。規模を拡大したインターネット ビジネスでは、USDC やその他のデジタル資産を既存の顧客体験に組み込むだけでよく、ゼロから再構築する必要はありません。",
    ],
    7: [
      "ドルをアップロード",
      "USDC はステーブルコインとも呼ばれるデジタルドルで、24 時間 365 日利用可能で、インターネットの速度で動きます。USDC はインターネット上でネイティブに存在し、世界で最も先進的なブロックチェーンの多くで実行されています。毎日数十億の USDC が取引され1、USDC のデジタルドルはすべて 1:1 で米ドルと引き換えることができます。",
      "常にオン。常にオープン。",
      "USDC は国境や銀行の営業時間を超えます。グローバルに展開するデジタルドルとして、USDC は必要なときにいつでもどこでも利用できます。世界中に USDC を送信したり、商品やサービスの支払いをしたり、将来のために貯蓄したりするのは簡単です。インターネットに接続できる人なら誰でも、USDC を送信、受信、貯蓄できます。",
      "即時のキャッシュフロー。一日中。毎日。",
      "取引を完了して現金を引き出すのを待つ必要はもうありません。USDC は、支払いが世界中を回って電子メールと同じくらい速くアカウントに到着するため、決済時間の概念を時代遅れにします。数秒で完了するあらゆる規模の FX 取引を想像してみてください。それが USDC の力です。",
      "コスト効率よく送金",
      "お金を使うのにお金がかかるべきではありません。 電信送金に 50 ドル支払う必要はもうありません。ほんの数セントで済みます。 加盟店はクレジットカード決済で 2.9% の手数料を回避し、即時のキャッシュフローの恩恵を受け、節約分を顧客に還元できます。",
      "金融の再考",
      "USDC は現金の能力を次のレベルに引き上げます。 USDC を使って、世界中でアクセス可能な暗号通貨資本市場で取引、貸付、借入、資金調達の機会を解き放ちましょう。 可能性は始まったばかりです。",
      "オープンマネー プラットフォーム",
      "グローバルでオープンソース、相互運用可能、無料で使用できる超マネー API を使用して製品を構築します。 開発者は、アプリがユーザーにリアルタイムの支払い、取引、金融サービスを提供するコア ビルディング ブロックとして USDC を信頼しています。 USDC で大きな夢を描きましょう。",
      "USDC は金融を変革しています",
      "USDC は、従来の商取引と暗号通貨の商取引にまたがるグローバル エコシステムの一部です。規制されたフィンテック企業 Circle によって作成された USDC は、信頼性が高く、広く受け入れられ、流動性の高いデジタル ドルです。",
      "信頼できる安定性",
      "USDC は常に 1:1 で米ドルと交換できます。USDC の準備金は、BlackRock や BNY Mellon などの米国の大手金融機関によって管理および保管されています。",
      "米国公認会計士協会 (AICPA) が定めた証明基準に従って、毎月の USDC 準備金の額が流通額以上であることを保証する月次証明を表示します。",
      "Circle は、PayPal、Stripe、Apple Pay と同様に、米国の州法に基づいて認可された送金業者として規制されています。Circle の財務諸表は毎年監査されています。",
      "USDC はどこにでもあります",
      "デジタル ドルの世界への旅を選択してください。企業、ユーザー、開発者が USDC を使用するすべての方法についてさらに詳しく学ぶか、今すぐ USDC を入手してください。",
      "ビジネス",
      "瞬時にキャッシュフローを実現する超高速 USDC 決済で、グローバルな成長を加速します。USDC の流動性にアクセスして、暗号資本市場全体で取引、借入、貸付、投資を行います。USDC にアクセスする最良の方法",
      "エコシステム",
      "USDC で次世代のお金を体験してください。世界中の誰とでも、どこでも、24 時間 365 日、インターネットの速度でデジタル ドルを送金、使用、保存、交換できます。",
      "開発者",
      "今すぐ USDC をアプリに統合しましょう。USDC は、オープンソースで、構成可能で、誰でも構築できるプログラム可能なデジタル ドルです。",
      "リーダーが USDC を選択する理由",
      "透明性であれ、プログラム可能性であれ、USDC は未来を見据えて構築されています。",
      "Consensus 2023 のイノベーターが Circle を使用して構築する理由をご覧ください",
      "パートナーに Circle と USDC を選んだ理由を尋ねたところ、Circle と USDC は構築方法が異なるという回答が多数ありました。Circle は高速で使いやすく、実装も簡単です。また、準備金により、USDC はステーブルコイン業界で際立っています。",
      "Circle Ventures の創設者が USDC をどのように使用しているかをご覧ください",
      "信頼性と透明性は最低限必要なことのように思えますが、Circle Ventures のポートフォリオに含まれる企業の創設者が USDC を選択する主な理由です。その他の理由は、プログラム可能な流動性、ほぼ瞬時の決済速度、効率性などです。",
      "ステーブルコインの法律の基準を設定",
      "USDC は、規制されたデジタル通貨を設計するための青写真と見なされることがよくあります。 Circle は最高レベルで、米国議会のリーダーと緊密に連携して、Web3 のオープンな価値を従来の金融システムと整合させ、調和のとれた相互接続された未来の創造に貢献しています。",
      "USDC は暗号通貨ですか?",
      "USDC は完全に準備されたステーブルコインで、暗号通貨またはデジタル資産の一種です。価格が変動する他の暗号通貨とは異なり、USDC は米ドルとの価格同等性を維持するように設計されています。USDC は、ブロックチェーン技術のスピードとセキュリティの恩恵を受ける安定した価値の保存手段です。\n",
      "USC が常に 1:1 で米ドルと交換可能であることをどのように信頼できますか?",
      "USDC は、流動性の高い現金および現金同等資産によって 100% 裏付けられたデジタルドルであり、常に 1:1 で米ドルと交換可能です。USDC 準備金の一部は、ブラックロックが管理する SEC 規制のマネー マーケット ファンドである Circle Reserve Fund (USDXX) に投資されています。ポートフォリオに関する独立したサードパーティ レポートは毎日公開されています。詳細については、当社の透明性のページをご覧ください。\n",
      "USDC が「鋳造」または「バーン」されるとはどういう意味ですか?",
      "企業は Circle Mint アカウントを開設して、米ドルを USDC に交換できます。企業が Circle Mint アカウントに米ドルを入金すると、Circle は企業に同額の USDC を発行します。新しい USDC を発行するプロセスは「鋳造」と呼ばれます。このプロセスにより、新しい USDC が流通します。\n",
      "同様に、企業が USDC を米ドルに交換したい場合、企業は Circle Mint アカウントに USDC を入金し、米ドルを無料で受け取るようリクエストできます。この USDC の償還プロセスは「バーン」と呼ばれます。このプロセスにより、USDC は流通から外されます。\n",
      "デジタル資産取引所で米ドルが USDC に交換される場合、取引所は通常、スワップを実行するために手元にある USDC の残高を提供します。取引所がスワップを実行するためにさらに多くの USDC を必要とする場合、取引所は多くの場合、Circle Mint アカウントを使用してさらに多くの USDC を鋳造します。",
      "USDC にアクセスするには何が必要ですか?",
      "企業が USDC にアクセスする迅速かつ簡単な方法は、Circle Mint アカウントを申請することです。",
      "USDC は、Coinbase、Crypto.com、Kraken などのデジタル資産取引所からもすぐにアクセスできます。",
      "開発者は、GitHub リポジトリにアクセスすることで、今日から USDC での構築を開始できます。追加のリソースは、USDC for Developers ページから入手できます。",
      "USC はどのブロックチェーンで利用できますか?",
      "USDC は、Algorand、Arbitrum、Avalanche、Base、Ethereum、Flow、Hedera、NEAR、Noble、OP Mainnet、Polkadot、Polygon PoS、Solana、Stellar、TRON の 15 のブロックチェーンでネイティブにアクセスできます。今年以降、さらに多くのブロックチェーンが追加される予定です。",
      "USDC は、サードパーティのブリッジによって多くの新興ブロックチェーンにもブリッジされており、その結果、USDC.e などのブリッジ形式の USDC が作成されています。",
      "詳細については、USDC for Developers ページをご覧ください。",
      "USDC は CBC (中央銀行デジタル通貨) とどう違うのですか?",
      "USDC は民間企業である Circle が発行しますが、CBDC は政府が発行します。ほとんどの CBDC はまだ研究段階ですが、USDC は現在存在し、世界中の何百万人もの人々に広く使用されています。Circle は、USDC を 11 のパブリック ブロックチェーンで実行できるようにする技術を開発しており、オープンソースと民間市場のイノベーションがドル デジタル通貨モデルの急速な進歩を推進しています。",
      "連邦準備制度の CBDC に関する議論に関する Circle の見解について詳しくは、こちらをご覧ください。",
    ],
    8: [
      "USD コイン (USDC) とは何ですか?",
      "スマート コントラクトとは、2 つ以上の当事者が、第三者を介さずに、透明で紛争のない方法でお金、財産、株式、または価値のあるものを交換できるようにする、自動実行のデジタル契約です。",
      "最も簡単に説明すると、スマート コントラクトは複雑な取引の自動販売機に例えることができます。",
      "スマート コントラクトは、ブロックチェーン上のコードに書き込まれた単純な「if/when…then…」ステートメントに従って機能します。コンピューターのネットワークは、事前に設定された条件が満たされて検証されると、アクションを実行します。これらのアクションには、適切な当事者への資金のリリース、車両の登録、通知の送信、チケットの発行などが含まれます。トランザクションが完了すると、ブロックチェーンが更新されます。つまり、トランザクションは変更できず、許可された当事者だけが結果を確認できます。",
      "スマート コントラクトでは、ビットコインを自動販売機 (つまり元帳) に入れるだけで、エスクロー、証書、契約書、商品、運転免許証など、契約の対象となるものがアカウントに簡単に入ります。スマート コントラクトは、注文の条件が満たされたかどうかを判断するためのすべての作業を行います。",
      "スマート コントラクトは、従来の契約と同じように、合意に関するルールと罰則を定義し、それらの義務を自動的に強制します。",
      "スマート コントラクトはどのようにして収益を得るのでしょうか?",
      "スマート コントラクト開発者が作業を収益化するための主なオンチェーン メソッドは 2 つあります。プロトコルの使用に必要な Double Profit 機能を開始すること、さらにブロックチェーンが受け取った報酬からの利益の 90% をブロックチェーンでスマート コントラクトに署名したユーザーに送ること、スマート コントラクト = 貯蓄プランでは、ブロックチェーンがかなりの割合をブロックチェーンの倉庫に送り、トークンの価格が上がるにつれてさらに販売して利益を得ることです。スマート コントラクトの使用に対して 2 倍の手数料を請求します。",
      "例を挙げると、スマート コントラクトは、銀行の定期預金で 2 倍の利息を得るという原理に似ています。",
      "スマート コントラクトはリスクがありますか?",
      "スマート コントラクトは、ブロックチェーン プラットフォーム上の自動実行コードであり、プロセスを自動化し、信頼のない取引を可能にすることで業界を変革しました。そのため、ブロックチェーン ファイナンスでも評価されており、これまでのところ、スマート コントラクトがリスクを伴うケースは発生していません。",
      "では、スマート コントラクトはどこに存在するのでしょうか? どこで実行されるのでしょうか? また、どのように機能するのでしょうか?",
      "知っておくべきこと",
      "スマート コントラクトは、買い手と売り手の間の合意条件がコンピューター ネットワークを介して自動的に検証および実行される、自動実行コード行です。",
      "1998 年に「ビット ゴールド」と呼ばれる仮想通貨を発明したアメリカのコンピューター サイエンティストの Nick Szabo 氏は、スマート コントラクトを、契約条件を実行するコンピューター化されたトランザクション プロトコルと定義しました。2",
      "ブロックチェーンに展開されたスマート コントラクトは、トランザクションを追跡可能、透明、かつ不可逆なものにします。",
      "スマート コントラクトの利点 正確性、スピード、効率",
      "条件が満たされると、契約はすぐに実行されます。",
      "スマート コントラクトはデジタル化され、自動化されているため、書類の処理は不要です。",
      "手作業で書類に記入する際に発生する可能性のあるエラーの修正に時間を費やす必要はありません。",
      "信頼性と透明性",
      "第三者が関与せず、参加者間で暗号化されたトランザクション ログが交換されるため、個人的な利益のために情報が改ざんされることを心配する必要はありません。",
      "スマート コントラクトは、ブロックチェーン ネットワーク上で実行されるコンピューター プログラムによって検証、実行、および強制されます。スマート コントラクトに関与する両当事者がその条件に同意すると、プログラムは自動的に実行されます。契約はブロックチェーン ネットワークによって検証および強制されるため、第三者は不要になります。",
      "スマート コントラクトは人間ではなくコードによって実行されるため、人為的エラーの可能性がなくなり、従来は人間の介入が必要だった多くのタスクを自動化できます。",
      "ブロックチェーンの最も優れた点の 1 つは、許可されたすべての当事者間に存在する分散型システムであるため、仲介者 (仲買人) に支払う必要がなく、時間と紛争を節約できることです。",
      "もちろん、ブロックチェーンには問題もありますが、従来のシステムよりも高速で、安価で、安全であることは間違いありません。このため、Ethereum、Solana、Tezos、Hyperledger などのさまざまなブロックチェーン ネットワークで、より多くのスマート コントラクトが実行されています。",
      "ウォレットを強化",
      "NFT、DeFi プロトコル、USDC などを使用して革新的なエクスペリエンスを作成します。スマート コントラクトを統合することで、ウォレットにさらに多くの機能を追加します。",
      "ロイヤルティと顧客エンゲージメントの向上",
      "NFT ドロップ、ロイヤルティ ポイント、対面エクスペリエンスと交換できる限定トークンなど、顧客と関わり、ロイヤルティを高めるための新しいユニークなエクスペリエンスを構築します。",
      "スワップ、ステーク、ブリッジ",
      "埋め込みスマート コントラクトを使用して、DeFi プロトコルをアプリにシームレスに統合することで、DeFi とのギャップを埋めます。",
      "USDC でスケール",
      "スマート コントラクトを活用して、給与、サブスクリプション、支払いなどのプログラムによる USDC トランザクションを簡単に有効化および管理します",
      "Web3 インタラクションをアプリにすばやく導入",
      "スマート コントラクトに関する Vitalik Buterin",
      "28 歳の Ethereum 創設者 Vitalik Buterin 氏は、DC Blockchain Summit で次のように説明しています。「スマート コントラクト アプローチでは、資産または通貨がプログラムに転送され、プログラムがこのコードを実行し、ある時点で条件を自動的に検証し、資産をある人物に渡すか別の人物に戻すか、または資産を送った人物にすぐに返金するか、またはその組み合わせかを自動的に決定します。」",
      "一方、分散型台帳はドキュメントを保存および複製するため、一定のセキュリティと不変性が確保されます。",
      "例: トランザクションのエスクロー",
      "私からアパートを借りるとします。",
      "暗号通貨で支払うことで、ブロックチェーンを介してこれを行うことができます。仮想契約に保持されている領収書を受け取ります。私はあなたにデジタル入場キーを渡し、それは指定された期日までに届きます。キーが時間どおりに届かなかった場合、ブロックチェーンは払い戻しを行います。",
      "レンタル日より前にキーを送信した場合、関数はそれを保持し、期日が来たときに料金とキーの両方をあなたに、そして私にそれぞれリリースします。",
      "システムはIf-Thenの前提で動作し、何百人もの人が目撃しているので、完璧な配達が期待できます。",
      "私があなたにキーを渡せば、私は確実に支払われます。あなたが一定額のビットコインを送ると、あなたはキーを受け取ります。割り当てられた時間が過ぎると、文書は自動的にキャンセルされ、参加者全員が同時に警告を受けるため、どちらかが知らないうちにコードを妨害することはできません。",
      "スマートコントラクトの実際の使用例の特定",
      "IBMのブロックチェーン技術担当副社長であるジェリー・クオモは、金融サービスからヘルスケア、保険まで、チェーン全体でスマートコントラクトを使用できると考えています。以下にいくつかの例を示します。",
      "DeFiアプリケーション",
      "DeFi アプリが主流になりつつある中、お金の管理方法に大きな変化が起こっています。",
      "分散型金融は、従来の銀行サービスや規範からの脱却です。これは非常に繊細な移行ですが、避けられないものと思われます。",
      "それを証明する数字があります。DeFi の年間取引額は 2020 年に 14 倍に増加し、2021 年には 4 倍以上の 1,120.7 億ドルに増加し、DeFi スマート コントラクトだけで総額 200 億ドルを超える価値がロックされています。",
      "DeFi のスマート コントラクトは、商品、サービス、データ、資金などの交換を促進しています。銀行や信用組合などの中央金融機関のユーザーは、取引を実行するために仲介業者に依存しています。一方、DApps はスマート コントラクトを使用して、各アクションが本物で、透明性があり、人為的エラーがないことを保証しています。",
      "わずか数年で、NFT は市場で爆発的に普及し、2021 年の時価総額は 409 億ドルに迫り、スマート コントラクトの最も成功したユースケースとなりました。",
      "NFT は、ブロックチェーン上に展開されたスマート コントラクトを必要とする鋳造プロセスを通じて作成されます。",
      "スマート コントラクトは、NFT 所有者と購入者の間で売買契約を締結できるツールです。スマート コントラクトには、作品の作成者、NFT が販売されるたびにロイヤリティを受け取る権利のあるその他の当事者、作品の所有権履歴など、NFT に関する情報が含まれています。",
      "NFT の大部分はブロックチェーンに記録されません。なぜなら、大量のデータをブロックチェーンに保存するのはコストがかかり、エネルギーを大量に消費するからです。その結果、スマート コントラクトには、所有者だけが閲覧できる、それが表す作品へのリンクが含まれることがよくあります。",
      "政府",
      "内部関係者は、投票システムが不正に操作されるのは非常に難しいと断言していますが、それでも、スマート コントラクトは、はるかに安全なシステムを提供することで、すべての懸念を和らげます。元帳で保護された投票は、解読する必要があり、アクセスするには過度の計算能力が必要です。誰もそれほどの計算能力を持っているわけではないので、システムをハッキングするには神が必要です!",
      "第二に、スマート コントラクトは低い投票率を押し上げる可能性があります。その惰性の多くは、列に並び、身元を示し、フォームに記入するなど、不器用なシステムから生じています。スマート コントラクトを使用すると、ボランティアはオンラインで投票を転送でき、ミレニアル世代は大挙して大統領に投票するでしょう。",
      "DeFi アプリケーション",
      "2020 年の米国大統領選挙では、ユタ郡はブロックチェーンを利用したモバイル アプリケーション Voatz を通じて不在者投票者の投票を収集することに成功しました。投票者の ID は固有の署名/ハッシュ値の背後に隠され、関連データも外部の脅威から保護されたブロックチェーン内で安全に保たれます。",
      "ブロックチェーンは信頼の源として単一の台帳を提供するだけでなく、正確性、透明性、自動化されたシステムにより、コミュニケーションとワークフローで起こり得る障害も排除します。",
      "通常、ビジネス オペレーションは、承認を待ち、内部または外部の問題が解決するまで、何度もやり取りをしなければなりません。ブロックチェーン台帳はこれを効率化します。また、独立した処理で通常発生し、費用のかかる訴訟や決済の遅延につながる可能性のある矛盾も排除します。",
      "ケース ヒストリー",
      "2015 年、Depository Trust & Clearing Corp. (DTCC) は、ブロックチェーン台帳を使用して、3 億 4,500 万件の取引に相当する 1,500 兆ドル以上の証券を処理しました。",
      "サプライ チェーン 自動車",
      "怠惰な前人類の脊椎動物から超スマートなロボットへと進化していることは間違いありません。すべてが自動化された未来を想像してみてください。Google は、スマートフォン、スマート グラス、さらにはスマート カーでその未来に近づいています。そこでスマート コントラクトが役立ちます。",
      "1 つの例は、自律走行車または自動駐車車です。スマート コントラクトは、衝突事故の責任が誰にあるか (センサーかドライバーか)、および無数の他の変数を検出できる一種の「オラクル」を機能させることができます。自動車保険会社は、スマート コントラクトを使用して、顧客が車両を運転している場所と状況に基づいて異なる料金を請求できます。",
      "不動産",
      "アパートを誰かに貸したい場合、Craigslist や新聞などの仲介業者に広告料を支払い、その人が家賃を支払い、契約を履行したことを確認するためにも誰かに支払う必要があります。",
      "分散型ソリューションはコスト削減に役立ちます。暗号通貨で支払い、スマート コントラクトに契約をエンコードするだけです。誰もがそれを確認し、自動履行を実現します。ブローカー、不動産業者、ハード マネー レンダー、不動産ゲームに関係するすべての人が利益を得ることができます。",
      "スマート コントラクトは、現在の不動産慣行を変革する点で革命的です。",
      "スマート コントラクトは、売り手と買い手の間の唯一の合意として従来の契約に取って代わりつつあります。契約の特定の条件が満たされるとすぐに、要件が自動的に実行されます。",
      "スマート コントラクトは、信頼を確立することで、真実の単一バージョンを通じて信頼を保証します。銀行、エージェント、住宅ローン貸し手を含むすべての当事者は、スマート コントラクトを介して契約に署名できます。取引はブロックチェーン上に保持されるため、この共有台帳により、関係者はいつでもどこからでもプロセスを確認できます。",
      "ヘルスケア",
      "個人の健康記録は、特定の個人にのみアクセスを許可する秘密鍵を使用してブロックチェーンにエンコードして保存できます。同じ戦略を使用して、研究が HIPAA 法に基づいて (安全かつ機密に) 実施されるようにすることができます。",
      "手術の領収書はブロックチェーンに保存され、配達証明として保険会社に自動的に送信されます。台帳も、薬の監督、規制の遵守、検査結果、医療用品の管理など、一般的なヘルスケア管理に使用できます。",
      "スマート コントラクトは素晴らしい!",
      "スマート コントラクトによって得られるものは次のとおりです。",
      "自律性",
      "契約を結ぶのはあなたです。ブローカー、弁護士、その他の仲介者に確認を頼る必要はありません。ちなみに、これにより、実行はネットワークによって自動的に管理されるため、第三者による操作の危険性も排除されます。1 人以上の、おそらく偏見のある個人が間違いを犯すことはありません。",
      "信頼",
      "ドキュメントは共有台帳で暗号化されます。誰かがそれを紛失したと言うことはできません。",
      "バックアップ",
      "銀行があなたの普通預金口座を紛失したと想像してみてください。ブロックチェーンでは、あなたの友人全員があなたの味方です。あなたの文書は何度も複製されます。",
      "安全性",
      "ウェブサイトの暗号化である暗号化により、文書は安全に保たれます。ハッキングはありません。実際、コードを解読して侵入するには、並外れて賢いハッカーが必要です。",
      "スマート コントラクトは、ブロックチェーン ネットワークのセキュリティを提供します。テクノロジー自体は、潜在的なハッキングを防ぐための適切な種類のセキュリティを提供できますが、スマート コントラクトを作成することも、同様にその安全性を確保することにつながります。",
      "節約",
      "スマート コントラクトは仲介者の存在を排除するため、お金を節約できます。たとえば、取引の証人となる公証人に料金を支払う必要があります。",
      "正確性",
      "自動化された契約は、より速く、より安価であるだけでなく、大量のフォームを手動で入力することから生じるエラーを回避することもできます。",
      "スマート コントラクトは素晴らしい!",
      "スマート コントラクトの将来の一部は、先ほど説明した問題を複雑に絡み合わせることにあります。",
      "たとえば、コーネル テックでは、スマート コントラクトが日常生活に浸透すると主張する弁護士が、これらの懸念事項の研究に専念しています。",
      "特に NFT マーケットプレイスが急増しているため、スマート コントラクトの問題のある側面を解決するために、これまで以上に多くのことがかかっています。",
      "スマート コントラクトは、特に法律などの特定の業界に変化をもたらす可能性があります。これは、弁護士が従来の契約書の作成から、LegalZoom で見つかる標準化された従来の契約書に似た標準化されたスマート コントラクト テンプレートの作成に移行するためです。",
      "マーチャント アクワイアラー、クレジット会社、会計士も、リアルタイム監査やリスク評価などのタスクにスマート コントラクトを採用する可能性があります。",
      "世界中で DeFi アプリがますます導入され、NFT 市場の価値が倍増するにつれ、スマート コントラクトが紙とデジタル コンテンツのハイブリッドに融合し、契約がブロックチェーン経由で検証され、物理的なコピーによって実証されていることは明らかです。",
      "スマート コントラクトは今後も改善を続け、テクノロジーが進化するにつれて、信頼性と効率性がますます高まります。",
      "Blockgeeks では、スマート コントラクトを始めるために必要なすべての重要なトピックを網羅した、イーサリアム開発を含むブロックチェーン テクノロジーの詳細なコースを提供しています。",
    ],
    9: [
      "NFTはデジタルアート、スポーツグッズ、メディアなどを変えています。",
      "このエコシステムはデジタル商品の流通方法に革命を起こしています。",
      "作成、配布、収集",
      "NFT の世界は、次のものによって急速に成長しています:",
      "ブロックチェーン",
      "NFT がホストされ、鋳造される場所。",
      "マーケットプレイス",
      "NFT がリストされ、取引される場所。",
      "NFT プロジェクト",
      "ジェネレーティブ アートから仮想不動産など、NFT はそれ自体で進化しています。",
      "しかし、このエコシステムについて検討する前に、NFT を定義しましょう。",
      "Qut の代替可能性を理解する",
      "NFT とは何ですか?",
      "NFT は、所有権の履歴と現在の所有者がブロックチェーンに保存されている、暗号化されたトークンです。",
      "NFT により、デジタル商品の堅牢なプライマリ マーケットとセカンダリ マーケットが実現し、仲介業者が排除され、クリエイターが作品を最大限に活用できるようになります。",
      "代替可能 VS.",
      "非代替可能",
      "代替可能性とは、商品の個々の単位が交換可能で、価値が区別できないことです。",
      "米ドルなどの通貨は代替可能です。すべての米ドルは交換可能で、他のドルと同じ価値があります。",
      "非代替トークン (NFT) または商品には独自の特性があり、他の同様のトークンや商品とは異なる価値を持ちます。",
      "テラスのある家は価値が異なり、テラスのない家とは交換できません。",
      "テラスは、家を非代替可能にし、異なる価値を持つ多くの異なる特性の 1 つです。",
      "同様に、非代替トークンには、他のトークンとは異なる芸術的特性やその他の特性があり、独自の価値を与えます。",
      "Cryptopunks は、10,000 ピクセル アートの「パンク」の NFT コレクションであり、2 つのパンクは同じではありません。",
      "さまざまな希少性のさまざまな属性により、特定のパンクはより希少で、より高く評価されます。",
      "属性の希少性が評価を左右する傾向がありますが、投資家やコレクターの美的および主観的な好みも評価に影響を与える可能性があります。",
      "「出っ歯」属性のパンクは 78 個、「パーカー」属性のパンクは 259 個あり、「パーカー」属性のパンクは「出っ歯」パンクの 3 倍の価値があります。",
      "出典: Larya Labs",
      "ドルの価値は Eth 価格 4,430 ドルで計算",
      "2021 年 11 月 29 日時点のパンクの価値",
      "Mighty Minters",
      "NFTS をホストするブロックチェーン プラットフォーム",
      "時価総額",
      "これらのブロックチェーンのほとんどは、プルーフ オブ ステーク コンセンサスを使用しており、プルーフ オブ ワークと比較して消費エネルギーが大幅に少なくなります。",
      "イーサリアムは来年中にプルーフ オブ ステークに移行する予定です。",
      "Tezos には専用の NFT コミュニティがあり、イーサリアム以外の最初の NFT プラットフォームの 1 つでした",
      "出典: Messari.io",
      "2021 年 11 月 29 日時点",
      "Ethereum トークン標準の調査",
      "Ethereum は NFT の最大のプラットフォームであり、NFT はネットワークの転送とアクティビティの大部分を占めています。",
      "ERC 721 トークンに加えて:",
      "ERC-20 契約標準は代替可能なトークンを作成するために使用され、Ethereum ネットワーク全体で最も一般的に使用されています。",
      "ERC-1155 トークン標準は Enjin によって作成され、代替可能な資産と代替不可能な資産の両方を作成できます。",
      "ERC-998 は ERC-721 標準の拡張であり、NFT が NFT と ERC-20 トークンを所有できるようにします。",
      "Ethereum の NFT トークン標準は、同じく Ethereum ブロックチェーン上に構築された dapps (分散型アプリケーション) と対話できるため、シームレスな統合が可能です。",
      "Ethereum Name Services NFT は、Ethereum ウォレットの名前を、選択した「.eth」アドレスに変更して、一意で覚えやすいアドレス名にします。",
      "これらの NFT は、Web ドメイン名と同様に、年間期間で購入されます。",
      "二次マーケットプレイスのオープン シー",
      "現在、NFT を売買するためのさまざまな二次マーケットプレイスがあります。",
      "OpenSea は、2021 年 11 月の取引量が 19 億ドルで、主要なプラットフォームとして浮上しました。",
      "NFT の現在の位置",
      "デジタルアート、収集品、ゲームなど",
      "NFT は単なるデジタル所有権証明以上のもので、物理的な資産や特典と結びつくことができます。",
      "キングス・オブ・レオンの最新アルバム「When You See Yourself」は、物理的な資産や特典に結びついたさまざまな期間限定の NFT とともにリリースされました。",
      "50 オーストラリアドル (35.70 ポンド) の NFT で、所有者は音楽のデジタルダウンロード、アルバムの物理的なビニール、デジタル商品にアクセスできます。",
      "6 つの「ゴールデンチケット NFT」で、所有者はバンドのどのショーでも最前列の席 4 つに生涯アクセスできます。",
      "英国のアーティスト、ダミアン・ハーストの NFT コレクション「The Currency」は、人々が NFT と物理的なアートをどのように評価するかを探ります。",
      "通貨は 10,000 個のユニークな NFT のセットで、それぞれが 2016 年に作成された対応する物理的な作品を持っています",
      "物理的な作品は英国の金庫に保管され、NFT の購入者は 1 年間 (2022 年 7 月 27 日まで) 以内に NFT を保持するか物理的なバージョンを保持するかを決定する必要があります。決定後、もう一方のバージョンは破棄されます。",
      "現在、NFT の分野には 3 つの主要な分野があり、それぞれが独自の方法でイノベーションを推進しています。",
      "プロフィール写真のコレクションからジェネレーティブ アートや共同音楽作品まで、NFT はデジタル アートの繁栄を可能にしています。音楽であれスポーツ コレクションであれ、視覚芸術の NFT だけではありません。",
      "NFT ゲームの世界は革新に満ちており、ゲーム内アセットや購入のプレイヤー所有権、プレイ・トゥ・アーン モデルがさまざまなプロジェクトによって実装および検討されています。",
      "NFT はデジタル所有権、アイデンティティ、および空間を再定義し、仮想現実のデジタルランドとその所有者を表す 3D アバターをトークン化しています。",
      "デジタルを超えて。",
      "NFT はビッグバンの瞬間を迎えましたが、ブームはまだまだ終わりません。",
      "これらの分野とともに、NFT は家や物理的な芸術作品などの現実世界の資産をトークン化し、デジタル転送を可能にする方法として、依然として検討されています。",
      "ほぼすべての物理的な資産または商品をトークン化して所有権の証明と交換を容易にすると同時に、分割、担保化、スマート コントラクトが提供できるユーティリティなどの可能性も開きます。",
      "NFT エコシステムのマッピング",
      "NFT は 2021 年の最もホットな話題であり、最も活気のある市場であり、販売量は 100 倍に増加し、夕方のトーク番組でも話題になっています。",
      "暗号通貨が本格的に主流に浸透するまでには 10 年近くかかりましたが、NFT が人々の注目を集めるのには数年しかかかりませんでした。バドワイザー、ビザ、アディダスなどのブランドが NFT を購入してこの分野に参入していることから、NFT が単なる新しいホットなトレンド以上のものであることは明らかです。",
      "Next Decentrum がスポンサーとなっているこのインフォグラフィックでは、NFT を定義し、NFT を中心に急速に成長した活気あるエコシステムについて探っています。非代替性とは何か、NFT がどこで鋳造され取引されているか、そしてこの資産クラスの将来がどうなるかをご覧ください。",
      "NFT とは何ですか。代替性とは何ですか。",
      "NFT は、所有権の履歴と現在の所有権がブロックチェーン上で暗号化されて保護されている非代替性トークンです。これらのトークンは、JPEG 形式のデジタル アート作品でも、MP3 ファイル形式の曲でも、何でも表現できます。",
      "これらのトークンのトランザクションをブロックチェーンに保存することで、二重支払いや過去のトランザクションや所有権の改ざんを恐れることなく、所有権のデジタル証明とこれらのデジタル商品の市場を確保できます。",
      "代替可能性の把握",
      "これらはすべて暗号通貨によく似ていますが、NFT が特別なのはなぜでしょうか。それは、代替不可能な点です。ビットコインやイーサリアムなどの暗号通貨とは異なり、代替不可能なトークンは、固有の特性と属性を持つ商品や資産を表し、同じコレクションの一部であっても固有の価値を持つことができます。",
      "代替可能: 価値が区別できない、交換可能な単位を持つ商品。例: 米ドル、ビットコイン、アーケード トークン",
      "代替不可能: 固有の特性を持つ商品で、類似の商品と比較すると固有の価値があります。例: 不動産、絵画、NFT。",
      "最も人気のある NFT コレクションである Cryptopunks は、10,000 個のピクセル アート「パンク」のコレクションで、さまざまな帽子、メガネ、ヘアスタイルなどのさまざまな属性があります。希少性の異なる属性のランダムな組み合わせにより、各パンクに固有の価値が生まれます。",
      "希少性と主観的な美的嗜好が、クリプトパンクやその他の NFT の評価を左右し、歴史的重要性などの他の要因や、それらがホストされているブロックチェーンさえもその価値に影響を与えます。",
      "NFT 対応ブロックチェーンの比較",
      "NFT を鋳造およびホストできるブロックチェーンは数多くありますが、現在、時価総額と取引量で最大かつ最も使用されているのは Ethereum です。",
      "デジタルアート、ゲーム、メタバース、そして NFT の未来",
      "NFT は 2021 年に大ヒットし、クリエイターに作品をホストおよび交換できるデジタルおよび分散型ネットワークを提供しました。",
      "現在、デジタルファーストのユースケースが NFT 開発の最前線にあり、ゲーム内アセットまたはメタバース内の商品の所有権が、検討されている主要なユースケースの 2 つです。ただし、NFT は不動産、物理的なアートワークなどの物理的なアセットをトークン化するために使用でき、その用途にはほぼ無限の可能性が開かれています。",
      "今日の不動産取引における書類手続きや官僚主義の摩擦を取り除くことから、資産所有権の容易な分割を可能にすることまで、NFT の具体的な現実世界の使用例は、まだ検討が始まったばかりです。",
    ],
    10: [
      "Web3 を嫌うか好きかは関係なく、ベンチャーキャピタリストのマーク・アンドリーセン、元 Twitter CEO のジャック・ドーシー、テスラの最高経営責任者のイーロン・マスクなど、テクノロジー界の有力者が最近 Web3 について議論しているため、Web3 は今話題になっています。[1] Web3 支持者は、Web3 が経済情勢を一変させるとも考えています。ガートナーは、Web3 によって 2030 年までに世界の GDP が 1.76 兆ドル増加すると予測しています。[2] Web3 の台頭により、仮想ガバナンス、データプライバシー、知的財産保護、規制遵守、侵害など、多くの法的問題が必然的に生じます。これらは、テクノロジー弁護士や法律事務所にとって、この急速に進化する法律分野に参入するビジネスチャンスも豊富に提供します。",
      "Web3 とは?",
      "Web3 は、開発者とユーザーが所有する分散型ブロックチェーン技術に基づいて構築された新しいプラットフォームで、暗号通貨、NFT、メタバースを新しいプラットフォームに結び付けることができます。Web3 では、個人が各ユーザーに直接サービスを提供したり、システム内に本来存在する価値のあるアイテムを取引したりできます。 Web3 アプリケーション/ネットワーク/プラットフォームの例としては、銀行を使用する代わりに分散型ブロックチェーン上で動作するメタバース (複数の仮想空間を組み合わせたオンラインの 3 次元宇宙で、ユーザーはそこでコラボレーション、ミーティング、ゲーム、交流を行うことができます) が挙げられます。",
      "Web3 には法的保護が必要ですか?",
      "分散型プラットフォームは規制されていないという誤解があります。さらに、デジタル資産は、現地の規制当局が取引を規制する法律や規制を導入している世界の多くの地域で急速に成長しています。たとえば、米国財務省の金融犯罪取締ネットワーク (FinCEN) は、暗号通貨取引所を追跡し、疑わしい活動を報告するための規則を策定しています。さらに、オーストラリアとカナダは、ビットコインを課税対象となる価値のある金融資産と見なしています。アジアでは、日本の国会が 6 月に、投資家保護のためにステーブルコインまたは暗号通貨を規制する法案を可決しました。",
      "メタバースは世界中で劇的に成長しているため、法的懸念は増大します。一般的に、多くのユーザーは、NFT が所有権の証明を提供すると考えています。しかし、物事は私たちが考えていたほど単純ではないかもしれません。たとえば、インディアナ大学の法学教授であるジョアン・マリノッティ氏によると、仮想土地の所有権は財産法ではなく契約法に該当するため、財産の所有権が問題となります。また、NFT を購入しても、ユーザーにデジタル資産の所有権が与えられるわけではありません。\n",
      "Web3 はまったく新しい複雑な環境ですが、問題が発生した場合には新しい種類の法的保護が必要です。暗号通貨訴訟は急増しています。ライセンスや資産の共同所有に関連する NFT の紛争が発生します。今日の企業は、メタバースをビジネスおよびマーケティング戦略の一部として使用しており、ガバナンス、知的財産保護、侵害に関する多くの法的問題を引き起こしています。\n",
      "Web3 の機会",
      "テクノロジー環境が最大限に成長したい場合は、すべての関係者の利益を保護するために定められた権利と義務を含む適切な法的枠組みを組み込む必要があります。私たちが対処する必要がある法的課題には、課税、仮想ガバナンス、規制上の課題、ライセンスと所有権、取引の追跡と報告、データプライバシー、知的財産保護などがあります。\n",
      "取り残されないようにするには、従来の法律事務所、弁護士、法学生は、Web3 テクノロジの開発と、Web3 および地方政府に関連する法律と規制の変化を理解する必要があります。",
    ],
    11: [
      "暗号通貨デビットカードの仕組み",
      "暗号通貨デビットカードは、Coin などの暗号通貨金融サービスを専門とする企業によって使用されています。これらの企業は Visa や Mastercard などの決済ネットワークと契約を結んでおり、ユーザーは従来の決済システムが受け入れられる場所で暗号通貨を使用できます。\n",
      "このプロセスにより、取引の安全性と透明性が確保されます。さらに、変換が完了するまで何時間も何日も待つことなく、高速な変換レートが提供されます。\n",
      "適切な暗号通貨デビットカードを選択する",
      "この記事のこの部分では、シームレスな日常の取引のために暗号通貨デビットカードを取得する方法を説明します。暗号通貨を法定通貨に変換し、有効なデビットカードが受け入れられる場所ならどこでも買い物をします。また、市場にあるいくつかのオプションから最高の暗号通貨デビットカードを取得する際に注意する必要がある要素についても説明します。",
      "•サポートされている暗号通貨: 最高の暗号通貨デビットカードを手に入れるには、カードが暗号通貨をサポートしていることを確認する必要があります。",
      "• 手数料: 暗号通貨デビットカードは、多くの場合、ロード手数料、取引手数料、ATM引き出し手数料など、さまざまな手数料を請求します。カードを選択する前に、さまざまなカードで請求される手数料を比較してください。\n",
      "• 特典: 支出を最大限に活用するには、キャッシュバック特典プログラムを備えた暗号通貨デビットカードを選択してください。最高のキャッシュバックを備えた最高の暗号通貨デビットカードを検索、比較して選択します。\n",
      "• セキュリティ: 暗号通貨デビットカードは、暗号通貨を保護するためにさまざまなセキュリティ機能を使用します。強力なセキュリティ機能を提供する最高の暗号通貨デビットカードを選択してください。",
      "• カスタマーサポート: デビットカードを備えた最高の暗号通貨ウォレットを手に入れるために考慮する必要がある主なポイントの1つは、サポートの品質です。問題に直面したときにサポートが役に立たない場合、お金と時間を失うことになるので、必ず有効なサポートを選択してください。\n",
      "これらは、デジタル資産を現実世界の取引で使用できるようにする革新的なソリューションです。ブロックチェーン技術を利用することで、これらのカードは、主要なデビットカードが受け入れられる場所ならどこでも使用できる、安全で透明な取引を促進できます。\n",
      "米国最大の暗号通貨取引所の1つは金曜日、シームレスなデジタル資産の移転と採用について、グローバルな支払いプロセスと「緊密に」協力していると述べました。",
      "暗号通貨に対する規制優先のアプローチで知られるサンフランシスコを拠点とする暗号通貨取引所Coinbaseは本日、「Visaのようなイノベーターと協力できることを誇りに思う」と述べ、デジタル通貨の採用を強化しました。\n",
      "取引所は、Visaのような企業が暗号通貨と従来の金融のギャップを埋めるのに役立っていると述べました。これにより、暗号通貨をグローバルネットワークに接続するための環境が整い、従来の分野と暗号通貨の分野のリーダーが集まります。\n",
      "Visaがデジタル通貨のアプローチを発表",
      "決済大手の Visa は、時価総額が 4,200 億ドルで、ネットワーク上で 1 秒あたり 25,000 件を超える取引を処理しており、今週初めのブログ投稿で暗号通貨に関する計画を発表しました。\n",
      "「デジタル通貨へのアプローチの推進」と題された同社は、より多くの市場に参入し、世界中で金融包摂性を向上させるための新しいデジタルファースト戦略の中心にデジタル資産を置きました。\n",
      "Visa は、Coinbase などの認可および規制を受けたデジタル通貨プラットフォームと積極的に協力し、デジタル通貨と 6,100 万の既存のグローバル ネットワークの商店との橋渡しを行っていると述べています。\n",
      "同社は、現在 25 を超えるデジタル通貨ウォレットが Visa ネットワークにリンクされており、「ユーザーは Visa デビット カードまたはプリペイド クレデンシャルを使用して、デジタル通貨残高から簡単に支払いを行うことができます」と指摘しています。\n",
      "その他の開発には、Visa Direct などのイノベーションが含まれます。これは、消費者のデジタル通貨を迅速に変換し、それらを Visa クレデンシャルに追加する、同社の法定通貨から暗号通貨への即時ブリッジです。 \n",
      "Visa は、これらの取り組みにより、このブランドはデジタル通貨ウォレットに採用されるのに最適であると述べている。後者は「世界中でデジタル通貨をより迅速かつ簡単に使用できるようにすることで、ユーザーにとっての価値を高めることに熱心です。」\n",
      "ステーブルコインは将来の経済の大きな部分を占める",
      "Visa は、公共部門での暗号通貨の展開に関するより広範な戦略の中で、企業にデジタル通貨の利点を知らせるために政策立案者や規制機関と連携していると述べました。\n",
      "暗号通貨デビットカードで取引する",
      "デビットカードで暗号通貨ウォレットを使用すると、暗号通貨を変換してプロセスが完了するのを待つことなく簡単に使用できます。プロバイダーは暗号通貨を自動的に法定通貨に変換して転送します。",
      "暗号通貨デビットカードを日常の取引に使用する",
      "従来のデビットカードと同じように機能し、Visa または Mastercard が受け入れられる場所ならどこでも使用できます。日常の取引を容易にし、キャッシュバックや暗号通貨ポイント報酬プログラムなど、従来のカードよりも優れた利点を提供します。\n",
      "暗号通貨デビットカードのセキュリティ機能",
      "暗号通貨デビットカードは、暗号化、2要素認証、詐欺防止、FDIC保険を使用し、従来のカードと同じセキュリティ規制に従って暗号化と個人情報を保護します。\n",
      "暗号通貨デビットカードを使用する利点",
      "暗号通貨デビットカードを取得すると、利便性、報酬、分散化による資産の完全な管理など、多くの利点が得られます。",
    ],
    12: [
      "USD コイン (USDC) 貯蓄口座: 暗号通貨で利息を稼ぐ",
      "Web3 には、暗号通貨貯蓄口座の選択肢が豊富にあり、その数は増え続けています。これらの低リスク、高利回りの貯蓄口座は、ユーザーが暗号通貨で毎月最大 200% の利息 + 複利を得るのに役立ちます。USD コイン (USDC) は、流通している最も使用されているステーブルコインの 1 つです。これに関する情報と、YouHodler で暗号通貨の利息を簡単に貯める方法に関する追加情報をご紹介します。",
      "USD コイン (USDC) とは何ですか?; 暗号通貨を稼ぐ方法",
      "他の多くの暗号通貨とは異なり、USDC は「ステーブルコイン」と呼ばれるユニークなタイプの暗号通貨です。USDC は、1:1 の比率で米ドルに裏付けられています。したがって、1 USD コインを常に 1.00 米ドルに引き換えることができます。これにより、ビットコインや他の暗号通貨よりもはるかに安定した資産となり、資産を安全に保管できます。",
      "USDC は Ethereum ベースのトークンであり、たとえば YouHodler の USDC ウォレットのような Ethereum 互換ウォレットに保存できます。USDC の背後にあるチームは、ユーザーが暗号通貨ウォレットから企業、取引所、その他の人々にドルを世界中に簡単に移動できるように、このステーブルコインを設計しました。YouHodler は、プラットフォームで暗号通貨の利息を稼ぐもう 1 つの優れたオプションとして、これをラインナップに追加しました。",
      "USDC 貯蓄口座: web3 で暗号通貨の利息を稼ぐ理由",
      "Web3 は、デジタル資産から利益を得るためのさまざまな方法を提供しており、暗号通貨貯蓄口座を使用すると、ユーザーは高利回りでリスクの低い投資ツールを利用できます。Web3 で暗号通貨の利息を稼ぐ理由をいくつか紹介します。",
      "暗号通貨で最大 2.6% と 10% の USDC 利息を獲得: Web3 で年間 2.6% と 10% の USDC 利息を獲得できます。これは、従来の銀行の「高利回り」貯蓄口座やその他の投資よりもはるかに高い利回りです。Web3 は、市場で最高の USDC 為替レートを顧客に提供しています。",
      "資産の安全確保: Web3 のすべての暗号通貨資金は、保管に関して業界のベスト プラクティスを使用して保護されています。資金は常に 100% ホット ウォレットに保管されます。当社は、ユーザーの資金が最大限に保護されるように、ホット ウォレットとコールド ウォレットのハイブリッド ストレージ メソッドを使用しています。",
      "暗号通貨の利息は 4 時間ごとに手数料なしで支払われます。4 時間の決済期間の終了時に、獲得したすべての暗号通貨の利息がユーザーの普通預金口座の預金に入金されます。ユーザーは、普通預金口座からウォレットに解放して引き出すか、口座に資金を保持して次の請求サイクル中にさらに多くの暗号通貨の利息を獲得するかを選択できます。(口座からの利息の引き出し回数は 1 日 3 回で、各引き出しは 1 USDC を超える必要があることに注意してください)",
      "Web3 で暗号通貨の利息を獲得する他の方法を調べる",
      "USDC コイン利息は、Web3 を通じて暗号通貨の利息を獲得するための 1 つのオプションにすぎません。利息獲得ページにアクセスして、さまざまなステーブルコインと暗号通貨ステーブルコインから選択してください。ビットコイン、バイナンスコインなどで利息を獲得できます。普通預金口座で暗号通貨を獲得し、より効率的な HODL の方法を見つけましょう。\n",
      "利息が支払われる USDC 貯蓄ウォレット アプリ",
      "Wwb3 ウォレット アプリは、通常のウォレットと同じように USD コイン (USDC) を保管できる唯一のアプリです。さらに、USDC ウォレット残高に対して 4 時間ごとに利息を獲得できます。\n",
      "USDC 利息の仕組み",
      "従来の銀行普通預金口座と同じように USDC で利息を獲得できますが、重要な違いが 1 つあります。銀行のほぼゼロの利息ではなく、ETH に対して最大 10% の利息を提供します。\n",
      "無料で USDC を獲得する方法",
      "簡単です！Web3ウォレットにUSDCを入金するだけで、4時間ごとに無料のETHを獲得できます。",
      "なぜETHで利息が得られるのですか？",
      "ウォレットにUSDCを保管した後、4時間ごとに得られる利息は暗号通貨ETHです。ただし、ブロックチェーンで取得したETHをウォレットに直接引き出すことはできません。ブロックチェーンWeb3にログインする必要があります。アカウントで獲得したETHは、ウォレットに引き出す前にUSDCに変換する必要があります。以下は、Web3アカウントで獲得したETHをUSDCに変換する詳細な説明です。",
      "利息を引き出す前に、まずWeb3アカウントにアクセスし、「交換」をクリックし、「すべて変換」をクリックしてすべてを変換する必要があります。",
      "次のステップは、Web3アカウントからウォレットに交換したUSDCを引き出すことです。以下はUSDCの引き出しの詳細な説明です。",
      "次に、「引き出し」をクリックし、「合計残高」をクリックし、最後に「確認」をクリックする必要があります。利益の引き出しが完了しました。 USDC の通常の引き出しは 10 分以内に到着します。ネットワークが混雑している場合は、到着が遅れる場合があります。",
      "USDC と ETH の累積利息",
      "はい、Web3 アカウントで USDC と ETH を保有している場合は、累積利息が発生します。4 時間ごとに複利を計算します。",
      "USDC の日次利回りと利回り",
      "他のプラットフォームと比較して、当社は APR ではなく日次収入を提供しています。日次利率は複利の影響なしに利息を支払います。したがって、Web3 日次では、累積利息により月末により多くの USDC を受け取ることができます。\n",
      "USDC 貯蓄計算機",
      "USDC 貯蓄計算機を使用するだけで、Web3 で 3 か月、6 か月、または 12 か月保有した場合にいくら稼げるかを確認できます。",
    ],
    13: [
      "今回発行された3,000,000 ETHは、マイナーとブロックチェーンの間で締結されたDeFiローン契約から取得されます。DeFiローン契約によると、マイナーとブロックチェーンの間で締結されたDeFiローン契約が3,000,000 ETHに達すると、ブロックチェーンは3,000,000 ETHをブロックチェーンプロジェクトに流し、ブロックチェーンのデータを保持し、ETH自体の価値を示す権利を持ちます。そのため、ブロックチェーンはユーザーが保管したUSDCの元本額に応じて金利を支払い、USDCを保管したユーザーはETHで金利を受け取ります。\n",
      "1 ETHの金利はどこから来るのですか？",
      "マイナーは、DeFi プロトコル貸付を通じてブロックチェーンから採掘した ETH を借り入れます。現在、ブロックチェーンはマイナーの DeFi プロトコル貸付からすでに 300 万 ETH を受け取っています。そのため、ブロックチェーンは主要な Web3 ウォレットと連携して USDC を利益のために保管する DeFi プロジェクトを開始しました。この 300 万 ETH が利息として送金されるまで、次のブロックチェーン保管利益プロジェクトに参加できます。過去の経験によると、この 300 万 ETH は 3 ～ 5 か月で配布されます。",
      "たとえば、マイナーのブロックチェーンとの DeFi ローン契約が 300 万 ETH に達した場合、ブロックチェーンはマイナーが担保にした ETH を主要な Web3 ウォレットと組み合わせて流動化し、ETH 自体の価値を反映する権利を持ちます。",
      "さらに、現在のブロックチェーン VIP ストレージ ユーザーになると、ブロックチェーンによって発見された次のノードに入り、利益のために保管を続けるようブロックチェーンから招待される機会があることをお知らせする必要があります。\n",
      "DeFi ETH レンディング",
      "通常は高く、APY から自動的に差し引かれます",
      "プラットフォームによって決定されます — 固定",
      "内部の仕組みをまったく把握していない、中央集権的で不透明なプラットフォーム",
      "低 — 数回のクリックで実行できます",
      "ハッキング、プラットフォームの破産、倒産",
      "手数料は通常低く、APY と一緒に明示的に表示されます",
      "市場によって決定されます — 常に変動します",
      "コードがオープンソースであることが多い、分散型で透明なプラットフォーム",
      "高 — プロセスを完了するには、DeFi に関するある程度の知識が必要です",
      "ハッキング",
      "上記の 3 つのプラットフォームは、集中型金融 (CeFi) に参加する集中型プラットフォームです。もう 1 つのオプションは、貸し手と借り手のための暗号通貨市場として機能する分散型金融 (DeFi) ETH 貸出プロトコルです。\n",
      "DeFi プラットフォームは、コードを使用してリアルタイムの貸出および借入レートを決定する自動化された市場を維持します。その結果、DeFi の ETH 貸出レートは、需要と供給に基づいて変動します。",
      "DeFi の利点は、プラットフォーム料金がはるかに低いことです。また、DeFi プロトコルは通常、オープンソース コードを使用し、トークン所有者のコミュニティによって管理されるため、DeFi プロトコルは CeFi 貸し手よりもはるかに透明性があります。",
      "DeFi 貸出は収益性があります。ただし、より複雑な場合が多いため、通常は経験豊富なトレーダーに適しています。",
      "Q: マイナーはどこで ETH を入手しますか?",
      "回答: マイナーは流動性マイニングを通じて ETH を入手しました。",
      "DeFi 流動性マイニングは、ユーザーが報酬と引き換えに分散型金融 (DeFi) プラットフォームに流動性を提供するプロセスです。この記事を詳しく見て、理解を深めましょう。",
      "DeFi 流動性マイニングとは?",
      "DeFi 流動性マイニングとは、ユーザーが分散型金融 (DeFi) プラットフォームに流動性を提供して報酬を得るプロセスです。流動性プロバイダー (LP) は、プール内で行われる取引で手数料を獲得できるほか、DeFi プラットフォーム自体からトークンを獲得できます。\n",
      "DeFi 流動性マイニングはどのように機能しますか?",
      "DeFi 流動性マイニングは、自動マーケット メイキング (AMM) と呼ばれるプロセスを使用して機能します。AMM は、さまざまなトークンの流動性プールを作成するスマート コントラクトです。LP は流動性プールにトークンを追加し、プール内で行われる取引で手数料を獲得できます。\n",
      "手数料は、プールで取引されているトークンで支払われます。たとえば、ETH と USDC を流動性プールに追加すると、ETH と USDC で手数料を獲得できます。また、DeFi プラットフォーム自体からもトークンを獲得できます。これらのトークンはガバナンス トークンと呼ばれることが多く、プラットフォームでの投票権が付与されます。\n",
      "DeFi 流動性マイニングのメリットは何ですか?",
      "DeFi 流動性マイニングに参加すると、いくつかのメリットがあります。まず、DeFi プラットフォームに流動性を提供することで、受動的収入を得ることができます。次に、DeFi プラットフォーム自体からトークンを獲得でき、その価値が上がる可能性があります。3 番目に、DeFi プラットフォームの流動性を高めることに貢献でき、プラットフォームのすべてのユーザーに利益をもたらすことができます。\n",
      "DeFi 流動性マイニングは安全ですか?",
      "DeFi 流動性マイニングにはリスクがないわけではありませんが、リスクを負う意思があれば、受動的収入を得るための有益な方法になる可能性があります。DeFi 流動性マイニング プロジェクトに参加する前に調査を行い、失っても大丈夫な金額のみを投資することが重要です。\n",
      "DeFi 流動性マイニングとは何ですか? どのように機能しますか? - この記事が参考になれば幸いです。",
    ],
  },
  check: {
    title: "デイリーサインイン",
    rules: "ルール",
    point: "ポイント蓄積",
    signin: "サインイン",
    days: "${consecutiveDays}日間連続でサインインしています",
    reSigning: "再サインイン",
    day10: "10日間",
    day90: "90日間",
    rewards: "報酬",
    day10Rewards: "10日間連続サインイン報酬",
    getIt: "獲得する",
    exchange: "交換",
    exchangeSub: "ポイント交換",
    points: "ポイント",
    share: "共有タスク",
    shareSub: "共有タスク報酬",
    rank: "ポイントランキングリスト",
  },
};
