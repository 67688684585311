export default {
    "zt": {
      "st_0": "En revisión",
      "st_1": "En progreso",
      "st_2": "Redimido",
      "st_3": "Revisión no aprobada",
      "st_4":"Caducado"
    },
    "langName": "español",
    "common": {
      "error": "¡Error del sistema, por favor intente nuevamente!",
      "home": "Inicio",
      "detail": "Detalles",
      "market": "Mercado",
      "trade": "Intercambio",
      "options": "Opciones",
      "wallets": "Carteras",
      "notMore": "No hay más registros",
      "reconnect": "Reconectando",
      "noData": "Actualmente no hay contenido",
      "loading": "Cargando...",
      "liveChat": "Chat en vivo",
      "help": "Ayuda",
      "lang": "Idioma",
      "notify": [
        "¡Por favor, abre este enlace en tu billetera!",
        "Envío exitoso",
        "¡Ya has reclamado este certificado!",
        "No se puede autorizar"
      ]
    },
    "header": {
      "voucher": "Obtener certificado",
      "connectWallet": "Iniciar sesión",
      "receive": "Instrucciones de recepción",
      "receiveInfo": "Necesitarás pagar tarifas de minería para obtener el certificado. Asegúrate de que tu billetera tenga suficiente ETH para cubrir las tarifas.",
      "receiveBtn": "Obtener",
      "reward": "Has ganado una recompensa"
    },
    "share": {
      "shareFriend": "Compartir con amigos",
      "info": "Envía tu enlace de invitación, los amigos que se unan a través de tu enlace recibirán grandes recompensas en tokens.",
      "myShareLink": "Mi enlace de invitación",
      "copy": "Copiar"
    },
    "ad": {
      "plan": "Ahorro planificado:",
      "regular": "Ahorro regular:"
    },
    "output": {
      "interest": "Interés",
      "desc": "Ingresos flexibles por interés",
      "output": "Producción",
      "address": "Dirección",
      "quantity": "Cantidad"
    },
    "order": {
      "order": "Pedido",
      "amount": "Monto",
      "available": "Disponible",
      "all": "Todo",
      "confirm": "Confirmar",
      "period": "Período",
      "requird": "Requisito de monto",
      "rate": "Tasa de cambio (ETH)",
      "additional": "Recompensa adicional",
      "less": "No menos de 1 USDC",
      "participants": "Participantes",
      "total": "Monto total (USDC)"
    },
    "chart": {
      "loading": "Cargando",
      "min1": "1 minuto",
      "min15": "15 minutos",
      "min5": "5 minutos",
      "min30": "30 minutos",
      "hour1": "1 hora",
      "hour4": "4 horas",
      "day1": "1 día",
      "week1": "1 semana",
      "mon1": "1 mes",
      "more": "Más",
      "price": "Precio",
      "open": "Abrir",
      "amount": "Cantidad",
      "high": "Máximo",
      "low": "Mínimo",
      "buy": "Comprar",
      "sell": "Vender",
      "buyLong": "Comprar/Ir largo",
      "sellShort": "Vender/Ir corto",
      "main": "Principal",
      "side": "Secundario",
      "setting": "Configuración",
      "history": "Posiciones"
    },
    "futureOrder": {
      "title": "Confirmar pedido",
      "name": "Nombre",
      "direction": "Dirección",
      "price": "Precio",
      "info": "Tiempo de entrega (desliza a la izquierda para obtener más ganancias)",
      "amount": "Cantidad (USDC)",
      "available": "USDC disponible",
      "fee": "Tarifa",
      "second": "segundo",
      "buy": "Comprar",
      "sell": "Vender",
      "minAount": "Cantidad mínima: ${minAmount}-${maxAmount}",
      "startPrice": "Precio del pedido",
      "expectedProfits": "Beneficios esperados",
      "finish": "Liquidación completada",
      "currentPrice": "Precio actual",
      "completeResult": "Resultado final",
      "confirmOrder": "Confirmar",
      "continueBuy": "Seguir comprando"
    },
    "futureHistory": {
      "title": "Historial de pedidos",
      "direction": "Dirección",
      "amount": "Cantidad",
      "buyPrice": "Precio del pedido",
      "OrderPrice": "Precio final",
      "deliveryTime": "Tipo",
      "pl": "Ganancia/Pérdida",
      "sellTime": "Hora de finalización"
    },
    "market": {
      "currency24h": "Moneda 24h",
      "volume": "Volumen",
      "liquidity": "Liquidez"
    },
    "user": {
      "title": "Felicidades a los nuevos usuarios que se unieron al pool de minería.",
      "nation": "Nacionalidad del usuario",
      "address": "Dirección en la cadena pública",
      "Hashrate": "Hashrate de la billetera"
    },  
    "rule": {
    "daily": "Diario",
    "interest": "Interés",
    "rule": "Reglas",
    "flexible": "Flexible",
    "payments": "Los intereses se pagan cada 4 horas, 6 veces al día.",
    "safety": "Seguridad de los activos",
    "less": "Aproximadamente el 80% de los activos en USDC están en bonos del Tesoro de EE. UU. a corto plazo, con vencimientos de no más de 3 meses.",
    "economy": "Estos se consideran unos de los activos más seguros del mundo, respaldados por la 'plena confianza y crédito' del gobierno de EE. UU., el cual está respaldado por la economía más grande del mundo.",
    "fees": "Tarifas",
    "additional": "No hay tarifas adicionales para retirar los intereses."
  },
  "report": {
    "title": "Carteras Web3 compatibles con DApp",
    "subTitle": ["Móvil", "Hardware", "Contratos inteligentes", "Otros"]
  },
  "help": {
    "article": [
      {
        "title": "Tienes tus propios datos",
        "details": [
          "Con esta billetera criptográfica de software que usa la blockchain de Ethereum, solo tú puedes tener acceso a tus datos y almacenarlos localmente. Puedes acceder a tu billetera a través de una extensión de navegador o una aplicación móvil."
        ]
      },
      {
        "title": "¿Cuáles son los beneficios de Web3.0?",
        "details": [
          "Web 3.0 se basa en conceptos centrales de descentralización, apertura y mayor utilidad para los usuarios.",
          "En un sistema descentralizado, la información se puede recuperar según su contenido, lo que permite que se almacene en múltiples ubicaciones, descentralizándola. Esto descompone los grandes conjuntos de datos que actualmente están controlados por los gigantes de Internet, haciéndolos más escalables, seguros y accesibles."
        ]
      },
      {
        "title": "¿Qué es una aplicación descentralizada (dApp)?",
        "details": [
          "Una dApp es una aplicación que funciona en la blockchain o en nodos de redes P2P, en lugar de depender de una computadora centralizada.",
          "Algunos beneficios de las dApps son que son más eficientes financieramente, ya que pueden operar sin la intervención de intermediarios que extraen ganancias de las transacciones. Los usuarios deben asumir la responsabilidad legal y regulatoria de los contenidos que comparten o consumen."
        ]
      },
      {
        "title": "Contratos inteligentes",
        "details": [
          "Una dApp es una aplicación que funciona en la blockchain o en nodos de redes P2P, en lugar de depender de una computadora centralizada.",
          "Algunos beneficios de las dApps son que son más eficientes financieramente, ya que pueden operar sin la intervención de intermediarios que extraen ganancias de las transacciones. Los usuarios deben asumir la responsabilidad legal y regulatoria de los contenidos que comparten o consumen."
        ]
      }
    ],
    "center": "Centro de ayuda",
    "hope": "Esperamos que esto te sea útil"
  },
  "partner": {
    "title1": "Informe de auditoría",
    "detail1": "Contamos con un informe de auditoría de seguridad",
    "title2": "Socios",
    "detail2": "Nuestros socios comerciales"
  },
  "trading": {
    "titles": ["Volumen total de comercio", "Equidad no liquidada", "Número total de usuarios"],
    "currency": "Monedas populares",
    "banners": ["Ahorros de liquidez para ganar USDC", "Nuestras ventajas"]
  },
  "poolIndex": {
    "tab1": "Datos del fondo",
    "tab2": "Plan",
    "tab3": "Impuestos",
    "tab4": "Cuenta",
    "tab5": "Transferencias",
    "transfer": "Transferir USDC desde la billetera al saldo",
    "button": "Transferir"
  },
  "liquidity": {
    "type": "Tipo",
    "interest": "Interés",
    "period": "Período",
    "amountRequire": "Requisito de cantidad (USDC)",
    "rate": "Tasa de cambio (ETH)",
    "additionalReward": "Recompensa adicional",
    "days": "Días",
    "subscription": "Suscripción",
    "redeem": "Redimir",
    "record": "Registro",
    "participants": "Participantes",
    "totalAmount": "Monto total (USDC)",
    "interestRate": "Tasa de interés",
    "amount": "Cantidad (USDC)",
    "collectionAmount": "Cantidad recolectada",
    "reward": "Recompensa",
    "addReward": "Recompensa adicional",
    "endTime": "Hora de finalización",
    "status": "Estado",
    "stakingAmount": "Cantidad de staking",
    "unCollected": "Cantidad no recolectada"
  },
  "pool": {
    "total": "Datos totales de dividendos",
    "participant": "Participante",
    "userRevenue": "Ingresos del usuario",
    "poolData": "Datos del fondo"
  },
  "message": {
    "title": "Mensaje",
    "ok": "Aceptar"
  },
    "lottery": {
      "title": "Caja misteriosa USDC",
      "btn": "Aleatorio",
      "stake": "Staking",
      "connectWallet": "Conectar billetera",
      "voucher": "Obtener certificado",
      "tab1": "Datos del fondo",
      "tab2": "Plan",
      "tab3": "Impuestos",
      "tab4": "Cuenta",
      "ok": "Aceptar",
      "lotteryChance": "¡Felicidades, has ganado una oportunidad de sorteo con ETH!",
      "lotteryBtn": "Comenzar sorteo",
      "congratulations": "¡Felicidades!",
      "experienceUsdt1": "¡Felicidades! Has recibido un fondo de experiencia de",
      "experienceUsdt2": "USDC. El interés actual es",
      "experienceUsdt3": "ETH. El tiempo de vencimiento de la experiencia es"
    },
    "my": {
      "myAccount": "Mi cuenta",
      "totalOutput": "Producción total",
      "walletBalance": "Saldo de billetera",
      "exchangeable": "Intercambiable",
      "exchange": "Intercambiar",
      "withdraw": "Retirar",
      "record": "Registro"
    },
    "exchange": {
      "redeem": "Redimir todo",
      "exchange": "Intercambiar",
      "convert": "Convertir ETH a USDC"
    },
    "withdraw": {
      "total": "Saldo total",
      "confirm": "Confirmar",
      "tip": "Tu retiro será enviado a tu dirección de billetera USDC dentro de las 24 horas."
    },
    "record": {
      "time": "Hora",
      "quantity": "Cantidad",
      "status": "Estado",
      "output": "Producción",
      "exchange": "Intercambio",
      "withdraw": "Retiro",
      "interest": "Interés",
      "rebate": "Rebaja"
    },
    "loan": {
      "title": "Monto del préstamo",
      "dueDate": "Fecha de vencimiento",
      "tips": ["Préstamo con documentos simples"],
      "form": "Formulario de solicitud de préstamo",
      "download": "Descargar",
      "print": "Imprimir y llenar el formulario",
      "upload": "Subir foto del formulario",
      "btn": "Subir",
      "file": "Descargar archivo",
      "cancel": "Cancelar",
      "uFile": "Subir archivo",
      "tip": "Haz clic para subir",
      "doc1": "Acuerdo de préstamo personal 1.doc",
      "doc2": "Acuerdo de préstamo personal 2.doc",
      "file1": "PERSONAL_LOAN_AGREEMENT-1.docx",
      "file2": "PERSONAL_LOAN_AGREEMENT-2.docx"
    },
    "nav": {
      "home": "Inicio",
      "lang": "Idioma",
      "loan": "Préstamo",
      "paper": "Libro blanco",
      "pool": "Datos del fondo",
      "trading": "Comercio"
    },
    "whitepaper": {
      "title": "Libro blanco de USDC",
      "view": "Ver",
      "eth": "Libro blanco de Ethereum"
    },
    "nft": {
      "vol": "Volumen de comercio",
      "amount": "Monto de la transacción",
      "qty": "Cantidad de NFT",
      "users": "Usuarios ganadores",
      "quantity": "Cantidad",
      "totalVol": "Volumen total de comercio",
      "lastestTrans": "Último precio de transacción",
      "collQuantity": "Cantidad de colección",
      "showMore": "Mostrar más",
      "pd": "Detalles del proyecto",
      "sellPrice": "Precio de venta",
      "royalty": "Regalías",
      "royaltyTip": "Todas las regalías de esta transacción se donarán a organizaciones benéficas",
      "buy": "Comprar",
      "basic": "Información básica",
      "contract": "Dirección del contrato",
      "network": "Red",
      "attribute": "Atributo",
      "trait": "Posee esta característica",
      "records": "Registros",
      "balance": "Saldo",
      "sellNow": "Vender ahora",
      "cancel": "Cancelar",
      "buyNow": "Comprar ahora",
      "pay": "Monto a pagar",
      "transPass": "Contraseña de transacción",
      "transTip": "Nota: recuerda tu contraseña de transacción. Si la olvidas, contacta al soporte en línea de la plataforma.",
      "newPass": "Nueva contraseña",
      "rePass": "Confirmar contraseña",
      "confirm": "Confirmar",
      "purchaseTime": "Hora de compra",
      "purchaseAmount": "Monto de compra",
      "price": "Precio",
      "priceTip": "Por favor, ingresa el precio de venta",
      "sell": "Vender",
      "digitTip": "Por favor, ingresa un número de 6 dígitos"
    },  
    info: {
      1: [
        "USDC is a token that traders use to earn interest. There are various ways to do this, which is one of the unique features of cryptocurrencies since traditional banks offer very little return. Understanding how to use USDC tokens is valuable for anyone trading cryptocurrencies.",
    "Table of Contents",
    "1 What is USD Coin (USDC)?",
    "2 How Does USDC Interest Work?",
    "3 How to Earn Interest with USDC?",
    "4 What are the Ways to Earn Returns with USDC?",
    "5 Is Earning Interest on USDC Worth It?",
    "6 FAQ: Frequently Asked Questions",
    "What is USD Coin (USDC)?",
    "USD Coin, abbreviated 'USDC', is a type of cryptocurrency known as a stablecoin. Issued by two of the largest regulated financial institutions in the US, Circle and Coinbase, it is fully backed by reserve assets. In other words, it can be exchanged one-to-one for US dollars.",
    "This allows for transactions in crypto wallets that are tied to the US dollar. USD Coin is an ERC-20 token supported by the Ethereum network, meaning it can be stored in any wallet that accepts Ethereum. This makes it highly flexible and accessible to most in the cryptocurrency space.",
    "How Does USDC Interest Work?",
    "Stablecoins are tokens whose value is tied to another asset, making them ideal for earning interest. In the case of USDC, it is pegged to the value of one US dollar. The interest earned from these coins is often much higher than other cryptocurrencies because it is easier to quantify. When the asset is stable, it is easier to provide rewards since it shouldn't fluctuate too much. By allowing people to lend USDC, companies and individuals can obtain loans via blockchain for investments, personal reasons, or leveraged trading. They agree to repay the loan with interest, just like a traditional bank loan. In other words, if you borrow 10,000 USDC, it's like borrowing 10,000 dollars.",
    "Cryptocurrency Interest vs Stablecoins",
    "You can earn interest from both stablecoins and cryptocurrencies. However, there is a major difference, and the risks you deal with will reflect this. Cryptocurrencies are much more volatile than stablecoins, so exchanges are less willing to lend them out. Cryptocurrencies can lose 30% of their value in a single day, but stablecoins are much harder to move.",
    "Due to the extreme volatility of cryptocurrencies, exchanges offer lower currency rates to those who stake cryptocurrencies on their platforms. After all, they might get into trouble by offering higher returns. On the other hand, those staking cryptocurrencies may find themselves earning some interest but also losing a large portion of their principal. Risk is always present.",
    "Since tokens like USDC have less volatility, stablecoins are considered a safer store of value in the cryptocurrency space. As a result, exchanges and blockchains are more willing to offer higher rates to users. USDC's APR tends to be strong mainly because it is 'stable.'",
    "Why Should You Earn Interest on USDC?",
    "There are many reasons why you might want to earn interest on USDC, and its advantages include the following, among others:",
    "Alternative to Savings:",
    "Some people use USDC to earn interest as a substitute for savings accounts. Over the past few years, savings accounts have offered very little interest, and cryptocurrencies have rushed to fill that gap.",
    "Higher Yields:",
    "The yield on staking cryptocurrencies, including USDC, is typically much higher than traditional banking.",
    "Earn While You Wait:",
    "Markets can sometimes be risky and unstable. Therefore, some traders hold USDC while staying away from the market. This is a good way to continue growing your account balance.",
    "How to Earn Interest with USDC?",
    "To benefit from USDC, you first need to own it. Storing USDC is simple and can be done on most exchanges. You'll need to register on multiple exchanges. We recommend wiring your funds to your exchange. Once your wire transfer arrives, you’ll need to buy ETH on the exchange you registered with and then send the ETH to your exchange. In a Web3 wallet, the next step is to convert the ETH you received in your Web3 wallet into USDC. This is simple and can be done in a few minutes.",
    "What are the Different Ways to Earn Returns with USDC?",
    "There are several ways and places you can earn USDC interest. However, you need to understand the differences and pros/cons of each type of platform.",
    "Earn USDC Returns via Exchanges",
    "Earning returns via exchanges might be the simplest process. Most exchanges require a similar set of steps, including setting up an account, depositing USDC, and choosing your preferred yield product. This may or may not involve locking USDC for a certain period. If liquidity is important to you, this could be an issue.",
    "The major advantage of using centralized exchanges is that it is simple, as you likely won’t be holding your cryptocurrency on the platform or at least providing the funds for trading your cryptocurrency.",
    "Advantages:",
    "Disadvantages:",
    "Simple and convenient.",
    "You may or may not be forced to lock USDC for a specific period.",
    "Can be completed on the same platform.",
    "May receive lower rates.",
    "Most exchanges are well-capitalized.",
    "The available products may be limited.",
    "Web3 is one of the leaders in the cryptocurrency staking space.",
    "Earn USDC Returns via DeFi",
    "One of the most exciting possibilities in the crypto space is DeFi platforms, which allow users to access basic financial services without intermediaries. Users can deposit cryptocurrencies (in this case, USDC) into decentralized lending protocols to earn interest.",
    "This decentralized finance space allows users to borrow and invest in new financial products. It all works through smart contracts and blockchain technology.",
    "Advantages:",
    "Disadvantages:",
    "Smart contracts provide ease of use.",
    "DeFi protocols do not reverse user errors.",
    "No intermediaries.",
    "Scalability might be an issue with some protocols.",
    "More products available.",
    "Liquidity might be a problem for smaller protocols.",
    "Examples of decentralized finance protocols include Uniswap and Bancor.",
    "Is Earning Interest on USDC Worth It?",
    "Earning interest on USDC is a good way to earn interest on crypto assets. Keep in mind that the interest rates are much higher than traditional savings accounts, so you can earn better returns.",
    "Earning interest on USDC is beneficial for most traders.",
    "You can choose to use USDC for earning interest for a variety of reasons. The most obvious is that, in terms of interest rates, it far surpasses standard physical banks. Besides that, USDC is a good way to earn interest on assets that might devalue in a general crypto market selloff. For example, you can cash out Bitcoin, convert it into USDC, and earn interest while waiting for the market to improve.",
    "Remember, nothing is 100% safe, but as long as you choose a reputable exchange or lending service, you should be protected. Keep in mind that this could be a good way to diversify your overall portfolio since earning interest on USDC can be seen as very similar to high-yield bonds.",
    "Most traders benefit from holding USDC, of course, and also try to generate interest during times when the market is difficult to navigate. By incorporating these safe assets into your portfolio, not only will it provide you with security, but it will also give you flexibility since they can be converted to other tokens or serve as a safety net. Most professional traders have at least a portion of these coins at various times.",
    "FAQ: Frequently Asked Questions",
    "Does USDC Have Interest?",
    "The token itself does not pay interest, but many exchanges and lenders offer interest. It is one of the most common coins for earning interest, so you have a wide range of options.",
    "Does USDC Pay Monthly Interest?",
    "It depends on where you lend it. Deposit terms can range from very short periods to a year or more.",
    "Can You Make Money by Buying USDC?",
    "Not necessarily, although earning interest is a way to make money. USDC is pegged to the dollar, so it is essentially a one-to-one transaction.",
    "Why is USDC's Interest So High?",
    "Due to supply and demand, USDC’s interest rate is higher. This situation tends to rise and fall depending on market conditions, especially during periods known as 'crypto winters.' The interest rate reflects this and fluctuates with changes in supply and demand.",
    "Can You Lose Money on USDC?",
    "Theoretically, if the peg to the dollar breaks, you could. It is one of the safest coins in the crypto world to date.",
    "What is the Purpose of USDC?",
    "USDC has many uses, with the most obvious being a way to transfer US dollars on the blockchain. However, earning interest is an important point in its use, as you can deposit it in exchanges, lending services, or DeFi spaces."

      ],
      2: [
          "Índice",
          "1. ¿Qué es una billetera Web3?",
          "1.1. ¿Qué es Web3?",
          "2. ¿Por qué necesitamos una billetera Web3?",
          "3. Alternativas a la billetera Web3",
          "3.1. MetaMask",
          "3.2. Billetera Coinbase",
          "3.3. Argent",
          "3.4. Trust Wallet",
          "3.5. Rainbow",
          "4. ¿Qué es una billetera Web3?",
          "Con el creciente interés en Web3, la participación en diversos ecosistemas también ha aumentado drásticamente. Además, si no estás familiarizado con este campo, la barrera de entrada puede parecer alta y difícil de superar. Sin embargo, a medida que aumenta el interés por las criptomonedas y el desarrollo de Web3, y con la industria volviéndose más amigable para el usuario, estas barreras están comenzando a desvanecerse. A medida que se vuelve más accesible, el espacio de las criptomonedas también evoluciona con el aumento de la competencia, lo que significa que se están desarrollando constantemente nuevas dApp (aplicaciones descentralizadas), tokens, NFT y billeteras Web3. Las billeteras son esenciales para ingresar al mundo de las criptomonedas porque cumplen múltiples funciones fundamentales en el espacio Web3. Por lo tanto, en este artículo, profundizaremos en el ámbito de las criptomonedas y responderemos a la pregunta: '¿Qué es una billetera Web3?'",
          "A medida que comprendemos mejor qué es una billetera Web3, también profundizaremos en algunas de las alternativas más populares del mercado. Además, si estás interesado en el desarrollo de Web3 y deseas crear tu propia billetera de criptomonedas, te guiaremos en el camino correcto, un camino que comienza con Web3.0.",
          "Con Web3, puedes desarrollar tu propia billetera Web3 de manera rápida y eficiente, ya que puedes acceder a la infraestructura subyacente del sistema operativo. Web3.0 es el primer sistema operativo para el desarrollo de Web3, que además de la infraestructura backend, permite a los usuarios acceder a una amplia caja de herramientas. Dentro de estas herramientas, encontrarás soluciones como nodos rápidos de Web3.0, SDK de Web3.0, API de precios, y soporte nativo de IPFS, entre otros.",
          "Por lo tanto, si deseas convertirte en desarrollador y ahorrador en blockchain, el primer paso es registrarte en Web3.0. El proceso de registro es rápido y se completa en pocos minutos.",
          "¿Qué es una billetera Web3?",
          "Si estás considerando adentrarte en el mundo de las criptomonedas, tu primer paso es obtener una billetera Web3. Las billeteras Web3 son cruciales para acceder al espacio Web3, DeFi y las criptomonedas. Es posible que hayas oído hablar de términos como 'Web3' o 'billetera criptográfica'. Si eres nuevo en este campo, vamos a responder primero a la pregunta: '¿Qué es una billetera Web3?'",
          "Primero, una billetera Web3 es básicamente una billetera digital. Por lo tanto, tiene la capacidad de almacenar activos digitales. Esto incluye todo, desde tokens fungibles hasta tokens no fungibles (NFTs). En segundo lugar, una billetera Web3 también abre las puertas al mundo de las criptomonedas, permitiéndote interactuar con diversas dApp en diferentes blockchains. A su vez, la billetera te ayuda a acceder a un vasto ecosistema de dApps.",
          "Las billeteras criptográficas generalmente tienen características no custodiales, lo que significa que, como propietario de la billetera, puedes almacenar tus activos digitales sin necesidad de intermediarios. Esto implica que, como usuario, sigues teniendo control total sobre todos tus activos, ya que nadie más puede acceder a tus tokens. Sin embargo, con este acceso exclusivo, toda la responsabilidad recae sobre ti, lo que significa que debes guardar tus claves privadas por ti mismo.",
          "Además de la capacidad de almacenar activos digitales, las billeteras generalmente ofrecen otras funciones. Por ejemplo, esto permite enviar y cambiar tokens utilizando una billetera Web3. Por lo tanto, una billetera criptográfica se puede usar para gestionar completamente tus activos, incluida la obtención de tokens adicionales.",
          "En el mercado existen diversas billeteras, cada una con sus propias características. Algunas de las más populares incluyen MetaMask, Coinbase Wallet, TrustWallet, Argent, Atoken, Hyperpay, Bitkeep, entre otras. Además, otro tema interesante a investigar es WalletConnect, ya que está estrechamente relacionado con las billeteras Web3.",
          "¿Qué es Web3?",
          "Con el surgimiento de la industria blockchain, probablemente hayas oído hablar de 'Web3'. Sin embargo, para los no iniciados, este término puede ser desconocido y algo confuso. Por lo tanto, en esta sección, nos tomaremos el tiempo para explicar y resumir qué es Web3.",
          "En términos generales, Web3 se refiere a la 'última generación' o 'fase' de Internet. Como puedes imaginar, las generaciones anteriores son Web1 y Web2, las cuales son más familiares para la mayoría. Estas tres etapas de la web no comenzaron en un punto específico ni fueron transformadas por una única entidad, pero cada etapa tiene sus propias características. Web1 es estática, Web2 es dinámica y Web3 es descentralizada.",
          "La descentralización es el concepto central de la última fase de Internet, dominada por datos descentralizados. A diferencia de Web2, no existe una entidad centralizada que posea los datos; en cambio, son distribuidos y compartidos. Además, Web3 resuelve finalmente el problema de las empresas que poseen grandes cantidades de información personal, ya que los usuarios controlan sus propios datos.",
          "En el ecosistema Web3, también encontramos otro componente importante, que son las dApps (aplicaciones descentralizadas). Estas son aplicaciones descentralizadas, normalmente basadas en blockchain, y el mayor ecosistema de dApp actualmente está alojado en la blockchain de Ethereum. Gracias a las características descentralizadas de las dApps, se pueden desarrollar aplicaciones poderosas que eliminan los problemas de la centralización, como los puntos únicos de falla.",
          "Sin embargo, si deseas obtener más información sobre la última fase de Internet, te recomendamos leer el artículo más detallado sobre Web3 en el blog.",
          "¿Por qué necesitamos una billetera Web3?",
          "En la sección '¿Qué es una billetera Web3?', dimos una breve introducción a las razones por las cuales las personas necesitan este tipo de billetera. Sin embargo, en esta sección, profundizaremos en estos puntos. Entonces, ¿por qué necesitamos una billetera Web3?",
          "El primer caso de uso de una billetera Web3 es poder gestionar completamente tus activos criptográficos. Para ilustrar esto, analizaremos de cerca la billetera de criptomonedas más grande del mercado, MetaMask. Si tienes una cuenta de MetaMask, es posible que ya estés familiarizado con cómo funciona la billetera; sin embargo, la interfaz se ve de la siguiente manera:",
          "Como puedes ver, tienes dos pestañas diferentes, una para 'Activos' y otra para 'Actividad'. La pestaña de actividad solo muestra tu historial de transacciones, el cual puedes explorar por separado. Además, bajo la pestaña de activos, puedes ver todos tus activos. También hay tres botones de acción: Comprar, Enviar, Intercambiar. Estos botones son autoexplicativos, por lo que no entraremos en detalles adicionales.",
          "Sin embargo, esto demuestra que, como billetera criptográfica, puedes gestionar completamente todos tus activos directamente a través de MetaMask. Además, esto no es exclusivo de MetaMask, ya que la mayoría de las otras billeteras permiten gestionar tus activos de una manera similar.",
          "La segunda razón por la que necesitas una billetera Web3 es la accesibilidad. Las billeteras criptográficas son una puerta de acceso a diversas blockchains. Por lo tanto, una billetera Web3 permite a los usuarios acceder fácilmente a las dApp, ya que los activos criptográficos son necesarios para interactuar con la mayoría de las aplicaciones. Por lo tanto, los usuarios pueden usar la billetera para autenticar su identidad y asegurarse de que pueden pagar las tarifas de transacción.",
          "Si estás interesado en más detalles sobre la autenticación de usuarios, te invitamos a leer el artículo sobre cómo autenticarte con MetaMask y cómo conectar a los usuarios usando WalletConnect.",
          "Alternativas a las billeteras Web3",
          "Como mencionamos antes, con el creciente número de personas que entran al mundo de las criptomonedas, también está aumentando el número de billeteras Web3. Esto es el resultado de una simple ley de 'oferta y demanda', ya que las empresas han visto oportunidades de crecimiento en este mercado emergente. Sin embargo, cuando los usuarios intentan elegir entre tantas alternativas, esto puede resultar confuso.",
          "Por lo tanto, nos centraremos en cinco billeteras alternativas para que puedas elegir. La mayoría de ellas tienen el mismo propósito, sin embargo, son únicas a su manera. Estas cinco billeteras Web3 son:",
          "MetaMask",
          "Billetera Coinbase",
          "Argent",
          "Trust Wallet",
          "Rainbow",
          "Así que, sin más preámbulos, echemos un vistazo a MetaMask.",
          "MetaMask",
          "La primera billetera Web3 en nuestra lista es MetaMask, una de las billeteras más conocidas en el mercado, con más de 21 millones de usuarios a nivel mundial. Esta billetera está disponible en dos formas: aplicación móvil o extensión de navegador. Ya mencionamos MetaMask cuando intentábamos resaltar la necesidad de una billetera criptográfica. Señalamos que MetaMask permite gestionar activos criptográficos de manera descentralizada.",
          "Sin embargo, no mencionamos que MetaMask es una puerta de acceso al ecosistema de dApp de Ethereum. Además, Ethereum es la blockchain de desarrollo más importante, lo que significa que existen miles de aplicaciones disponibles para los usuarios de MetaMask. Por lo tanto, con el continuo crecimiento de Ethereum, tener una billetera MetaMask resultará ser muy beneficioso. Para obtener una guía más detallada sobre MetaMask, consulta nuestro artículo explicativo sobre MetaMask.",
          "Billetera Coinbase",
          "Si muestras interés en las criptomonedas, es probable que hayas oído hablar de Coinbase. Coinbase es una de las plataformas de criptomonedas más grandes a nivel mundial, y además de su plataforma fácil de usar, ahora ofrece la Billetera Coinbase en forma de una billetera Web3.",
          "La billetera Coinbase es una billetera Web3 no custodiada independiente de la plataforma centralizada de Coinbase. Esto ofrece a los usuarios una forma de experimentar el mundo de las dApp y las criptomonedas de manera independiente de la gestión centralizada de Coinbase. Además, una característica única de la billetera Coinbase es la capacidad de enviar y recibir activos criptográficos usando un nombre de usuario. Normalmente, solo puedes enviar activos usando una dirección de billetera, que es una secuencia larga y compleja de letras y números, lo que significa que debes copiar y pegar la dirección para enviar y recibir activos criptográficos.",
          "Además, si deseas obtener más información sobre el envío de tokens, puedes leer este artículo sobre cómo enviar tokens ERC-20, lo cual destaca lo fácil que es enviar tokens ERC-20 usando Web3.0.",
          "La tercera billetera es Argent, relativamente nueva en el mercado de billeteras Web3. Argent se enfoca en los usuarios de Ethereum, ya que solo es compatible con tokens de Ethereum. Además, esta billetera se dedica a proporcionar una experiencia amigable para el usuario al utilizar dApp y DeFi (finanzas descentralizadas). Logran esto integrando nativamente dApp y protocolos dentro de la aplicación de Argent. Por lo tanto, es posible pedir y prestar activos directamente a través de su aplicación, evitando así el uso de navegadores internos de aplicaciones.",
          "Si deseas obtener más información sobre DeFi, te sugerimos leer el artículo '¿Qué es DeFi?' de WEB3, y si deseas mejorar tus habilidades en DeFi, también puedes explorar nuestros cursos de blockchain DeFi 101 y DeFi 201.",
          "Sin embargo, una de las principales limitaciones de Argent es que solo está disponible para la red Ethereum. Por lo tanto, no es posible almacenar tokens nativos de otras cadenas, como los de la blockchain de Bitcoin.",
          "Trust Wallet",
          "La cuarta opción es Trust Wallet, que, a diferencia de Argent, tiene la capacidad de almacenar casi cualquier tipo de token. Esta billetera es lo que se conoce como agnóstica a las blockchains, lo que significa que es compatible con activos provenientes de varias cadenas. Además, Trust Wallet tiene más de diez millones de usuarios en todo el mundo y se ha consolidado en el mercado. Debido a su soporte para múltiples activos y blockchains, Trust Wallet se presenta como una opción razonable para aprovechar todas las funcionalidades ofrecidas por las redes descentralizadas.",
          "Rainbow",
          "La última opción es Rainbow, una billetera no custodiada compatible con la red Ethereum. Al igual que Argent, Rainbow tiene soporte nativo para las dApp de la red Ethereum. Un ejemplo de esto es Uniswap V2, que permite realizar intercambios dentro de la aplicación. Otra característica interesante de Rainbow Wallet es su soporte para NFT. Gracias a esto, la billetera puede mostrar ordenadamente todos tus activos digitales.",
          "Estas son solo cinco de las muchas alternativas disponibles. Como habrás notado en la breve descripción, son similares pero a la vez únicas en sus características, lo que significa que puedes elegir la billetera que mejor se adapte a tus necesidades.",
          "¿Qué es una billetera Web3? - Resumen",
          "Si planeas ingresar al mundo de las criptomonedas, lo primero que necesitas es una billetera Web3. Las billeteras criptográficas te permiten gestionar todos tus activos digitales, al mismo tiempo que te brindan acceso al ecosistema descentralizado de dApp. Además, todo esto es posible mientras mantienes el control total sobre tus activos, ya que la descentralización es una característica esencial de Web3. Por lo tanto, no se requieren intermediarios para realizar transacciones, préstamos de activos y otras operaciones.",
          "A medida que crece el interés por las redes descentralizadas, vemos un aumento significativo en la cantidad de alternativas de billeteras. Sin embargo, las cinco opciones más populares son MetaMask, Coinbase Wallet, Argent, Trust Wallet y Rainbow. Aunque estas alternativas tienen muchas similitudes, algunas son más adecuadas que otras, dependiendo de tus necesidades y circunstancias.",
          "Además, con las poderosas capacidades de Web3, puedes desarrollar rápidamente tu propia billetera con características específicas. Esto es posible porque los usuarios de Moralis pueden aprovechar la infraestructura subyacente del sistema operativo, lo que les permite crear una billetera Web3 completamente funcional en cuestión de minutos.",
          "Sin embargo, las capacidades de Moralis no se detienen allí. De hecho, puedes reducir significativamente el tiempo de desarrollo para todos tus futuros proyectos de blockchain. Si aún necesitas más persuasión, consulta los artículos en el blog que muestran lo fácil que es crear una dApp de Ethereum, desarrollar tu propio mercado NFT o crear un token BSC en cinco pasos.",
          "Por lo tanto, si estás interesado en mejorar tus habilidades en desarrollo Web3, regístrate en WEB3 ahora mismo. Esto te brindará acceso a la infraestructura de backend y las herramientas de Moralis."      
      ],
      3: [
          "Web3 en Vietnam UEL promueve el concepto de Web3 y ofrece becas",
          "Web3 se complace en anunciar que su más reciente evento exclusivo para estudiantes, 'Gate Web3 - Empowering GenZ in Web3', fue un éxito rotundo. Este evento educativo y benéfico tuvo lugar el 28 de octubre de 2023, de 1:00 p.m. a 4:30 p.m. en la Universidad de Economía y Derecho de Ciudad Ho Chi Minh, Vietnam.",
          "El objetivo del evento era proporcionar a la próxima generación (especialmente a la Generación Z) conocimiento y oportunidades en el rápidamente desarrollándose mundo de la tecnología Web3. Los asistentes obtuvieron valiosos conocimientos sobre los conceptos revolucionarios de Web3 y por qué se espera que sea la tecnología líder del futuro. Además, los expertos ofrecieron orientación sobre las habilidades fundamentales, por dónde empezar y cómo construir una carrera exitosa en este dinámico campo.",
          "Web3 se compromete a apoyar a los estudiantes vulnerables brindando becas a aquellos que necesitan ayuda económica para sus matrícula y gastos de vida. La ponente Mai Ngo,",
          "El evento también incluyó una mesa redonda con expertos de la industria, incluidos Mai Ngo de BSCS, Tuan Anh y Dao Tien Phong, abogado principal de Investpush Legal, quienes compartieron valiosos conocimientos sobre las oportunidades de carrera en la tecnología blockchain. Los asistentes tuvieron la oportunidad de participar en conversaciones significativas con estos expertos y obtener conocimientos y orientación valiosa.",
          "Además de las conferencias informativas, Web3 y Gate Charity otorgaron becas durante el evento. Se otorgaron 15 becas de un millón de VND cada una a estudiantes pobres con excelentes resultados académicos.",
          "Se espera que el evento invite a unos 100 estudiantes y profesores de la Universidad FTC. Además, el evento será ampliamente transmitido en línea, con una audiencia estimada de 50,000 personas.",
          "Web3 está encantado de organizar este evento para reunir a expertos en tecnología Web3, estudiantes y defensores para promover la educación, conciencia y oportunidades en este campo transformador.",
          "Sobre Gate Charity",
          "Charity es una organización benéfica global sin fines de lucro que se dedica a impulsar el movimiento global de la caridad blockchain. Charity tiene como misión 'promover el equilibrio mundial', con el objetivo de unir a la comunidad criptográfica para reducir la brecha de pobreza, mejorar la vida de los grupos vulnerables, maximizar la equidad educativa y fomentar iniciativas de desarrollo sostenible a través de redes blockchain y criptomonedas innovadoras y confiables.",
          "Descargo de responsabilidad:",
          "Tenga en cuenta que todo el contenido relacionado con este evento es solo para fines benéficos. El uso o compra de cualquier servicio o producto relacionado está fuera del alcance de este evento. Si tiene alguna pregunta, comuníquese con Gate Charity a través de support@gatecharity.org.",
          "Comercio de Criptomonedas de forma segura, rápida y fácil en Web3 con acceso a más de 1,700 criptomonedas",
          "Regístrese en Web3 y obtenga un 40% de comisión por referencia, obteniendo espacio de almacenamiento en Web3 de inmediato.",
          "¡Contáctenos!",
          "Equipo, 23 de octubre de 2023"
      ],
      4: [
          "Acceso a la Blockchain\nCambia vidas",
          "Construir un mundo donde todas las personas puedan acceder a servicios financieros y asistencia a través de soluciones blockchain, en cualquier momento y en cualquier lugar.",
          "Total de donaciones\n(USD)",
          "Número total de beneficiarios\n",
          "Región de beneficio\n",
          "Promover el desarrollo equilibrado del mundo",
          "Eliminar la pobreza",
          "Creemos que la blockchain y las criptomonedas pueden jugar un papel clave en la creación de igualdad en educación y oportunidades laborales, en ayudar a las personas afectadas por la pobreza y en liberar a millones de personas de la pobreza.",
          "Empoderar a los grupos vulnerables",
          "Eliminar barreras en recursos clave y sistemas de apoyo, con un enfoque en ayudar a mujeres, personas con discapacidad, niños pobres, ancianos y familias vulnerables en regiones empobrecidas, logrando inclusividad, accesibilidad y la capacidad de prosperar y crecer.",
          "Salvar nuestro planeta",
          "Solo tenemos un planeta. Siempre actuaremos para reducir el daño de la humanidad al clima y al medio ambiente natural. Un entorno saludable tiene un impacto positivo general para las generaciones futuras.",
          "Brindar asistencia humanitaria",
          "En momentos de crisis, brindar ayuda a las personas afectadas por desastres y emergencias, incluyendo apoyo a personas afectadas por conflictos, reconstrucción en zonas de desastre, asistencia a desplazados y ayuda médica en áreas empobrecidas.",
          "Transparencia en la recaudación de fondos",
          "Usando la tecnología blockchain para lograr acciones benéficas transparentes y confiables, que realmente ayuden a los grupos vulnerables.",
          "Canal de recaudación mediante arte NFT",
          "A través de subastas de NFTs, podemos lograr eficazmente fines benéficos mientras difundimos el valor del arte.",
          "Proyecto benéfico en el Metaverso",
          "Soluciones diversificadas de Web3.0, ofreciendo formas educativas y divertidas para difundir la semilla de la caridad en el corazón de todos.",
          "Usando la tecnología blockchain para beneficiar a las personas",
          "Líder mundial en tecnología blockchain",
          "A través de una tecnología blockchain innovadora y segura, garantizamos que las donaciones benéficas sean transparentes, seguras y confiables. Cualquier persona que necesite apoyo económico tendrá la garantía de recibir ayuda en todos los aspectos.",
          "Almacén de activos digitales ampliamente respaldado",
          "Nos sentimos orgullosos de apoyar el mayor grupo de activos digitales del mundo, lo que significa que puedes usar cualquier activo digital que poseas para participar en este gran movimiento benéfico.",
          "Obtener educación en blockchain",
          "Creemos que la educación es la base de todo, y ofrecer educación básica sobre blockchain traerá grandes resultados para las personas que ayudamos, quienes serán una parte esencial en la construcción del futuro de blockchain.",
          "Colaboración multilateral benéfica",
          "Nos comprometemos a maximizar la efectividad de la blockchain colaborando con las mejores instituciones, proyectos y expertos de la industria, entendiendo que cualquier forma de integración eventualmente servirá a la causa benéfica.",
          "Clasificación",
          "El 21 de agosto de 2010, Web3 Charity, en colaboración con A. A. Garotos de Ouro y Gate.io, organizó un evento especial por el Día del Niño en el famoso Estadio Brahma de Brasil. Este encuentro extraordinario atrajo a unos 150 niños de comunidades cercanas, llenos de deseo y emoción.",
          "La organización benéfica global sin fines de lucro Web3 Charity celebró con éxito un evento con el tema 'Protección de los océanos' en Baclaran, Filipinas, el 6 de octubre de 2023.",
          "Nuestras iniciativas",
          "Proyecto de apoyo a la crisis alimentaria en Somalia",
          "Únete a Gate Charity y World Vision para apoyar a las víctimas de la hambruna en Somalia",
          "Destacar",
          "Donaciones",
          "Donar ahora",
          "Causas apoyadas",
          "En al menos 45 países de todo el mundo, cerca de 50 millones de personas se encuentran al borde de la hambruna. Nunca hemos enfrentado una crisis alimentaria tan grave en tiempos recientes, y África, América Latina, Medio Oriente y Asia son las regiones afectadas. En países como Somalia, millones de personas están sufriendo hambre. El impacto adverso del cambio climático, como sequías, inundaciones y otros fenómenos climáticos extremos, ha destruido cultivos e ingresos, y en algunos países ha generado grandes plagas de langostas que destruyen las cosechas. Las consecuencias de la pandemia de COVID-19 han provocado un aumento en los precios de los alimentos y otros productos a nivel mundial, lo que ha hecho que las familias no puedan comprar los alimentos básicos para sobrevivir. Mientras tanto, los conflictos en todo el mundo están forzando a millones de personas a abandonar sus hogares, destruyendo hogares, vidas y medios de subsistencia.",
          "El acuerdo con el Programa Mundial de Alimentos de las Naciones Unidas significa que por cada 1 USDC donado, se multiplica por 27.",
          "El impacto de los múltiples conflictos, incluida la guerra en Ucrania, está creando una tormenta perfecta para los niños más vulnerables en la cuna mundial de producción de trigo.",
          "Al mismo tiempo, los conflictos en varias partes del mundo están forzando a millones de personas a huir de sus hogares, destruyendo vidas y medios de subsistencia.",
          "Las consecuencias de la pandemia de COVID-19 han provocado un aumento en los precios de los alimentos y otros productos.",
          "El impacto adverso del cambio climático, como sequías, inundaciones, tormentas extremas e impredecibles, ha destruido cultivos e ingresos, e incluso ha provocado plagas de langostas a gran escala en algunos países, destruyendo cosechas.",
          "Unidos contra la hambruna",
          "Para abordar la crisis alimentaria sin precedentes que afecta a casi 50 millones de personas en 45 países, Web3 Charity ha unido fuerzas con World Vision. Esta colaboración tiene como objetivo proporcionar asistencia alimentaria crucial a las personas necesitadas, especialmente en regiones como Somalia, que enfrentan graves hambrunas. La asociación busca abordar la grave escasez de alimentos en África, América Latina, Medio Oriente y Asia.",
          "Maximizar el impacto del Programa Mundial de Alimentos",
          "Web3 Charity se ha asociado con World Vision y con el Programa Mundial de Alimentos (PMA) de las Naciones Unidas para utilizar donaciones individuales. Cada donación de 1 USDC se multiplica por 27, transformando una donación de 1,000 USDC en 27,000 USDC. Estos recursos son cruciales para proporcionar ayuda alimentaria básica a las familias en las zonas afectadas por la hambruna, especialmente a los niños que dependen de la ayuda humanitaria para sobrevivir. La iniciativa incluye la distribución de paquetes de alimentos a las familias y la entrega de nutrición terapéutica a los niños más pequeños para satisfacer sus necesidades nutricionales directas durante esta crisis.",
          "Nuestros socios",
          "World Vision es una organización cristiana no gubernamental dedicada a erradicar la pobreza y promover el desarrollo sostenible de comunidades, familias y niños vulnerables a nivel mundial. La organización proporciona agua, alimentos y empoderamiento a los necesitados cada minuto. World Vision opera en casi 100 países y tiene la capacidad de responder rápidamente a emergencias, crisis y desastres naturales. En 2021, ayudó a más de 30 millones de personas en 52 países afectados por situaciones de emergencia. Los informes financieros de World Vision son auditados anualmente para garantizar la transparencia.",
          "Apoyando a la escuela de niños vulnerables SLB Sri Soedewi en Indonesia",
          "Únete a Gate Charity y World Vision para apoyar a las víctimas de la hambruna en Somalia",
          "Destacar",
          "Donaciones",
          "Donar ahora",
          "Causas apoyadas",
          "La escuela SLB Sri Soedewi para personas con discapacidad fue fundada en 1982 y alberga a más de cien estudiantes, ofreciendo cursos para diversas discapacidades, incluyendo discapacidades visuales, auditivas, intelectuales, físicas y autismo. Abarca desde la primaria hasta la secundaria. La institución se enfoca en aprovechar el potencial y los intereses únicos de cada estudiante, cultivando sus habilidades académicas y de vida para garantizar su crecimiento integral. El objetivo final es capacitar a los estudiantes con habilidades de vida que les permitan vivir de forma independiente en la sociedad. Web3 Charity y SLB Sri Soedewi School for the Differently-Abled están comprometidos con la promoción de la inclusión y ofreciendo una plataforma segura y de apoyo para cada niño.",
          "Cada regalo tendrá un impacto significativo en la vida de los estudiantes, y tú también puedes ser parte de ello.",
          "Brindar apoyo académico y de vida de calidad a los estudiantes con discapacidad visual puede ayudarlos a construir su autoestima, desarrollar importantes amistades y creer que un futuro brillante está a su alcance.",
          "Un entorno escolar adecuado es una respuesta importante a las necesidades sociales y culturales de los niños. SLB Sri Soedewi ofrece oportunidades para desarrollar sistemas de apoyo emocional, lingüístico y académico que permiten a los estudiantes brillar.",
          "La discapacidad no significa un obstáculo para la vida, ni tampoco significa que haya barreras para participar en ciertas actividades. Solo tienen formas diferentes de hacer las actividades que no pueden hacer, porque todos son especiales.",
          "El espíritu de unidad es uno de los factores más importantes para cultivar la inclusión, iluminar sonrisas brillantes, tomar las manos de los demás y fortalecer los lazos."      
      ],
      5: [
          "Circle lanza su primer informe anual sobre la 'Situación Económica del USDC'",
          "A continuación se presenta el prefacio del informe 'Situación Económica del USDC—Bienvenidos a la etapa de valor práctico de las monedas digitales'.",
          "Descargue el informe completo sobre la situación económica del USDC y vea nuestra agenda en el Foro Económico Mundial #WEF23 en Circle.com/Davos",
          "La moneda digital respaldada por el dólar está impulsando la próxima evolución de la moneda, los pagos y las finanzas",
          "Hace diez años, cofundé Circle con otras personas, con la visión de que las monedas tradicionales pudieran existir como monedas digitales en línea, y ser ofrecidas en una red global abierta e interoperable. Iniciamos un mundo de intercambio de valor sin fricción, similar a cómo la información y las comunicaciones en internet se desarrollaron sin fricción en sus primeros años. Desde entonces, el trabajo de Circle ha estado lleno de energía.",
          "En 2013, la madurez tecnológica de las monedas digitales y la tecnología blockchain, la comprensión de la industria y la conciencia regulatoria casi no existían. De hecho, no existían siquiera los requisitos necesarios para crear un 'dólar en internet'. Sabíamos que este sería un viaje de al menos diez a veinte años, y que el impacto de las monedas digitales en una red abierta sería aún más profundo que el de las redes de información.",
          "Hoy, diez años después, la moneda digital regulada más prominente, el dólar digital USDC, está entrando en la corriente principal. Casi todos los indicadores y tendencias muestran que la economía del USDC está creciendo saludablemente, y está convirtiéndose en una de las fuerzas principales para promover la actividad económica a gran escala en internet—una actividad que los bancos no pueden cubrir con infraestructuras tradicionales o servicios bancarios.",
          "Aunque el ciclo especulativo de Bitcoin y otras criptomonedas ha atravesado un año turbulento, monedas digitales como el USDC presagian la transición necesaria hacia una etapa de valor práctico de las monedas basadas en blockchain y programables, siempre conectadas. En la era de internet, impulsar estas innovaciones responsables no se trata de competir con los sistemas financieros tradicionales, sino de completar un trabajo que aún está pendiente.",
          "Ver correctamente esta oportunidad: monedas digitales como el USDC podrían comenzar a absorber una gran parte de los más de 2 billones de dólares en ingresos del sector de pagos, y convertirse en una nueva expresión digital de la oferta monetaria M2 de 21 billones de dólares.",
          "Por ejemplo, los comerciantes entienden el significado de esta evolución. Más del 85% de las personas priorizan habilitar pagos con monedas digitales. Aunque el uso de USDC en puntos de venta es algo reciente, ya está surgiendo. Muchos proveedores tradicionales de pagos (incluyendo Checkout.com, Mastercard, Plaid, Stripe, Visa, FIS's Worldpay, entre otros) están colaborando con USDC para facilitar que los comerciantes lo acepten de la forma más sencilla posible.",
          "La infraestructura de blockchain pública está experimentando una 'actualización de banda ancha dial-up', facilitando transacciones casi instantáneas, altamente seguras, programables y de bajo costo. A medida que más startups, empresas y compañías tradicionales de servicios financieros y pagos buscan conectarse con la internet de valor, los constructores y desarrolladores de Web2 se están volviendo cada vez más hacia Web3.",
          "Al mismo tiempo, los formuladores de políticas globales están prestando más atención, introduciendo las necesarias regulaciones para supervisar cómo las autoridades bancarias y de pagos deben regular las monedas digitales confiables de manera coherente y coordinada.",
          "Desde su fundación, Circle ha creído firmemente que un nuevo sistema económico global puede construirse sobre la infraestructura nativa de internet: una infraestructura pública abierta, global e interoperable para el almacenamiento y la transmisión de valor, y en última instancia, la intermediación de capital. Esta visión se está haciendo realidad, y 2023 será un año clave en la próxima evolución de la moneda, los pagos y las finanzas.",
          "Como se señala en nuestra primera revisión anual de la situación económica del USDC, el surgimiento del valor de utilidad pública está tomando fuerza en todo el mundo, catalizando una ola de innovación en servicios financieros abiertos y responsables, que pueden mejorar el camino hacia la prosperidad, mientras protegen la integridad del sistema financiero global."      
      ],
      6: [
          "Nuevo contenido de la Situación Económica del USDC 2024",
          "Está surgiendo un nuevo sistema financiero en la red. Gracias al poder de USDC, las redes blockchain y algunas de las mejores mentes y compañías tecnológicas del mundo, el sistema financiero está evolucionando. Es más rápido, más fácil y más seguro, permitiendo que todos ganen.",
          "Prefacio",
          "En la última década, el auge de la tecnología blockchain ha impulsado la fusión de internet con las finanzas globales. Esta fusión está trayendo una transformación radical al sistema financiero, como una actualización de software.",
          "Este fue el propósito por el que Circle fue fundada en 2013. Nuestra misión es aprovechar la apertura y escalabilidad de la red para promover la prosperidad económica global mediante el intercambio de valor sin fricciones, creando lo que consideramos un nuevo sistema financiero en la red.",
          "El software está a punto de tomar control del mundo financiero, al igual que lo hizo en la pasada generación con muchas industrias tradicionales. Al hacer que la comunicación, el entretenimiento y otras actividades comerciales sean más rápidas, más baratas y más accesibles, los costos caen, las barreras disminuyen y la innovación florece. Creemos que la acelerada migración del comercio hacia blockchain es la siguiente ola de la transformación digital, una tendencia que durará décadas y cuyo impacto social y económico podría ser aún mayor que el impacto que liberó la internet en los años 90 y principios de los 2000.",
          "Consideremos el hecho de que entre 2000 y 2020, el uso de internet creció del 7% de la población mundial al 60%. Es probable que veamos una trayectoria de crecimiento similar.",
          "En los próximos años, anticipamos que millones de empresas y miles de millones de personas comenzarán a utilizar esta nueva capa de red Web3 abierta para procesar billones de dólares en valor.",
          "Las monedas abiertas son una gran idea, que se basa en principios económicos, técnicos y filosóficos fundamentados en el diseño abierto e interoperable de internet. Al reducir el costo marginal de difundir datos a cero, la red ha permitido que todos accedan al conjunto del conocimiento humano. Y al permitir que desarrolladores y creadores construyan con facilidad, ha dado lugar a un ecosistema rico de sitios web y aplicaciones.",
          "Sin embargo, el proyecto de internet aún está incompleto. Aunque los datos pueden ser transmitidos de manera segura, instantánea y gratuita por todo el mundo, las autopistas monetarias en internet todavía se están construyendo.",
          "Todo lo que hacemos hoy es para construir y acelerar este sistema financiero en la red.",
          "Como se detalla en este informe, todo comenzó con nuestro esfuerzo por hacer de USDC la red de stablecoin más grande y más utilizada del mundo. Con USDC, hemos permitido que casi cualquiera, en cualquier lugar, acceda y utilice dólares en la red. Estamos transformando USDC en una plataforma abierta para que las empresas y los desarrolladores construyan una nueva ola de aplicaciones que puedan competir con y remodelar las plataformas de internet y finanzas dominantes de hoy en día.",
          "El año pasado marcó un hito significativo en el futuro de las finanzas nativas de internet basadas en blockchain. Los actores malintencionados comenzaron a asumir toda la presión de sus actividades fraudulentas, mientras que la tecnología subyacente maduraba y las empresas principales profundizaban su participación en tecnologías como USDC.",
          "Las principales empresas de pagos y las compañías de liquidación comercial ahora están usando USDC para resolver problemas del mundo real. Los mercados crediticios basados en blockchain están utilizando USDC para respaldar actividades económicas físicas en todo el mundo. Las fintech nacidas en la red y los nuevos bancos están adoptando USDC como medio para transferir dólares a la velocidad del comercio en línea. Las empresas de remesas y las ONG están colaborando con USDC para resolver el desafío de transferir valor de manera rápida y justa a quienes más lo necesitan. Los bancos tradicionales, las compañías de mercados de capital y las instituciones de custodia están respaldando USDC como una forma de hacer crecer sus negocios.",
          "Este impulso es la razón por la cual los formuladores de políticas globales han logrado avances significativos al emitir reglas y directrices sobre cómo participar de manera responsable en las monedas estables y en las blockchains abiertas. Comprenden la magnitud de la oportunidad y están decididos a aprovecharla para beneficiar a la economía y la sociedad. Innovadores y responsables políticos están redefiniendo juntos el arte de la moneda en la red.",
          "Aunque todavía es temprano, hay señales claras de que USDC y el ecosistema más amplio están listos para alcanzar la velocidad de escape — liberar el poder de las monedas abiertas en todo el mundo.",
          "Espero que disfruten la edición 2024 de la Situación Económica del USDC. Mi equipo y yo esperamos discutir los hallazgos de la investigación con ustedes.",
          "Sinceramente,",
          "Co-fundador, Presidente y CEO",
          "Dólares para cualquiera, en cualquier lugar",
          "La red blockchain abierta está impulsando una nueva forma de moneda y redefiniendo la manera en que las personas y empresas intercambian valor a nivel global. Estas redes representan una nueva capa de fintech que utiliza internet para reducir los costos y fricciones incrustados, aumentar la velocidad y repensar lo que es posible al intercambiar valor, ya sea enviando dólares digitales a través de fronteras o interactuando con versiones tokenizadas de otros activos tradicionales.",
          "Esta posibilidad fue la que llevó a Circle a lanzar USDC en 2018.",
          "Impulsada por la posición del dólar en los centros financieros globales, la oportunidad reciente es enorme y ya está teniendo un impacto significativo. En América Latina, el dólar representa más del 90% de los valores comerciales, en Asia-Pacífico el 74%, y en otras partes del mundo fuera de Europa, el 79%. Un tercio de los billetes de 100 dólares se mantienen fuera de los Estados Unidos.",
          "La posición global del dólar significa que la gran mayoría de la actividad de las stablecoins hoy en día está denominadas en dólares. USDC permite que casi cualquiera, en cualquier lugar, acceda, posea y comercialice dólares digitales a través de la red. USDC ya se ha lanzado en más de 190 países y está guardado en cerca de 3 millones de billeteras en cadena.",
          "Plataforma de monedas abiertas en la red",
          "En los últimos años, USDC como red de stablecoin ha experimentado un crecimiento exponencial. Desde su lanzamiento en 2018, USDC ha sido utilizado para liquidar alrededor de 12 billones de dólares en transacciones en blockchain. Cada vez más personas en más países están experimentando los beneficios de desbloquear dólares digitales en la blockchain. Esto ha incentivado a los desarrolladores a crear nuevas aplicaciones, ha fortalecido el ecosistema de servicios alrededor de USDC y ha hecho que sea más fácil acceder, enviar y almacenar.",
          "El creciente caso de uso de la tecnología blockchain recuerda la curva de adopción de la computación en la nube. En sus primeros días, la nube era vista como algo de nicho y arriesgado, pero ahora es evidente las ventajas inherentes que aporta a los negocios. Hoy, el 60% de los datos de las empresas se almacenan en la nube, y nueve de cada diez grandes empresas adoptan una arquitectura multicloud. Estamos en el borde de la siguiente fase. El sistema financiero en la red que Circle está ayudando a construir no compite con la nube; lo complementa. De hecho, las redes blockchain están trayendo datos confiables, transacciones y computación al internet público.",
          "A medida que más empresas adopten Web3 en blockchains empresariales, estamos al borde de la siguiente fase de esta evolución.",
          "USDC, la API de dólares de las finanzas en la red, es esencialmente la API de dólares en internet. Es un bloque de construcción de código abierto, regulado, que puede integrarse fácilmente con otros proyectos de fintech, banca y monedas digitales, permitiendo transacciones casi instantáneas y casi gratuitas denominadas en la moneda más utilizada del mundo. En la era de rápido desarrollo de pilas fintech y la creciente popularidad de las APIs, esto crea grandes oportunidades.",
          "Construido para la interoperabilidad de blockchain, USDC se puede usar en 15 redes blockchain. Con el tiempo, planeamos que USDC esté disponible en lugares donde los desarrolladores estén activos y adopten las medidas de seguridad correctas, permitiendo que fluya fácilmente y de forma segura a través del ecosistema blockchain.",
          "Al mismo tiempo, estamos haciendo que la complejidad de la blockchain desaparezca de la vista de los usuarios. El Protocolo de Transferencia entre Cadenas (CCTP) recientemente lanzado ayuda a reducir la fricción, mejorar la seguridad y disminuir el costo de enviar USDC de una blockchain a otra. En un futuro cercano, nuestro objetivo es habilitar el soporte CCTP en cada blockchain donde se encuentre USDC, permitiendo que los dólares se envíen sin problemas a través de internet sin que los usuarios enfrenten los desafíos de transferir USDC entre blockchains.",
          "Facilitando blockchain para los desarrolladores",
          "Miles de desarrolladores ya están creando en la red blockchain, y anticipamos que este número crecerá rápidamente en los próximos años. Circle ofrece billeteras programables, plantillas de contratos inteligentes y otros servicios para apoyar el desarrollo de este ecosistema y simplificar el proceso de creación de nuevas aplicaciones en la red blockchain para empresas y desarrolladores. Las empresas de redes que ya han alcanzado la escala pueden integrar fácilmente USDC y otros activos digitales en la experiencia del cliente sin tener que reconstruir desde cero."      
      ],
      7: [
          "Sube tu dólar",
          "USDC es un dólar digital, también conocido como stablecoin, disponible 24/7 y se mueve a la velocidad de internet. USDC existe en la web, operando en muchas de las blockchain más avanzadas del mundo. Cada día, miles de millones de dólares en USDC se intercambian, y cada dólar de USDC se puede canjear 1:1 por dólares.",
          "Siempre activo. Siempre disponible.",
          "USDC trasciende fronteras y horarios bancarios. Como un dólar digital con impacto global, USDC está disponible para su uso en cualquier momento y lugar. Puedes fácilmente enviar USDC alrededor del mundo, pagar bienes y servicios o ahorrar para el futuro. Cualquier persona con conexión a internet puede enviar, recibir y almacenar USDC.",
          "Flujo de efectivo instantáneo. Todo el día. Todos los días.",
          "Ya no necesitas esperar a que las transacciones se completen o el dinero se retire. USDC hace obsoleto el concepto de tiempos de liquidación, permitiendo que los pagos viajen alrededor del mundo tan rápido como un correo electrónico y lleguen a tu cuenta. Imagina que transacciones de divisas de cualquier tamaño se completen en segundos: esa es la potencia de USDC.",
          "Transferencias económicas de dinero",
          "Usar dinero no debería costar dinero. Di adiós a pagar 50 dólares por una transferencia bancaria, y saluda a las fracciones de centavo. Los comerciantes pueden evitar pagar el 2.9% de tarifas por pagos con tarjeta de crédito, beneficiándose del flujo de efectivo instantáneo y devolviendo los ahorros a los clientes.",
          "Reimaginando las finanzas",
          "USDC eleva el poder del dinero a un nuevo nivel. A través de USDC, se desbloquean oportunidades para transacciones, préstamos y recaudación de fondos en los mercados de capitales de criptomonedas a nivel global. Las posibilidades apenas comienzan.",
          "Plataforma de dinero abierto",
          "Usa la API de uber-money, global, de código abierto, interoperable y gratuita, para crear tu producto. Los desarrolladores confían en USDC como el bloque de construcción principal de sus aplicaciones, brindando pagos, transacciones y servicios financieros instantáneos a los usuarios. Haz realidad tus grandes sueños con USDC.",
          "USDC está transformando las finanzas",
          "USDC es parte de un ecosistema global que abarca negocios tradicionales y de criptomonedas. Creado por la fintech regulada Circle, USDC es un dólar digital confiable, ampliamente aceptado y de alta liquidez.",
          "Estabilidad confiable",
          "USDC siempre puede canjearse 1:1 por dólares. Las reservas de USDC son gestionadas y custodiadas por principales instituciones financieras de EE.UU. como BlackRock y BNY Mellon.",
          "Consulta las auditorías mensuales, conforme a los estándares establecidos por la Asociación de Contadores Públicos Certificados de EE. UU. (AICPA), que garantizan que las reservas de USDC son mayores o iguales a su circulación mensual.",
          "Al igual que PayPal, Stripe y Apple Pay, Circle es una institución de transmisión de dinero autorizada según las leyes estatales de EE.UU. Los estados financieros de Circle se auditan anualmente.",
          "USDC está en todas partes",
          "Elige tu viaje hacia el mundo del dólar digital. Descubre todas las formas en que empresas, usuarios y desarrolladores usan USDC, o adquiere USDC ahora.",
          "Empresas",
          "Ofrece flujo de efectivo instantáneo con pagos USDC rápidos como un rayo, acelerando tu crecimiento global. Obtén liquidez en USDC para negociar, prestar e invertir en los mercados de capitales criptográficos.",
          "Ecosistema",
          "Experimenta la moneda de la siguiente generación con USDC. Envía, consume, guarda e intercambia dólares digitales con personas en cualquier parte del mundo 24/7 a la velocidad de internet.",
          "Desarrolladores",
          "Integra USDC en tu aplicación ahora. USDC es un dólar digital programable, de código abierto e interoperable que cualquier persona puede usar para construir.",
          "Por qué los líderes eligen USDC",
          "Ya sea por su transparencia o programabilidad, USDC está construido para el futuro.",
          "Descubre por qué los innovadores en el Consenso 2023 eligen construir con Circle",
          "Preguntamos a nuestros socios por qué eligen Circle y USDC, y lo que destacaron es que la manera en que Circle construye las cosas es diferente. Circle hace que sea rápido y fácil de usar e implementar, y sus reservas hacen que USDC se destaque en la industria de stablecoins.",
          "Descubre cómo los fundadores de Circle Ventures usan USDC",
          "Aunque la confianza y la transparencia pueden parecer un riesgo, son las principales razones por las cuales los fundadores de empresas en el portafolio de Circle Ventures eligen USDC. Otras razones incluyen su liquidez programable, velocidad de liquidación casi instantánea, eficiencia, y más.",
          "Estableciendo estándares legislativos para las stablecoins",
          "USDC es comúnmente considerado el modelo para diseñar monedas digitales reguladas. A nivel superior, Circle trabaja estrechamente con los líderes del Congreso de EE.UU. para integrar el valor abierto de Web3 con el sistema financiero tradicional, ayudando a crear un futuro armonioso e interconectado.",
          "¿Es USDC una criptomoneda?",
          "USDC es una stablecoin totalmente respaldada, que forma parte de las criptomonedas o activos digitales. A diferencia de otras criptomonedas que fluctúan en precio, USDC está diseñado para mantener su paridad con el dólar. USDC es un medio estable para almacenar valor, beneficiándose de la velocidad y seguridad de la tecnología blockchain.",
          "¿Cómo puedo confiar en que USDC siempre se puede canjear 1:1 por dólares?",
          "USDC está 100% respaldado por activos líquidos y equivalentes a efectivo, y siempre se puede canjear 1:1 por dólares. Parte de las reservas de USDC se invierten en el Circle Reserve Fund (USDXX), un fondo de mercado monetario regulado por la SEC y gestionado por BlackRock. Los informes independientes diarios sobre la cartera de reservas están disponibles públicamente. Más detalles en nuestra página de transparencia.",
          "¿Qué significa 'acuñar' o 'quemar' USDC?",
          "Las empresas pueden abrir una cuenta Circle Mint para intercambiar dólares por USDC. Cuando una empresa deposita dólares en su cuenta Circle Mint, Circle emite USDC equivalente a esa cantidad. Este proceso se llama 'acuñación', creando nuevo USDC para circular.",
          "De manera similar, cuando una empresa desea cambiar USDC por dólares, puede depositar USDC en su cuenta Circle Mint y recibir dólares gratuitamente. Este proceso se llama 'quema', y hace que el USDC deje de circular.",
          "Cuando los dólares se intercambian por USDC en un intercambio de activos digitales, el intercambio generalmente utiliza su saldo actual de USDC para completar el canje. Si el intercambio necesita más USDC, generalmente utiliza su cuenta Circle Mint para acuñar más USDC.",
          "¿Qué se necesita para acceder a USDC?",
          "Una forma rápida y sencilla para que las empresas accedan a USDC es solicitando una cuenta Circle Mint.",
          "También puedes acceder rápidamente a USDC desde intercambios de activos digitales como Coinbase, Crypto.com y Kraken.",
          "Los desarrolladores pueden comenzar a construir sobre USDC hoy accediendo a nuestro repositorio de GitHub. Nuestra página para desarrolladores de USDC ofrece más recursos.",
          "¿En qué blockchain se puede usar USDC?",
          "USDC está disponible en 15 blockchains: Algorand, Arbitrum, Avalanche, Base, Ethereum, Flow, Hedera, NEAR, Noble, OP Mainnet, Polkadot, Polygon PoS, Solana, Stellar y TRON, con más blockchains planificadas para el futuro.",
          "USDC también está puenteado a muchas blockchains emergentes a través de puentes de terceros, creando versiones puenteadas de USDC como USDC.e.",
          "Para más información, consulta nuestra página para desarrolladores de USDC.",
          "¿Cuál es la diferencia entre USDC y las CBDC (monedas digitales de los bancos centrales)?",
          "USDC es emitido por la empresa privada Circle, mientras que las CBDC serán emitidas por gobiernos. Aunque la mayoría de las CBDC aún están en fase de investigación, USDC ya está en circulación y es ampliamente utilizado por millones de personas en todo el mundo. La tecnología desarrollada por Circle permite que USDC opere en 11 blockchains públicas, impulsada por la innovación en mercados privados y open source en el modelo de moneda digital del dólar.",
          "Lee más sobre la perspectiva de Circle sobre las discusiones del CBDC por la Reserva Federal."      
      ],
      8: [
          "¿Qué es la moneda digital USDC?",
          "Un contrato inteligente es un protocolo digital automatizado que permite que dos o más partes intercambien dinero, propiedades, acciones o cualquier cosa de valor de manera transparente y sin conflictos, evitando la necesidad de un tercero.",
          "En términos más simples, se puede considerar un contrato inteligente como una máquina expendedora automática para transacciones complejas.",
          "El contrato inteligente funciona siguiendo simples declaraciones de 'si/cuando... entonces...', que se escriben en el código de la cadena de bloques. Cuando se cumplen y verifican las condiciones predefinidas, la red de computadoras ejecuta las acciones. Estas acciones pueden incluir la distribución de fondos a las partes correspondientes, el registro de vehículos, el envío de notificaciones o la emisión de multas. Cuando se completa la transacción, la cadena de bloques se actualiza. Esto significa que la transacción no se puede modificar y solo las partes autorizadas pueden ver el resultado.",
          "Con los contratos inteligentes, solo necesitas colocar Bitcoin en la máquina expendedora (es decir, el libro mayor) y todos tus contratos, bienes, licencias de conducir o cualquier otra cosa que esté bajo contrato solo debe ser colocada en tu cuenta. El contrato inteligente se encarga de determinar si se cumplen las condiciones del pedido.",
          "Un contrato inteligente define las reglas y sanciones de un acuerdo de la misma manera que un contrato tradicional, y ejecuta automáticamente esas obligaciones.",
          "¿Cómo ganan dinero los contratos inteligentes?",
          "Los desarrolladores de contratos inteligentes tienen dos formas principales de monetizar su trabajo en la cadena: activar la función de doble beneficio requerida para usar el protocolo, y la blockchain enviará el 90% de las ganancias de las recompensas recibidas a los usuarios que firmen el contrato inteligente. Usar contratos inteligentes para cobrar comisiones dobles.",
          "Un ejemplo: el contrato inteligente sigue el mismo principio que los depósitos bancarios a plazo fijo con intereses dobles.",
          "¿Existen riesgos con los contratos inteligentes?",
          "Los contratos inteligentes son códigos de ejecución automática sobre plataformas de blockchain, transformando toda una industria al automatizar procesos y hacer transacciones sin necesidad de confiar en terceros. Hasta ahora, no hemos encontrado casos en los que los contratos inteligentes presenten riesgos.",
          "Bien, ¿dónde existen los contratos inteligentes? ¿Dónde se ejecutan? ¿Cómo funcionan?",
          "Lo que necesitas saber",
          "Un contrato inteligente es un código que se ejecuta automáticamente, y los términos de un acuerdo entre comprador y vendedor son verificadas y ejecutadas automáticamente a través de una red de computadoras.",
          "Nick Szabo es un científico informático estadounidense que inventó una criptomoneda llamada 'Bit Gold' en 1998, y definió el contrato inteligente como un acuerdo comercial digitalizado que ejecuta los términos de un contrato.",
          "Los contratos inteligentes desplegados en la blockchain hacen que las transacciones sean rastreables, transparentes e inalterables.",
          "Ventajas de precisión, velocidad y eficiencia de los contratos inteligentes",
          "Cuando se cumplen las condiciones, el contrato se ejecuta de inmediato.",
          "Debido a que los contratos inteligentes son digitalizados y automatizados, no se requiere manejar ningún papeleo.",
          "No hay necesidad de corregir los errores que podrían surgir al llenar documentos manualmente.",
          "Confianza y transparencia",
          "No hay necesidad de preocuparse por la manipulación de la información para beneficio personal, ya que no participan terceros, y las partes intercambian registros de transacciones cifradas.",
          "Los contratos inteligentes son verificados, ejecutados y reforzados por programas informáticos que operan en la red blockchain. Cuando las partes en el contrato acuerdan sus términos, el programa se ejecuta automáticamente, eliminando la necesidad de terceros, ya que la validación y ejecución son realizadas por la red blockchain.",
          "Dado que los contratos inteligentes son ejecutados por código y no por personas, eliminan la posibilidad de errores humanos y pueden realizar automáticamente muchas tareas que tradicionalmente requieren interacción humana.",
          "Una de las mejores cosas de la blockchain es que, siendo un sistema descentralizado que existe entre todas las partes autorizadas, no necesitas pagar tarifas a intermediarios (middlemen), lo que ahorra tiempo y reduce conflictos.",
          "Por supuesto, la blockchain también tiene sus problemas, pero es innegable que es más rápida, más barata y más segura que los sistemas tradicionales. Es por eso que vemos más contratos inteligentes ejecutándose en diferentes redes blockchain como Ethereum, Solana, Tezos, Hyperledger, entre otras.",
          "Recarga tu billetera",
          "Crea experiencias innovadoras con NFT, protocolos DeFi, USDC y más. Agrega más funciones a tu billetera integrando cualquier contrato inteligente.",
          "Aumenta la lealtad y la participación de los clientes",
          "Crea experiencias nuevas y únicas para interactuar con los clientes y aumentar la lealtad, incluyendo drops de NFT, puntos de lealtad o tokens limitados que se pueden canjear por experiencias en persona.",
          "Intercambio, staking o puente",
          "Integra sin problemas protocolos DeFi en tu aplicación mediante contratos inteligentes para cerrar la brecha con DeFi.",
          "Escala con USDC",
          "Activa y gestiona fácilmente transacciones programadas de USDC con contratos inteligentes, como tu nómina, suscripciones o pagos.",
          "Introduce interacciones Web3 en tu aplicación más rápido",
          "Vitalik Buterin habla sobre contratos inteligentes",
          "Como explicó Vitalik Buterin, el fundador de Ethereum, en la cumbre de blockchain en DC cuando tenía 28 años, 'En el enfoque de contrato inteligente, los activos o monedas se transfieren al programa, el programa ejecuta este código y en algún momento valida automáticamente una condición. Luego determina automáticamente si el activo debe pertenecer a una persona o ser devuelto a otra persona, o si debe devolverse inmediatamente a la persona que envió el activo, o una combinación de ambos.'",
          "Al mismo tiempo, el libro mayor descentralizado también almacena y replica documentos, lo que le da una cierta seguridad e inmutabilidad.",
          "Ejemplo: Custodia de transacciones",
          "Supongamos que rentas un departamento de mí.",
          "Puedes hacerlo pagando con criptomonedas a través de la blockchain. Recibirás el recibo guardado en nuestro contrato digital; te daré una clave de entrada digital que llegará a tus manos antes de la fecha especificada. Si la clave no llega a tiempo, la blockchain emitirá un reembolso.",
          "Si envío la clave antes de la fecha de alquiler, la función la retendrá y liberará los pagos y la clave a ti y a mí, respectivamente, cuando llegue la fecha de alquiler.",
          "El sistema funciona bajo el principio de 'si-entonces' y está presenciado por cientos de personas, por lo que puedes esperar una entrega perfecta.",
          "Si te doy la llave, recibiré el pago. Si envías una cantidad determinada de Bitcoin, recibirás la llave. El documento se cancelará automáticamente después del tiempo especificado, y ninguno de los dos podrá interferir en el código sin que la otra parte lo sepa, ya que todos los participantes recibirán una alerta al mismo tiempo.",
          "Identificar casos de uso reales de contratos inteligentes",
          "Jerry Cuomo, vicepresidente de tecnología de blockchain en IBM, cree que los contratos inteligentes pueden usarse en toda la cadena, desde servicios financieros, atención médica hasta seguros. A continuación, algunos ejemplos.",
          "Aplicaciones de Finanzas Descentralizadas",
          "A medida que vemos que las aplicaciones DeFi se están volviendo mainstream, nuestra forma de gestionar el dinero está cambiando significativamente.",
          "Las finanzas descentralizadas son la salida de los servicios y regulaciones bancarias tradicionales. Este es un cambio sutil, pero parece inevitable.",
          "Tenemos datos que lo demuestran: el volumen de transacciones DeFi creció 14 veces en 2020, y más de cuatro veces en 2021, alcanzando los 112.07 mil millones de dólares. El valor total bloqueado en contratos inteligentes DeFi superó los 20 mil millones de dólares.",
          "Los contratos inteligentes en DeFi están facilitando el intercambio de bienes, servicios, datos, fondos, etc. Los usuarios de instituciones financieras centralizadas como bancos y cooperativas de crédito dependen de intermediarios para ejecutar transacciones. Las DApps, por otro lado, utilizan contratos inteligentes para garantizar que cada operación sea auténtica, transparente y sin errores humanos.",
          "En solo unos años, los NFT han crecido explosivamente en el mercado, alcanzando un valor de mercado de 40.9 mil millones de dólares en 2021, convirtiéndose en el caso de uso más exitoso de contratos inteligentes.",
          "Los NFT se crean a través del proceso de acuñación, que requiere desplegar un contrato inteligente en la blockchain.",
          "Un contrato inteligente es una herramienta que permite ejecutar un acuerdo de venta entre el propietario del NFT y el comprador. Los contratos inteligentes contienen información sobre el NFT, como el creador de la obra, las partes que tienen derecho a recibir regalías cada vez que se venda el NFT y el historial de propiedad de la obra.",
          "La mayoría de los NFT no se registran directamente en la blockchain, ya que almacenar tantos datos en la blockchain es costoso y consume mucha energía. Por lo tanto, los contratos inteligentes a menudo incluyen enlaces al trabajo que representan, los cuales solo pueden ser vistos por el propietario.",
          "Gobierno",
          "Los insiders garantizan que nuestros sistemas de votación son difíciles de manipular, pero aun así, los contratos inteligentes eliminarán todas las preocupaciones ofreciendo un sistema de seguridad sin igual. Los contratos inteligentes garantizan que el voto se registre sin la posibilidad de ser alterado.",
          "Industria Automotriz",
          "Los contratos inteligentes también se pueden usar para registrar el historial de vehículos. Las partes involucradas en la transacción (vendedores, compradores y las compañías de seguros) pueden interactuar directamente en la blockchain para hacer el proceso más eficiente y menos susceptible a fraudes."      
      ],
      9: [
          "NFT está cambiando el arte digital, los coleccionables deportivos, los medios de comunicación, entre otros.",
          "Este ecosistema está transformando radicalmente la forma en que se crean, distribuyen y recogen los productos digitales.",
          "El campo de los NFT está evolucionando rápidamente:",
          "Blockchain",
          "El lugar de alojamiento y acuñación de los NFT.",
          "Mercado",
          "El lugar donde se listan y negocian los NFT.",
          "Proyectos NFT",
          "Desde arte generativo hasta bienes raíces virtuales, y más.",
          "Pero antes de explorar este ecosistema, definamos qué es un NFT.",
          "Calculando la intercambiabilidad de Qut",
          "¿Qué son los NFT?",
          "Un NFT es un token protegido por criptografía cuyo historial de propiedad y propietario actual se almacenan en la blockchain.",
          "Ofrecen un mercado primario y secundario robusto para los productos digitales, eliminando a los intermediarios y permitiendo que los creadores obtengan el máximo valor de su trabajo.",
          "Intercambiable vs No intercambiable",
          "No intercambiable",
          "La intercambiabilidad se refiere a que las unidades de un bien pueden ser intercambiadas y su valor no es diferenciable.",
          "Monedas como el dólar son intercambiables. Cada dólar puede ser intercambiado y tiene el mismo valor que cualquier otro dólar.",
          "Un token no intercambiable (NFT) o un bien tiene propiedades únicas que le otorgan un valor diferente a otros tokens o bienes similares.",
          "El valor de una casa con terraza es diferente y no puede intercambiarse con una casa sin terraza.",
          "La terraza puede ser una de muchas características que hacen que la casa sea no intercambiable y tenga un valor diferente.",
          "De manera similar, un NFT no intercambiable tendrá diferentes propiedades artísticas u otras características que lo hacen único, otorgándole un valor exclusivo.",
          "Cryptopunks es una colección de 10,000 NFT de arte pixelado de 'punks', y no hay dos punks iguales.",
          "Diferentes rarezas y propiedades hacen que ciertos punks sean más raros y valiosos.",
          "Si bien la rareza de las propiedades generalmente determina el valor, las preferencias estéticas y subjetivas de los inversores y coleccionistas también influyen en la valoración.",
          "Hay 78 punks con la propiedad 'dientes salientes', y 259 punks con la propiedad 'sudadera'. Los punks con 'sudadera' tienen un valor tres veces mayor que los punks con 'dientes salientes'.",
          "Fuente: Larya Labs",
          "Valor en dólares calculado a un precio de ETH de 4,430 USD",
          "Valor de los punks al 29 de noviembre de 2021",
          "Fábricas de acuñación robustas",
          "Plataformas de blockchain que alojan NFT",
          "Capitalización de mercado",
          "La mayoría de estas blockchains utilizan el consenso de prueba de participación, que consume mucho menos energía que la prueba de trabajo.",
          "Ethereum pasará a prueba de participación en algún momento del próximo año.",
          "Tezos tiene una comunidad NFT dedicada y es una de las primeras plataformas NFT fuera de Ethereum.",
          "Fuente: Messari.io",
          "Hasta el 29 de noviembre de 2021",
          "Explorando los estándares de tokens de Ethereum",
          "Ethereum es la plataforma más grande para NFT, y los NFT constituyen una parte significativa del tráfico y actividad de la red.",
          "Con el token ERC-721:",
          "El estándar de contrato ERC-20 se utiliza para crear tokens intercambiables, y es el más utilizado en toda la red de Ethereum.",
          "El estándar ERC-1155 fue creado por Enjin y permite la creación de activos tanto intercambiables como no intercambiables.",
          "ERC-998 es una extensión del estándar ERC-721 que permite que los NFT posean otros NFT y tokens ERC-20.",
          "Los estándares de tokens de NFT de Ethereum pueden interactuar con DApps (aplicaciones descentralizadas) también construidas sobre la blockchain de Ethereum, facilitando una integración sin problemas.",
          "El servicio de nombres de Ethereum (ENS) es un NFT que cambia el nombre de tu billetera Ethereum a una dirección '.eth' elegida, proporcionando un nombre único y más fácil de recordar.",
          "Estos NFT se compran anualmente, similar a los nombres de dominio en la web.",
          "Un vasto mercado secundario",
          "Actualmente existen múltiples mercados secundarios donde se pueden comprar y vender NFT.",
          "OpenSea se ha convertido en la plataforma dominante, con un volumen de transacciones de 1.9 mil millones de dólares en noviembre de 2021.",
          "El estado actual de los NFT",
          "Arte digital, coleccionables, videojuegos, etc.",
          "Los NFT no solo son una prueba digital de propiedad, sino que también pueden estar vinculados a activos o beneficios físicos.",
          "El último álbum de Kings of Leon, 'When You See Yourself', se lanzó junto con varios NFT limitados vinculados a activos y beneficios físicos.",
          "Un NFT de 50 AUD (35.70 GBP) da acceso a la descarga digital de la música, un disco de vinilo físico del álbum y productos digitales.",
          "Seis 'NFT de entradas doradas' permiten a los poseedores obtener cuatro asientos en primera fila para cualquier concierto de la banda durante toda su vida.",
          "La serie de NFT 'The Currency' del artista británico Damien Hirst explora cómo las personas valoran los NFT y el arte físico.",
          "La moneda consiste en 10,000 NFT únicos, cada uno con una obra física correspondiente creada en 2016.",
          "Las obras físicas se guardan en una bóveda en el Reino Unido, y los compradores de los NFT tienen un año (hasta el 27 de julio de 2022) para decidir si mantienen el NFT o la versión física; una vez tomada la decisión, la otra versión será destruida.",
          "Actualmente, el campo de los NFT tiene tres ramas principales, cada una impulsando la innovación de manera única:",
          "Desde colecciones de imágenes de perfil hasta arte generativo y música colaborativa, los NFT están haciendo que el arte digital prospere. No solo es arte visual, sino también música y coleccionables deportivos.",
          "El espacio de los videojuegos NFT está lleno de innovaciones, y varios proyectos están implementando y explorando la propiedad de activos dentro del juego y los modelos de juego para ganar.",
          "Los NFT están redefiniendo la propiedad digital, la identidad y el espacio, tokenizando tierras digitales en realidad virtual y avatares 3D que representan a sus propietarios.",
          "Más allá de la digitalización.",
          "Aunque los NFT ya han experimentado una explosión, la prosperidad aún está lejos de su fin.",
          "Más allá de estos campos, los NFT siguen explorándose como una forma de tokenizar y transferir digitalmente activos del mundo real, como propiedades o arte físico.",
          "Casi cualquier activo o bien físico puede ser tokenizado para facilitar la prueba y el intercambio de propiedad, abriendo posibilidades como la fragmentación, la colateralización y la utilidad que los contratos inteligentes pueden ofrecer.",
          "Mapeando el ecosistema NFT",
          "Los NFT fueron uno de los temas más populares y el mercado más burbujeado de 2021, con ventas que crecieron 100 veces, convirtiéndose también en tema de discusión en los programas de noche.",
          "Las criptomonedas tardaron casi diez años en penetrar verdaderamente en el mainstream, pero los NFT solo tomaron unos pocos años para captar la atención de las personas. Con marcas como Budweiser, Visa y Adidas comprando NFT y entrando en el espacio, está claro que los NFT no son solo otra tendencia pasajera.",
          "Este gráfico patrocinado por Next Decentrum define qué es un NFT y explora el ecosistema floreciente que está surgiendo rápidamente alrededor de los NFT. Aprende sobre el significado de 'no intercambiable', los lugares de acuñación y comercio de NFT y el futuro de esta clase de activos.",
          "¿Qué es un NFT y qué significa ser intercambiable?",
          "Un NFT es un token no intercambiable cuyo historial de propiedad y titular actual se guarda de manera criptográfica en la blockchain. Estos tokens pueden representar cualquier cosa, desde arte digital en formato jpeg hasta canciones en formato mp3.",
          "Al almacenar las transacciones de estos tokens en la blockchain, podemos poseer una prueba digital de propiedad de estos bienes digitales y del mercado, sin preocuparnos por el doble gasto o por que las transacciones pasadas y la propiedad sean alteradas.",
          "Entendiendo la intercambiabilidad",
          "Esto suena muy similar a las criptomonedas, entonces ¿qué hace que los NFT sean tan especiales? Su no intercambiabilidad. A diferencia de criptomonedas como Bitcoin o Ether, los NFT representan bienes o activos con propiedades y características únicas, lo que les otorga un valor único, incluso si pertenecen al mismo conjunto.",
          "Intercambiable: Bienes con unidades intercambiables y valor indistinguible. Ej: dólares, Bitcoin, fichas de arcade.",
          "No intercambiable: Bienes con características únicas que, en comparación con bienes similares, tienen un valor único. Ej: bienes raíces, pinturas, NFT.",
          "La serie NFT más popular, Cryptopunks, es una colección de 10,000 'punks' de arte pixelado con diferentes propiedades, como diferentes sombreros, gafas, peinados, etc. La combinación aleatoria de propiedades con diferente rareza da como resultado que cada punk tenga un valor único.",
          "La rareza y las preferencias estéticas subjetivas impulsan la valoración de los Cryptopunks y otros NFT, factores como su significado histórico, e incluso la blockchain en la que están alojados también influyen en su valor.",
          "Comparativa de blockchains compatibles con NFT",
          "Existen muchas blockchains diferentes que pueden acuñar y alojar NFT, siendo Ethereum actualmente la blockchain más grande en términos de capitalización de mercado y volumen de transacciones.",
          "Arte digital, videojuegos, metaverso y el futuro de los NFT",
          "Los NFT causaron un gran revuelo en 2021, brindando a los creadores una red descentralizada y digitalizada en la que alojar e intercambiar sus trabajos.",
          "Actualmente, los casos de uso digital son los que lideran el desarrollo de los NFT, donde la propiedad de los activos dentro de los videojuegos es uno de los dos principales casos en exploración. Sin embargo, los NFT también se pueden usar para tokenizar activos físicos como bienes raíces o arte, lo que abre posibilidades casi infinitas para su aplicación.",
          "Desde eliminar la burocracia y el papeleo en las transacciones inmobiliarias hasta permitir que la propiedad de activos se divida fácilmente, los NFT apenas comienzan a ser explorados en casos de uso del mundo real."      
      ],
      10: [
        
          "No importa si odias o te gusta Web3, ahora está muy de moda, ya que influenciadores tecnológicos como el capitalista de riesgo Marc Andreesen, el ex CEO de Twitter Jack Dorsey y el CEO de Tesla Elon Musk han estado debatiendo sobre ello. Los defensores de Web3 también creen que está remodelando el panorama económico. Gartner predice que, para 2030, Web3 aumentará el PIB global en 1.76 billones de dólares. Esto también ofrece una gran cantidad de oportunidades comerciales para abogados y bufetes de abogados que se adentran en este campo legal en rápido desarrollo.",
          "¿Qué es Web3?",
          "Web3 es una nueva plataforma basada en tecnología descentralizada y blockchain, propiedad de creadores y usuarios, donde las personas pueden vincular criptomonedas, NFTs y el metaverso a una nueva plataforma. En Web3, los individuos pueden ofrecer servicios directamente a otros usuarios y comerciar con bienes valiosos inherentes al sistema. Ejemplos de aplicaciones/redes/plataformas de Web3 pueden ser un metaverso que funcione en una blockchain descentralizada (un universo tridimensional en línea que combina varios espacios virtuales donde los usuarios pueden colaborar, encontrarse, jugar y socializar), en lugar de usar bancos.",
          "¿Web3 necesita protección legal?",
          "Existe un malentendido de que las plataformas descentralizadas no están reguladas. Además, los activos digitales están creciendo rápidamente en muchas partes del mundo, y las autoridades locales han comenzado a lanzar leyes y regulaciones para gestionar las transacciones. Por ejemplo, la Red de Ejecución de Delitos Financieros (FinCEN) del Departamento del Tesoro de los Estados Unidos está desarrollando reglas para rastrear transacciones con criptomonedas y reportar actividades sospechosas. Además, Australia y Canadá consideran que Bitcoin es un activo financiero con valor sujeto a impuestos. En Asia, el parlamento japonés aprobó en junio una ley para regular las stablecoins o criptomonedas con el fin de proteger a los inversionistas.",
          "Con el rápido crecimiento del mundo virtual en todo el mundo, las preocupaciones legales aumentarán. En general, muchos usuarios creen que los NFTs ofrecen una prueba de propiedad indiscutible. Sin embargo, las cosas pueden no ser tan simples como parecen. Por ejemplo, el profesor de derecho de la Universidad de Indiana, João Marinotti, señala que la propiedad de tierras virtuales cae bajo el derecho contractual, no bajo la ley de propiedad. Además, la compra de un NFT no otorga al usuario la propiedad del activo digital.",
          "A pesar de que Web3 es un campo nuevo y complejo, cuando surgen problemas, se necesita una nueva protección legal. Las demandas relacionadas con las criptomonedas están en aumento. Los NFTs generarán disputas relacionadas con licencias de activos y propiedad compartida. Hoy en día, las empresas están utilizando el metaverso como parte de sus estrategias comerciales y de marketing, lo que ha generado numerosos problemas legales sobre gobernanza, protección de propiedad intelectual y violaciones.",
          "Oportunidades en Web3",
          "Si cualquier campo tecnológico quiere aprovechar su potencial al máximo, necesita incorporar un marco legal adecuado que incluya derechos y obligaciones establecidos para proteger los intereses de todas las partes. Los problemas legales que necesitamos abordar incluyen impuestos, gobernanza virtual, desafíos regulatorios, licencias y propiedad, seguimiento de transacciones e informes, privacidad de datos y protección de propiedad intelectual.",
          "Para evitar quedar atrás, los bufetes de abogados tradicionales, los abogados y los estudiantes de derecho deben comprender el desarrollo de la tecnología Web3 y las leyes y regulaciones en constante cambio relacionadas con Web3 y los gobiernos locales."
          
      ],
      11: [
          "¿Cómo funcionan las tarjetas de débito criptográficas?",
          "Las tarjetas de débito criptográficas son utilizadas por empresas especializadas en servicios financieros de criptomonedas, como Coin. Estas empresas tienen acuerdos con redes de pago como Visa y Mastercard, lo que permite a los usuarios utilizar criptomonedas en lugares que aceptan sistemas de pago tradicionales.",
          "Este proceso garantiza que tus transacciones sean seguras y transparentes. Además, ofrece tasas de conversión rápidas, sin tener que esperar horas o días para completar una conversión.",
          "Elegir la tarjeta de débito criptográfica correcta",
          "En esta sección del artículo, te mostraré cómo obtener una tarjeta de débito criptográfica para realizar transacciones diarias sin problemas. Convierte tus criptomonedas en moneda fiduciaria y realiza compras en cualquier lugar que acepte tarjetas de débito válidas. También te describiré los factores que debes tener en cuenta al elegir la mejor tarjeta de débito criptográfica entre las opciones disponibles en el mercado:",
          "• Criptomonedas soportadas: Para obtener la mejor tarjeta financiera criptográfica, debes asegurarte de que la tarjeta sea compatible con tus criptomonedas.",
          "• Tarifas: Las tarjetas de débito criptográficas generalmente cobran varias tarifas, como tarifas de carga, tarifas de transacción y tarifas de retiro de cajeros automáticos. Antes de elegir una tarjeta, compara las tarifas de las diferentes opciones.",
          "• Recompensas: Elige una tarjeta de débito criptográfica con un plan de recompensas de devolución de dinero para maximizar tus gastos. Busca, compara y elige la tarjeta de débito criptográfica con la mayor devolución de dinero.",
          "• Seguridad: Las tarjetas de débito criptográficas utilizan diversas funciones de seguridad para proteger tus criptomonedas. Elige la mejor tarjeta financiera criptográfica que ofrezca fuertes características de seguridad.",
          "• Soporte al cliente: Uno de los aspectos a considerar al elegir la mejor billetera financiera criptográfica es la calidad del soporte; si no te pueden ayudar cuando tienes problemas, perderás tiempo y dinero, así que asegúrate de elegir un soporte eficaz.",
          "Son una solución innovadora que te permite usar tus activos digitales en transacciones del mundo real. Al aprovechar la tecnología blockchain, estas tarjetas pueden facilitar transacciones seguras y transparentes, y se pueden usar en cualquier lugar que acepte tarjetas de débito principales.",
          "Una de las mayores plataformas de intercambio de criptomonedas de EE. UU. anunció el viernes que está trabajando 'estrechamente' con las redes de pagos globales para lograr una transferencia y adopción de activos digitales sin interrupciones.",
          "Coinbase, una plataforma de intercambio de criptomonedas con sede en San Francisco, conocida por su enfoque regulatorio en criptomonedas, anunció hoy con orgullo que está colaborando con innovadores como Visa para promover la adopción de monedas digitales.",
          "La plataforma indicó que empresas como Visa están ayudando a cerrar la brecha entre las criptomonedas y las finanzas tradicionales. Esto crea un entorno maduro que conecta las criptomonedas a sus redes globales, al mismo tiempo que reúne a los líderes de los sectores tradicional y cripto.",
          "Visa diseña un plan de monedas digitales",
          "El gigante de pagos Visa, valorado en 420,000 millones de dólares, cuya red realiza más de 25,000 transacciones por segundo, expuso su plan de criptomonedas en un blog esta semana.",
          "La empresa, llamada 'Avanzando en nuestro enfoque de monedas digitales', coloca los activos digitales en el centro de su nueva estrategia digital-first para abrir nuevos mercados y mejorar la inclusión financiera global.",
          "Visa destacó que está trabajando activamente con plataformas de monedas digitales reguladas y con licencia como Coinbase para construir un puente entre las monedas digitales y su red global de más de 61 millones de comerciantes.",
          "La compañía destacó que actualmente más de 25 billeteras de monedas digitales están conectadas a la red de Visa, 'proporcionando a los usuarios una forma simple de gastar desde sus saldos de monedas digitales utilizando una tarjeta de débito Visa o un certificado prepago.'",
          "Otros desarrollos incluyen innovaciones como Visa Direct, el puente de la compañía que permite convertir rápidamente monedas fiduciarias en criptomonedas, agregando estas monedas para emitir certificados Visa.",
          "Visa indicó que estos esfuerzos posicionan a la marca como la opción principal para la adopción de billeteras de monedas digitales, 'que buscan aumentar el valor para los usuarios permitiendo el uso más rápido y fácil de las monedas digitales a nivel global.'",
          "Las stablecoins son un componente clave para la economía del futuro",
          "Visa en su estrategia más amplia de lanzamiento de criptomonedas en el sector público indicó que está en contacto con formuladores de políticas y organismos regulatorios para informar a las empresas sobre los beneficios de las monedas digitales.",
          "Usando una tarjeta de débito criptográfica para hacer transacciones",
          "Usar una billetera criptográfica con tarjeta de débito es una forma sencilla de utilizar criptomonedas sin necesidad de convertirlas y esperar que el proceso termine. Tu proveedor convertirá automáticamente tus criptomonedas en moneda fiduciaria y realizará la transferencia.",
          "Usando una tarjeta de débito criptográfica para transacciones diarias",
          "Funcionan de manera similar a las tarjetas de débito tradicionales, permitiéndote usarlas en cualquier lugar que acepte Visa o Mastercard. Facilitan las transacciones diarias y ofrecen ventajas sobre las tarjetas tradicionales, como recompensas en efectivo o programas de puntos cripto.",
          "Características de seguridad de las tarjetas de débito criptográficas",
          "Las tarjetas financieras criptográficas utilizan cifrado, autenticación de dos factores, protección contra fraudes, seguro FDIC y siguen las mismas regulaciones de seguridad que las tarjetas tradicionales para proteger tus criptomonedas e información personal.",
          "Ventajas de usar una tarjeta de débito criptográfica",
          "Si obtienes una tarjeta de débito criptográfica, disfrutarás de muchas ventajas como conveniencia, recompensas y, debido a la descentralización, control total sobre tus activos."      
      ],
      12: [
          "Cuenta de ahorros en USDC (Dólar Coin): Gana intereses en criptomonedas",
          "Web3 ofrece una gran cantidad de opciones de cuentas de ahorros criptográficas que están en constante crecimiento. Estas cuentas de ahorro de bajo riesgo y alto rendimiento ayudan a los usuarios a ganar hasta un 200% de intereses criptográficos mensuales + compuesto. El Dólar Coin (USDC) es una de las stablecoins más utilizadas en circulación. A continuación se presentan algunos detalles sobre USDC y cómo puedes ganar intereses criptográficos de manera fácil en YouHodler.",
          "¿Qué es USDC (Dólar Coin)? ¿Cómo ganar criptomonedas?",
          "A diferencia de muchas otras criptomonedas, USDC es un tipo único de criptomoneda conocida como 'stablecoin'. USDC está respaldado por dólares a una proporción de 1:1. Por lo tanto, siempre podrás canjear 1 USDC por 1.00 USD. Esto genera un activo más estable que Bitcoin o otras criptomonedas, lo que lo convierte en un lugar seguro para almacenar valor.",
          "USDC es un token basado en Ethereum, lo que significa que puedes almacenarlo en una billetera compatible con Ethereum, como la billetera USDC de YouHodler. El equipo detrás de USDC diseñó esta stablecoin para ayudar a los usuarios a transferir dólares de manera sencilla desde una billetera criptográfica a empresas, intercambios y otros. YouHodler lo añadió a nuestra plataforma como otra excelente opción para ganar intereses en criptomonedas.",
          "Cuenta de ahorros USDC: ¿Por qué ganar intereses en criptomonedas a través de Web3?",
          "Web3 ofrece varias formas de obtener ganancias a partir de activos digitales, y mediante las cuentas de ahorros criptográficas, los usuarios pueden desbloquear herramientas de inversión de alto rendimiento y bajo riesgo. Aquí te mostramos algunas razones para ganar intereses en criptomonedas a través de Web3:",
          "Gana intereses de hasta un 2.6% y 10% en USDC: En Web3, puedes ganar intereses de hasta un 2.6% y 10% en USDC anualmente. Esto es mucho más alto que cualquier cuenta de ahorros 'de alto rendimiento' en bancos tradicionales y otras inversiones. Web3 ofrece a sus clientes algunas de las mejores tasas de USDC en el mercado.",
          "Asegura tus activos: Todos los fondos de criptomonedas en Web3 se protegen siguiendo las mejores prácticas de la industria en cuanto a almacenamiento. Los fondos siempre se mantienen al 100% en carteras calientes. Utilizamos una mezcla de almacenamiento en carteras calientes y frías para garantizar que los fondos de los usuarios estén lo más protegidos posible.",
          "Intereses criptográficos pagados cada 4 horas, sin comisiones: Al final de cada período de liquidación de 4 horas, todos los intereses ganados en criptomonedas se acreditarán en la cuenta de ahorros del usuario. Los usuarios pueden optar por liberar y retirar los fondos a su billetera o dejarlos en la cuenta para ganar más intereses en el siguiente ciclo de facturación. (Es importante destacar que el número de retiros permitidos al día es de tres, y cada retiro debe ser superior a 1 USDC).",
          "Explora otras formas de ganar intereses en criptomonedas a través de Web3",
          "Los intereses en USDC son solo una opción para ganar intereses en criptomonedas a través de Web3. Visita nuestra página de ganancia de intereses para ver las diversas stablecoins y criptomonedas disponibles, como Bitcoin y Binance Coin. Gana intereses a través de cuentas de ahorro y descubre formas más efectivas de mantener a largo plazo.",
          "Aplicación de cartera que paga intereses en USDC",
          "La aplicación de cartera Web3 es la única que te permite almacenar Dólar Coin (USDC) como una billetera normal y ganar intereses cada 4 horas sobre tu saldo de USDC.",
          "¿Cómo funciona el interés en USDC?",
          "Puedes ganar intereses en USDC de la misma forma que en una cuenta de ahorros tradicional, pero con una diferencia clave. Te ofrecemos tasas de interés de hasta el 10% en ETH, en lugar de las tasas cercanas a cero que ofrecen los bancos.",
          "¿Cómo ganar USDC gratis?",
          "¡Es fácil! Simplemente deposita USDC en tu billetera Web3 y empezarás a ganar ETH gratis cada 4 horas.",
          "¿Por qué los intereses ganados son en ETH?",
          "Cuando depositas USDC en tu billetera, los intereses que recibes cada 4 horas son en criptomoneda ETH. Sin embargo, el ETH ganado en la blockchain no se puede retirar directamente a tu billetera. Necesitarás iniciar sesión en Web3, y los ETH ganados en tu cuenta deberán ser convertidos a USDC antes de que puedas retirarlos a tu billetera. A continuación se detalla cómo convertir los ETH ganados en Web3 a USDC.",
          "Antes de retirar los intereses, primero debes acceder a tu cuenta Web3, hacer clic en 'Convertir', y luego hacer clic en 'Convertir todo' para convertir todo de una vez.",
          "El siguiente paso es retirar el USDC convertido desde tu cuenta Web3 a tu billetera. Aquí te explicamos en detalle cómo retirar USDC.",
          "Ahora debes hacer clic en 'Retirar', luego en 'Saldo total' y finalmente en 'Confirmar'. Tu retiro de ganancias se habrá completado. Los retiros de USDC normalmente se procesan en 10 minutos, aunque pueden retrasarse si hay congestión en la red.",
          "Acumulando intereses de USDC y ETH",
          "Sí, si tienes tanto USDC como ETH en tu cuenta Web3, ganarás intereses acumulados. Realizamos un cálculo compuesto de intereses cada 4 horas.",
          "Tasa de rendimiento diario de USDC",
          "A diferencia de otras plataformas, ofrecemos ingresos diarios, no anuales. La tasa diaria te paga intereses sin el impacto del interés compuesto. Por lo tanto, con el interés acumulado, obtendrás más USDC al final del mes.",
          "Calculadora de ahorros en USDC",
          "Solo usa nuestra calculadora de ahorros en USDC para ver las ganancias que obtendrás después de 3, 6 o 12 meses de tener USDC en Web3."      
      ],
      13: [
          "Los 3,000,000 ETH de este período se obtienen de los acuerdos de préstamo DeFi firmados entre los mineros y la blockchain. Según el acuerdo DeFi, cuando los mineros alcanzan los 3,000,000 ETH en el acuerdo de préstamo con la blockchain, esta tiene derecho a hacer fluir estos 3,000,000 ETH a través de los proyectos de blockchain para preservar los datos de la blockchain y reflejar el valor del propio ETH. Por lo tanto, la blockchain pagará intereses sobre el USDC almacenado por los usuarios, y los usuarios que guarden USDC recibirán intereses en ETH.",
          "¿De dónde provienen los intereses de 1 ETH?",
          "Los mineros piden prestados ETH obtenidos en la blockchain a través de acuerdos DeFi. Actualmente, la blockchain ha recibido 3,000,000 ETH del acuerdo de préstamo DeFi de los mineros. Como resultado, la blockchain, en conjunto con los proyectos DeFi, lanzó un proyecto para ganar beneficios almacenando USDC.",
          "Por ejemplo, si el acuerdo de préstamo DeFi entre los mineros y la blockchain alcanza los 3,000,000 ETH, la blockchain tiene derecho a combinar los ETH apostados por los mineros con las principales billeteras Web3, dándoles liquidez para reflejar el valor del ETH.",
          "Además, es importante mencionar que cuando te conviertes en un usuario VIP actual de almacenamiento en la blockchain, tendrás la oportunidad de ser invitado por la blockchain para continuar almacenando y ganando beneficios en el próximo nodo descubierto por la blockchain.",
          "Préstamos DeFi en ETH",
          "Normalmente más altos y deducidos automáticamente de tu APY",
          "Decidido por la plataforma — Fijo",
          "Plataformas centralizadas y opacas, sin visibilidad interna",
          "Bajo — Solo unos pocos clics para completar",
          "Ataques de hackers, quiebra de la plataforma, bancarrota",
          "Las tarifas suelen ser más bajas y se detallan junto con el APY",
          "Decidido por el mercado — Siempre variable",
          "Plataformas descentralizadas y transparentes, cuyo código generalmente es de código abierto",
          "Alto — Se requiere cierto conocimiento sobre DeFi para completar el proceso",
          "Ataques de hackers",
          "Las tres plataformas anteriores son plataformas centralizadas que participan en Finanzas Centralizadas (CeFi). Otra opción es el acuerdo de préstamo DeFi en ETH, que actúa como el mercado de criptomonedas entre prestamistas y prestatarios.",
          "Las plataformas DeFi utilizan código para mantener un mercado automatizado que determina las tasas de interés de préstamo en tiempo real. Por lo tanto, las tasas de interés de préstamo en ETH en DeFi fluctúan según la oferta y la demanda.",
          "La ventaja de DeFi es que las tarifas de la plataforma son mucho más bajas. Además, como los acuerdos DeFi generalmente utilizan código abierto y son gestionados por la comunidad de titulares de tokens, los acuerdos DeFi son mucho más transparentes que los prestamistas CeFi.",
          "Los préstamos DeFi pueden ser rentables. Sin embargo, suelen ser más complejos, por lo que generalmente son más adecuados para operadores experimentados.",
          "P: ¿De dónde obtienen los mineros los ETH?",
          "R: Los mineros obtienen ETH a través de la minería de liquidez.",
          "La minería de liquidez DeFi es el proceso mediante el cual los usuarios proporcionan liquidez a las plataformas de Finanzas Descentralizadas (DeFi) a cambio de recompensas. Vamos a echar un vistazo más de cerca a este artículo para comprenderlo mejor.",
          "¿Qué es la minería de liquidez DeFi?",
          "La minería de liquidez DeFi es el proceso mediante el cual los usuarios proporcionan liquidez a las plataformas DeFi a cambio de recompensas. Los proveedores de liquidez (LP) pueden ganar tarifas por las transacciones que ocurren en el fondo, así como tokens proporcionados por la plataforma DeFi misma.",
          "¿Cómo funciona la minería de liquidez DeFi?",
          "La minería de liquidez DeFi funciona mediante un proceso denominado Automatización de Creación de Mercado (AMM). AMM es un contrato inteligente que crea fondos de liquidez para diferentes tokens. Los socios limitados pueden agregar sus tokens a un fondo de liquidez y ganar tarifas a través de las transacciones en ese fondo.",
          "Las tarifas se pagan en forma de tokens intercambiados en el fondo. Por ejemplo, si añades ETH y USDC a un fondo de liquidez, ganarás tarifas en ETH y USDC. También puedes ganar tokens de la propia plataforma DeFi. Estos tokens suelen ser tokens de gobernanza, los cuales te otorgan derechos de voto en la plataforma.",
          "¿Cuáles son los beneficios de la minería de liquidez DeFi?",
          "Participar en la minería de liquidez DeFi tiene varios beneficios. Primero, puedes ganar ingresos pasivos al proporcionar liquidez a una plataforma DeFi. Segundo, puedes ganar tokens de la plataforma DeFi, los cuales tienen el potencial de apreciarse. Tercero, puedes ayudar a aumentar la liquidez de la plataforma DeFi, lo que beneficia a todos los usuarios de la plataforma.",
          "¿Es segura la minería de liquidez DeFi?",
          "La minería de liquidez DeFi no está exenta de riesgos, pero si estás dispuesto a asumir estos riesgos, puede ser una forma rentable de ganar ingresos pasivos. Es importante investigar a fondo cualquier proyecto DeFi antes de participar y solo invertir lo que estés dispuesto a perder.",
          "¿Qué es la minería de liquidez DeFi? ¿Cómo funciona? — Espero que este artículo te proporcione información útil."      
      ],
    },
    check: {
        "title": "Ingreso Diario",
        "rules": "Reglas",
        "point": "Acumulación de Puntos",
        "signin": "Registrarse",
        "days": "Has iniciado sesión durante ${consecutiveDays} días consecutivos",
        "reSigning": "Volver a Iniciar Sesión",
        "day10": "10 días",
        "day90": "90 días",
        "rewards": "Recompensas",
        "day10Rewards": "Recompensas por iniciar sesión durante 10 días consecutivos",
        "getIt": "Obtener",
        "exchange": "Intercambiar",
        "exchangeSub": "Intercambio de puntos",
        "points": "Puntos",
        "share": "Misión de compartir",
        "shareSub": "Recompensas por misión de compartir",
        "rank": "Clasificación de Puntos"      
    },
  };
  